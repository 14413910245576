import {
  DeviceDataEvent,
  DeviceEventFeederState,
  filterAndSortyByFeederState,
} from 'utils/device/event/sip';

export default function parseLecturaComederos(
  data: DeviceDataEvent[]
): DeviceEventFeederState[] {
  return filterAndSortyByFeederState(data);
}
