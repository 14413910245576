import { useCalendarQuery } from 'hooks/useCalendarQuery';
import { useMemo } from 'react';
import {
  useGetDeviceDownloadDataQuery,
  useGetDeviceLoadDataQuery,
  useGetDeviceLocationDataQuery,
} from 'store/devices/devicesApi';
import parseFunction from './parser';

export function useUbicacion() {
  const { data: deviceLoadData = [], isFetching: isDeviceLoadDataFetching } =
    useCalendarQuery(useGetDeviceLoadDataQuery);

  const {
    data: deviceDownloadData = [],
    isFetching: isDeviceDownloadDataFetching,
  } = useCalendarQuery(useGetDeviceDownloadDataQuery);

  const {
    data: deviceLocationData = [],
    isFetching: isDeviceLocationDataFetching,
  } = useCalendarQuery(useGetDeviceLocationDataQuery);

  const [eventsGeneralLocation, eventsLoad, eventsDownload] = useMemo(
    () =>
      parseFunction([
        ...deviceLoadData,
        ...deviceDownloadData,
        ...deviceLocationData,
      ]),
    [deviceLoadData, deviceDownloadData, deviceLocationData]
  );

  return {
    isLoading:
      isDeviceLoadDataFetching ||
      isDeviceDownloadDataFetching ||
      isDeviceLocationDataFetching,
    eventsGeneralLocation,
    eventsLoad,
    eventsDownload,
  };
}
