import ClearIcon from './clear-icon';
import DollarIcon from './dollar-icon';
import EngineIcon from './engine-icon';
import GithubIcon from './github-icon';
import HamburgerIcon from './hamburger-icon';
import LogoutIcon from './logout-icon';
import PencilIcon from './pencil-icon';
import PercentageIcon from './percentage-icon';
import SaveIcon from './save-icon';
import TrashIcon from './trash-icon';
import UserIcon from './user-icon';

interface IconProps {
  onClick?: () => any;
  name:
    | 'clear'
    | 'github'
    | 'save'
    | 'discard'
    | 'engine'
    | 'hamburger-icon'
    | 'user'
    | 'logout'
    | 'dollar'
    | 'percentage'
    | 'pencil'
    | 'trash';
  size?: 's' | 'm' | 'l';
}

export default function Icon({ name, size = 'm', onClick }: IconProps) {
  return (
    <div className={`icon-${size} ${onClick ? 'button-icon' : ''}`} {...{ onClick }}>
      {getIconByName(name)}
    </div>
  );
}

function getIconByName(name: string) {
  switch (name) {
    case 'clear':
      return <ClearIcon />;
    case 'github':
      return <GithubIcon />;
    case 'save':
      return <SaveIcon />;
    case 'engine':
      return <EngineIcon />;
    case 'hamburger-icon':
      return <HamburgerIcon />;
    case 'user':
      return <UserIcon />;
    case 'logout':
      return <LogoutIcon />;
    case 'dollar':
      return <DollarIcon />;
    case 'percentage':
      return <PercentageIcon />;
    case 'pencil':
      return <PencilIcon />;
    case 'trash':
      return <TrashIcon />;
    default:
      return;
  }
}
