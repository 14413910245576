import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { Check, Close, PriorityHigh } from '@material-ui/icons';
import clsx from 'clsx';
import { FullScreenLoader } from 'components/loader';
import TableSubsumView from 'components/table-subsum-view';
import ViewHeader from 'design-system/view-header';
import { useCalendarQuery } from 'hooks/useCalendarQuery';
import { Fragment, useMemo, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { useGetDeviceLoadDataQuery } from 'store/devices/devicesApi';
import { DeviceEventLoad } from 'utils/device/event/sip';
import { customBodyRenderDateOnlyShort, customBodyRenderTime } from 'utils/helpers/body-renders';
import { redondearDosDecimales } from 'utils/helpers/math';
import parseFunction, { DeviceEventLoadFormatted, DeviceEventLoadSummed } from '../pesadas/parser';

export default function ReporteCargas() {
  const { data: deviceLoadData = [], isFetching: isDeviceLoadDataFetching } =
    useCalendarQuery(useGetDeviceLoadDataQuery);

  const [parsedDataPesadas] = parseFunction(deviceLoadData, undefined, false) || [];
  const groupedDataPesadas = groupDataPesadas(parsedDataPesadas);

  // Reverse order of groupedDataPesadas
  groupedDataPesadas.reverse();

  // Groupd groupedDataPesadas by date
  const groupedDataPesadasByDate = groupedDataPesadas.reduce((acc, curr) => {
    const fechaShort = customBodyRenderDateOnlyShort((curr[0] as DeviceEventLoad).date);

    if (!acc[fechaShort]) {
      acc[fechaShort] = [];
    }

    acc[fechaShort].push(curr);

    return acc;
  }, {} as { [key: string]: DeviceEventLoadFormatted[][] });

  const isLoading = isDeviceLoadDataFetching;

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <ViewHeader title="Reporte de Cargas" dateSelector="range" />
      <div className="content-view px-8 py-8 flex flex-col">
        {Object.entries(groupedDataPesadasByDate).map(([date, dateGroup], indexDateGroup) => (
          <Fragment key={indexDateGroup}>
            <h3 className="text-lg font-medium leading-6 text-white pt-6 pb-4">{date}</h3>
            {dateGroup.map((group, index) => (
              <RecipeCard key={index} recipeData={group} />
            ))}
          </Fragment>
        ))}
      </div>
    </>
  );
}

const RecipeCard = ({ recipeData }: { recipeData: DeviceEventLoadFormatted[] }) => {
  // Expanded state
  const [expanded, setExpanded] = useState(false);

  const recipe = recipeData[0].recipe as string;
  const fechaShort = customBodyRenderTime((recipeData[0] as DeviceEventLoad).date);
  const usuario = recipeData[0].user;
  const cabezasKg = (recipeData[0] as DeviceEventLoad).amount;
  const ingredientesInReceta = (recipeData[0] as DeviceEventLoad).ingredientsQuantity;

  const diferenciaKg = useMemo(() => {
    const difKg = recipeData.find((row) => row.rowType === 'resultado')?.diferenciaKg || 0;
    if (typeof difKg === 'number') {
      return difKg;
    } else return difKg[0] + difKg[1];
  }, [recipeData]);

  const diferenciaKgPorcentaje = useMemo(() => {
    const rowResultado = recipeData.find((row) => row.rowType === 'resultado');
    if (!rowResultado) return 0;

    const loaded = typeof rowResultado.loaded === 'number' ? rowResultado.loaded : rowResultado.loaded[1];
    const toLoad = typeof rowResultado.toLoad === 'number' ? rowResultado.toLoad : rowResultado.toLoad[1];

    return redondearDosDecimales((loaded * 100) / toLoad - 100);
  }, [recipeData]);

  const porcentajeKgCarga = useMemo(() => {
    const rowResultado = recipeData.find((row) => row.rowType === 'resultado');
    if (!rowResultado) return 0;

    const loaded = typeof rowResultado.loaded === 'number' ? rowResultado.loaded : rowResultado.loaded[1];
    const toLoad = typeof rowResultado.toLoad === 'number' ? rowResultado.toLoad : rowResultado.toLoad[1];

    return redondearDosDecimales((loaded * 100) / toLoad);
  }, [recipeData]);

  const recipeDataWithState = recipeData.map((row) =>
    row.rowType === 'resultado'
      ? row
      : {
          ...row,
          state: row.semaforo?.[0]?.isWarning ? 'warning' : row.semaforo?.[0]?.isDanger ? 'danger' : 'success',
        }
  );

  // Comparar las primeras 13 letras de la receta
  const ingredientsInRecipeData = recipeData
    .filter((row) => row.rowType !== 'resultado')
    .map((row) => (row as DeviceEventLoadSummed).ingredient);

  const rowsWithWarning = recipeDataWithState.filter((row) => (row as any).state === 'warning');
  const rowsWithDanger = recipeDataWithState.filter((row) => (row as any).state === 'danger');

  const cargaPerfecta =
    ingredientsInRecipeData.length === ingredientesInReceta &&
    rowsWithWarning.length === 0 &&
    rowsWithDanger.length === 0;

  const cargaCorrecta =
    ingredientsInRecipeData.length === ingredientesInReceta &&
    rowsWithDanger.length === 0 &&
    rowsWithWarning.length > 0 &&
    rowsWithWarning.every((row: any) => Math.abs(row.porcentajeKg) < 10);

  const cargaConPocosErrores =
    ingredientsInRecipeData.length === ingredientesInReceta &&
    rowsWithDanger.length === 0 &&
    rowsWithWarning.length > 0 &&
    rowsWithWarning.some((row: any) => Math.abs(row.porcentajeKg) >= 10);

  const cargaConErrores = ingredientsInRecipeData.length === ingredientesInReceta && rowsWithDanger.length > 0;

  const cargaConErroresIngresoReiterado = ingredientsInRecipeData.length > ingredientesInReceta;

  const cargaConErroresFaltaronIngredientes = ingredientsInRecipeData.length < ingredientesInReceta;

  return (
    <Accordion
      style={{
        backgroundColor: 'transparent',
        border: 'rgba(255, 255, 255, 0.1) 1px solid',
        boxShadow: '0px 0px 1px rgba(255,255,255,0.25)',
        color: 'white',
      }}
      defaultExpanded={expanded}
      expanded={expanded}
      onChange={() => setExpanded((prev) => !prev)}
    >
      <AccordionSummary
        expandIcon={<FiChevronDown fontSize="32px" />}
        style={{
          color: 'white !important',
        }}
      >
        <div className="flex items-center gap-4 w-full flex-wrap">
          {(cargaPerfecta || cargaCorrecta) && (
            <span className="bg-green-700 text-white rounded-full p-1 w-fit">
              <Check />
            </span>
          )}
          {cargaConPocosErrores && (
            <span className="bg-yellow-600 text-white rounded-full p-1 w-fit">
              <PriorityHigh />
            </span>
          )}
          {(cargaConErrores || cargaConErroresIngresoReiterado || cargaConErroresFaltaronIngredientes) && (
            <span className="bg-danger-600 text-white rounded-full p-1 w-fit">
              <Close />
            </span>
          )}
          <h3 className="text-lg font-medium leading-6 text-white whitespace-nowrap">Dieta: {recipe}</h3>
          <h3 className="text-lg font-medium leading-6 text-white">{porcentajeKgCarga}%</h3>
          <p className="mt-1 max-w-2xl text-sm text-neutral-300">{fechaShort}hs</p>{' '}
          <h3
            className={clsx(
              'text-lg font-medium px-2 rounded-md w-fit whitespace-nowrap',
              (cargaPerfecta || cargaCorrecta) && 'bg-green-700 text-green-100',
              cargaConPocosErrores && 'bg-yellow-700 text-yellow-100',
              (cargaConErrores || cargaConErroresIngresoReiterado || cargaConErroresFaltaronIngredientes) &&
                'bg-red-700 text-red-100'
            )}
          >
            {cargaPerfecta && 'Carga Perfecta'}
            {cargaCorrecta && 'Carga Correcta'}
            {cargaConPocosErrores && 'Carga con Pocos Errores'}
            {cargaConErrores && 'Carga con Errores'}
            {cargaConErroresIngresoReiterado && 'Carga con Errores de Ingreso Reiterado'}
            {cargaConErroresFaltaronIngredientes && 'Carga con Errores - Faltaron Ingredientes'}
          </h3>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {expanded && (
          <div className="flex flex-col gap-2 w-full">
            <h3 className="text-base font-medium leading-6 text-white">Usuario: {usuario}</h3>
            <h3 className="text-base font-medium leading-6 text-white">
              Ingredientes cargados: {ingredientsInRecipeData.length} de {ingredientesInReceta}
            </h3>
            <h3 className="text-base font-medium leading-6 text-white">Cantidad Cabezas / KG: {cabezasKg}</h3>
            <h3 className="text-base font-medium leading-6 text-white">
              Error KG totales: {diferenciaKg}Kg ({diferenciaKgPorcentaje}%)
            </h3>
            <div className="w-full overflow-hidden relative">
              <TableSubsumView
                title="Reporte de Cargas"
                data={recipeDataWithState as DeviceEventLoad[]}
                columnsName="columnsReporteCargas"
                parseFunction={(data, _f, _e) => [data, {}]}
              />
            </div>
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

const groupDataPesadas = (data: DeviceEventLoadFormatted[]) => {
  // Remove any row that has isResultadoFinal=true
  const filteredData = data.filter((row) => !(row as DeviceEventLoadSummed).isResultadoFinal);

  // Iterate through the array. Put all rows with the same recipe in the same group. If there's a row with rowType='resultado', then create a different group for it.
  const groupedData: DeviceEventLoadFormatted[][] = [];
  filteredData.forEach((row) => {
    if (row.rowType === 'resultado') {
      groupedData[groupedData.length - 1].push(row);
    } else {
      if (groupedData.length === 0) {
        groupedData[0] = [row];
      } else if (
        groupedData[groupedData.length - 1][groupedData[groupedData.length - 1].length - 1].rowType === 'resultado' ||
        groupedData[groupedData.length - 1][0].recipe !== row.recipe
      ) {
        groupedData[groupedData.length] = [row];
      } else {
        groupedData[groupedData.length - 1].push(row);
      }
    }
  });

  return groupedData;
};
