import React from 'react';
import Icon from 'components/icons';
import Button, { ButtonProps } from 'components/button';
import styled from 'styled-components';
import tw from 'twin.macro';
import { TextHeader } from 'design-system/text';

interface SidebarSlideProps {
  isOpen: boolean;
  onClose: () => any;
  title: string;
  actions?: ButtonProps[];
}

const SidebarSlideComponent = styled.div<{ isOpen: boolean }>`
  ${tw`fixed w-full h-full top-0 right-0 bg-black/75 opacity-0 invisible z-10 transition-all ease-in duration-300`}
  ${({ isOpen }) => isOpen && tw`visible opacity-100`}

  .content {
    ${tw`flex flex-col shadow-sm w-full tablet:w-9/12 h-full top-0 right-0 absolute overflow-hidden bg-neutral-900 transition-all ease-in duration-300`}
    transform: translateX(100%);
    ${({ isOpen }) => isOpen && 'transform: translateX(0%);'}
  }
`;

const SidebarSlide: React.FC<SidebarSlideProps> = ({
  isOpen,
  onClose,
  title,
  actions = [],
  children,
}) => {
  return (
    <SidebarSlideComponent isOpen={isOpen} onClick={onClose}>
      <div
        className="content"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="flex px-6 py-6 justify-between items-center bg-background-black">
          <TextHeader>{title}</TextHeader>
          <button onClick={onClose}>
            <Icon name="clear" />
          </button>
        </div>
        <div className="overflow-y-auto flex flex-col h-full">
          <div className='flex-1'>{children}</div>
          {!!actions?.length && (
            <div className="flex-initial flex px-6 py-2 gap-4 justify-end items-center border-t border-gray-700">
              {actions.map(
                ({ onClick, text, variant, disabled, className }, idx) => (
                  <Button
                    onClick={onClick}
                    disabled={disabled}
                    variant={variant}
                    className={className}
                    key={`button${idx}`}
                  >
                    {text}
                  </Button>
                )
              )}
            </div>
          )}
        </div>
      </div>
    </SidebarSlideComponent>
  );
};

export default SidebarSlide;
