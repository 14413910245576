import {
  customBodyRenderDateTotalColumn,
  customBodyRenderSummedAttribute,
} from 'components/table/TableSubsum';

export const columnsHistoricoDescargas = [
  {
    name: 'date',
    label: 'Fecha',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderDateTotalColumn,
    },
  },
  {
    name: 'unloadGuideName',
    label: 'Guía',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'user',
    label: 'Usuario',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'warnings',
    label: 'Advertencias',
    options: { filter: false, sort: false },
  },
  {
    name: 'dangerousUnloads',
    label: 'Peligrosas',
    options: { filter: false, sort: false },
  },
  {
    name: 'toUnload',
    label: 'Kg Teorico',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderSummedAttribute,
    },
  },
  {
    name: 'unloaded',
    label: 'Kg Cargados',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderSummedAttribute,
    },
  },
  {
    name: 'lots',
    label: 'Nº Lotes',
    options: { filter: false, sort: false },
  },
];

export const columnsHistoricoDescargasV2 = [
  ...columnsHistoricoDescargas.slice(0, 5),
  {
    name: 'lotsTotalKg',
    label: 'Kg Teóricos según guía',
    options: { filter: false, sort: true },
  },
  {
    name: 'toUnload',
    label: 'Kg Teórico según carga',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderSummedAttribute,
    },
  },
  {
    name: 'unloaded',
    label: 'Kg Descargados',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderSummedAttribute,
    },
  },
  {
    name: 'lotsOverTotalLots',
    label: 'Nº Lotes',
    options: { filter: false, sort: false },
  },
];
