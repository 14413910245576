import Alert from 'components/alert';

function TareasPendientesBanner() {
  // const { tareasPendientes = [] } = useEquipos();
  const tareasPendientes = [];

  return (
    (!!tareasPendientes?.length && (
      <Alert severity="warning">Hay tareas pendientes para este equipo.</Alert>
    )) || <></>
  );
}
export default TareasPendientesBanner;
