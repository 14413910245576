import Input from 'components/input';
import { FullScreenLoader } from 'components/loader';
import FormCard from 'design-system/form-card';
import Select from 'design-system/select';
import SidebarSlide from 'design-system/sidebar-slide';
import { TableRowTwoCols } from 'design-system/table/table-row';
import { startCase, toLower } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useAddGacUserMutation, useUpdateGacUserMutation } from 'store/devices/devicesApi';
import { selectDeviceSelected } from 'store/devices/devicesSlice';
import { PumpUser } from 'utils/device';

interface ModalEditarUsuarioProps {
  isOpen: boolean;
  onClose: () => void;
  usuario?: PumpUser | null;
}

export function ModalEditarUsuario({ isOpen, onClose, usuario }: ModalEditarUsuarioProps) {
  const deviceSelected = useSelector(selectDeviceSelected);
  const pumpUsers = deviceSelected?.pumpUsers;

  const [addGacUser] = useAddGacUserMutation();
  const [updateGacUser] = useUpdateGacUserMutation();

  const [isSaving, setIsSaving] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isValid, isDirty },
  } = useForm<PumpUser>({
    defaultValues: {
      manualAccess: 'SI',
      mode: 'Mensual',
      pumpUserType: 'Surtidor',
    },
  });

  const onSave = () => {
    handleSubmit(async (data) => {
      setIsSaving(true);
      const newUser = {
        ...usuario,
        ...data,
        pumpUserType: startCase(toLower(data.pumpUserType)),
        mode: startCase(toLower(data.mode)),
        availableLiters: data.maxLiters,
      };

      const response = !usuario
        ? await addGacUser({ user: newUser, serialNumber: deviceSelected!.serialNumber })
        : await updateGacUser({ user: newUser, serialNumber: deviceSelected!.serialNumber });

      if ('data' in response) onClose();
      setIsSaving(false);
    })();
  };

  const setFormToUser = useCallback(
    (user: PumpUser) => {
      setValue('name', user.name);
      setValue('availableLiters', user.availableLiters);
      setValue('maxLiters', user.maxLiters);
      setValue('password', user.password);
      setValue('pumpUserType', user.pumpUserType);
      setValue('manualAccess', user.manualAccess);
      setValue('mode', user.mode);
      setValue('keyId', user.keyId);
    },
    [setValue]
  );

  useEffect(() => {
    if (usuario && isOpen) {
      setFormToUser(usuario);
    }
  }, [isOpen, setFormToUser, usuario]);

  return (
    <>
      <SidebarSlide
        isOpen={isOpen}
        onClose={onClose}
        title={usuario ? `Editar usuario ${usuario.name}` : 'Crear usuario'}
        actions={[
          {
            autoFocus: false,
            onClick: onSave,
            text: 'Guardar',
            disabled: !isDirty || !isValid,
            variant: 'fill',
          },
        ]}
      >
        {isSaving && <FullScreenLoader />}
        <FormCard
          items={
            !pumpUsers?.length
              ? []
              : [
                  {
                    value: (
                      <Controller
                        name="name"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <Input label="Nombre*" value={value} onChange={onChange} maxLength={14} />
                        )}
                      />
                    ),
                  },
                  {
                    value: (
                      <TableRowTwoCols>
                        <Controller
                          name="maxLiters"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Input label="Litros Permitidos" value={value} type="number" onChange={onChange} />
                          )}
                        />
                        <Controller
                          name="password"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Input label="Contraseña manual" value={value} onChange={onChange} maxLength={14} />
                          )}
                        />
                      </TableRowTwoCols>
                    ),
                  },
                  {
                    value: (
                      <TableRowTwoCols>
                        <Controller
                          name="pumpUserType"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              name="Tipo de Usuario"
                              label="Tipo de Usuario"
                              options={['Surtidor', 'Administrador'].map((value) => ({ name: value, value }))}
                              onChangeValue={onChange}
                              value={value}
                            />
                          )}
                        />
                        <Controller
                          name="manualAccess"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              name="Ingreso manual"
                              label="Ingreso manual"
                              options={['SI', 'NO'].map((value) => ({ name: value, value }))}
                              onChangeValue={onChange}
                              value={value}
                            />
                          )}
                        />
                      </TableRowTwoCols>
                    ),
                  },
                  {
                    value: (
                      <TableRowTwoCols>
                        <Controller
                          name="mode"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              name="Modo Despacho"
                              label="Modo Despacho"
                              options={['Mensual', 'Diario', 'Sin Restricciones'].map((value) => ({
                                name: value,
                                value,
                              }))}
                              onChangeValue={onChange}
                              value={value}
                            />
                          )}
                        />
                        <Controller
                          name="keyId"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Input
                              placeholder="ID Tarjeta Llavero"
                              label="ID Tarjeta Llavero"
                              value={value}
                              onChange={onChange}
                            />
                          )}
                        />
                      </TableRowTwoCols>
                    ),
                  },
                ]
          }
        />
      </SidebarSlide>
    </>
  );
}
