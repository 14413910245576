export const HAASTEN = '#044d29';
export const HAASTEN_LIGHT = '#168039';
export const SECONDARY_ACCENT = '#1976d2';
export const DEFAULT_FONT_COLOR = '#cecece';
export const DEFAULT_BACKGROUND = 'black';
export const DEFAULT_BACKGROUND_CONTENT = '#121317';
export const DEFAULT_BACKGROUND_CONTENT_LIGHTER = '#1B1E23';
export const DEFAULT_BACKGROUND_CONTENT_EXTRA_LIGHTER = '#282C33';
export const BORDER_COLOR = 'rgba(255,255,255,.3)';
export const HOVER_COLOR = 'rgba(255,255,255,.05)';
export const HAASTEN_GRADIENT_BACKGROUND = `linear-gradient(90deg, ${HAASTEN} 0%,${HAASTEN_LIGHT} 100%)`;
export const OPACITY_LEVEL = 'rgba(255 ,255, 255, 0.07)';
export const ORANGE = '#ff9800';
