import HAASTEN_LOGO from 'assets/img/favicon-haasten.png';

export interface Marker {
  lat: number;
  lng: number;
  label: string;
  logo?: string;
  className?: string;
}

function MapMarker({
  lat,
  lng,
  label,
  logo = HAASTEN_LOGO,
  className,
}: Marker) {
  return (
    <div className={`map-marker ${className}`}>
      <span>{label}</span>
      <div className="map-marker-icon">
        <div className="map-marker-img">
          <img src={logo} alt="marker img" />
        </div>
      </div>
      <div className="map-marker-cone" />
    </div>
  );
}
export default MapMarker;

export function MapMarkerDot({ lat, lng, label, className }: Marker) {
  return (
    <div className={`map-marker-dot ${className}`}>
      <span>{label}</span>
      <div className="map-marker-dot-icon" />
    </div>
  );
}
