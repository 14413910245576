import { Checkbox, FormControlLabel } from '@material-ui/core';
import { FullScreenLoader } from 'components/loader';
import { MUISelect } from 'components/mui-select';
import ScreenMapSlide from 'components/screen-map-slide';
import TimeFilter from 'components/time-filter';
import ViewHeader from 'design-system/view-header';
import { useToggle } from 'hooks/useToggle';
import moment from 'moment';
import { useMemo, useState } from 'react';
import {
  distanciaCoordenadas,
  redondearDosDecimales,
} from 'utils/helpers/math';
import Map from './mapa';
import { FormattedDeviceEventGeneralLocation } from './parser';
import { useUbicacion } from './useUbicacion';

function Ubicacion() {
  const { isLoading, eventsGeneralLocation, eventsLoad, eventsDownload } =
    useUbicacion();

  const [showGeneralLocations, toggleShowGeneralLocation] = useToggle(true);
  const [showLoadLocations, toggleShowLoadLocations] = useToggle(true);
  const [showDownloadLocations, toggleShowDownloadLocations] = useToggle(true);
  const [rangoHorario, setRangoHorario] = useState([0, 24]);
  const [selectedLoadIngredients, setSelectedLoadIngredients] = useState<
    string[]
  >([]);
  const [selectedDownloadLots, setSelectedDownloadLots] = useState<string[]>(
    []
  );

  const SELECT_INGREDIENT_OPTIONS = useMemo(
    () =>
      eventsLoad.map((element, idx) => ({
        value: `${element.id}`,
        name: `${element.ingredient} - ${moment(
          element.date,
          'DD-MM-YYYY HH:mm:ss'
        ).format('HH:mm:ss')}`,
      })),
    [eventsLoad]
  );

  const SELECT_LOT_OPTIONS = useMemo(
    () =>
      eventsDownload.map((element, idx) => ({
        value: `${element.id}`,
        name: element.lot,
      })),
    [eventsDownload]
  );

  const filteredEventsGeneralLocation = useMemo(
    () =>
      eventsGeneralLocation.filter((event) => {
        const hora = Number(
          moment(event.date, 'DD-MM-YYYY HH:mm:ss').format('HH')
        );
        return hora > rangoHorario[0] && hora < rangoHorario[1];
      }),
    [eventsGeneralLocation, rangoHorario]
  );

  const filteredEventsLoad = useMemo(
    () =>
      eventsLoad.filter((event) => {
        const hora = Number(
          moment(event.date, 'DD-MM-YYYY HH:mm:ss').format('HH')
        );
        return (
          hora > rangoHorario[0] &&
          hora < rangoHorario[1] &&
          (selectedLoadIngredients.length === 0 ||
            selectedLoadIngredients.includes(`${event.id}`))
        );
      }),
    [eventsLoad, selectedLoadIngredients, rangoHorario]
  );

  const filteredEventsDownload = useMemo(
    () =>
      eventsDownload.filter((event) => {
        const hora = Number(
          moment(event.date, 'DD-MM-YYYY HH:mm:ss').format('HH')
        );
        return (
          hora > rangoHorario[0] &&
          hora < rangoHorario[1] &&
          (selectedDownloadLots.length === 0 ||
            selectedDownloadLots.includes(`${event.id}`))
        );
      }),
    [eventsDownload, selectedDownloadLots, rangoHorario]
  );

  const distanciaTotal = useMemo(
    () => computeTotalDistance(filteredEventsGeneralLocation),
    [filteredEventsGeneralLocation]
  );
  const costoCombustible = useMemo(
    () => computeCostoCombustible(filteredEventsGeneralLocation),
    [filteredEventsGeneralLocation]
  );
  const velocidadPromedio = useMemo(
    () => computeAverageSpeed(filteredEventsGeneralLocation),
    [filteredEventsGeneralLocation]
  );

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <ScreenMapSlide
        toolbar={<ViewHeader title="Ubicación" dateSelector="range" />}
        MapComponent={Map}
        mapProps={{
          eventsGeneralLocation: showGeneralLocations
            ? filteredEventsGeneralLocation
            : [],
          eventsLoad: showLoadLocations ? filteredEventsLoad : [],
          eventsDownload: showDownloadLocations ? filteredEventsDownload : [],
        }}
        slideContent={
          <div className="px-8 py-8">
            <div className="ubicacion-toolbar">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showGeneralLocations}
                    onChange={toggleShowGeneralLocation}
                  />
                }
                label="Desplazamiento"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showLoadLocations}
                    onChange={toggleShowLoadLocations}
                  />
                }
                label="Pesadas"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showDownloadLocations}
                    onChange={toggleShowDownloadLocations}
                  />
                }
                label="Descargas"
              />
            </div>
            <div className="ubicacion-toolbar">
              <MUISelect
                name="Ingrediente"
                label="Ingrediente"
                placeholder="Ingrediente"
                options={SELECT_INGREDIENT_OPTIONS}
                value={selectedLoadIngredients}
                onChangeValue={(value: any) =>
                  setSelectedLoadIngredients(value as string[])
                }
                multiple
              />
              <MUISelect
                name="lote"
                label="Lote"
                placeholder="Lote"
                options={SELECT_LOT_OPTIONS}
                value={selectedDownloadLots}
                onChangeValue={(value: any) =>
                  setSelectedDownloadLots(value as string[])
                }
                multiple
              />
            </div>
            <div>
              <TimeFilter onSetRangoHorario={setRangoHorario} />
            </div>
            <div className="ubicacion-toolbar py-8">
              <span>Distancia total: {distanciaTotal}km</span>
              <span>Costo combustible: $ {costoCombustible}</span>
              <span>Velocidad promedio: {velocidadPromedio}km/h</span>
            </div>
          </div>
        }
        toggleLabel={'opciones'}
      />
    </>
  );
}

export default Ubicacion;

function computeTotalDistance(
  locationEvents: FormattedDeviceEventGeneralLocation[]
): number {
  return (
    Math.round(
      Number(
        locationEvents.reduce(
          (acc, curr, idx) =>
            idx < locationEvents.length - 1
              ? acc +
                distanciaCoordenadas(
                  curr.coordinates.lat,
                  curr.coordinates.lng,
                  locationEvents[idx + 1].coordinates.lat,
                  locationEvents[idx + 1].coordinates.lng
                )
              : acc,
          0
        )
      ) * 100
    ) / 100
  );
}

function computeCostoCombustible(
  locationEvents: FormattedDeviceEventGeneralLocation[]
): number {
  return redondearDosDecimales(
    locationEvents.reduce(
      (acc, curr, idx) =>
        idx < locationEvents.length - 1
          ? acc +
            distanciaCoordenadas(
              curr.coordinates.lat,
              curr.coordinates.lng,
              locationEvents[idx + 1].coordinates.lat,
              locationEvents[idx + 1].coordinates.lng
            ) *
              (curr.fuelCostPerLitre ?? 0) *
              (curr.averageFuelConsumptionPerKilometer ?? 0)
          : acc,
      0
    )
  );
}

function computeAverageSpeed(
  locationEvents: FormattedDeviceEventGeneralLocation[]
): number {
  return locationEvents.length > 0
    ? Math.round(
        (Number(locationEvents.reduce((acc, curr) => acc + curr.speed, 0)) /
          locationEvents.length) *
          100
      ) / 100
    : 0;
}
