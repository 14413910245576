import {
  customBodyRenderNumberValue,
  customBodyRenderSelectedColumn,
} from 'components/table/TableSubsum';
import { customBodyRenderPercentage } from 'utils/helpers/body-renders';

export const columnsTablaCreadorRecetas = [
  {
    name: 'orden',
    label: 'Orden',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderSelectedColumn,
    },
  },
  {
    name: 'name',
    label: 'Alimento',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'kgMV',
    label: 'Kg/CAB - MV',
    options: { filter: false, sort: false },
  },
  {
    name: 'kgMS',
    label: 'Kg/CAB - MS',
    options: { filter: false, sort: false },
  },
  {
    name: 'porcentajeMateriaSeca',
    label: '%MS Receta',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderPercentage,
    },
  },
  {
    name: 'dryMatterPercentage',
    label: 'MS Ing',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderPercentage,
    },
  },
  {
    name: 'priceTotal',
    label: 'Total $',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderNumberValue,
    },
  },
];
