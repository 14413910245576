import moment from 'moment';
import { DeviceEventGAC, sortGac } from 'utils/device/event/gac/gac';

export default function parseDataGac(data: DeviceEventGAC[] = []): (DeviceEventGAC & { fecha: string })[] {
  return sortGac(data).map((element) => ({
    ...element,
    date: moment.utc(element.date).local().format('HH:mm'),
    fecha: moment.utc(element.date).local().format('DD-MM-YYYY HH:mm:ss'),
  }));
}
