import { FullScreenLoader } from 'components/loader';
import ScreenMapSlide from 'components/screen-map-slide';
import Table from 'components/table/Table';
import ViewHeader from 'design-system/view-header';
import { useCalendarQuery } from 'hooks/useCalendarQuery';
import { useColumns } from 'hooks/useColumns';
import { useEffect, useMemo, useState } from 'react';
import { useGetDeviceMixingDataQuery } from 'store/devices/devicesApi';
import Map from './mapa';
import parseFunction from './parser';

function Mezclado() {
  const { data: deviceMixingData = [], isFetching: isDeviceMixingDataFetching } =
    useCalendarQuery(useGetDeviceMixingDataQuery);

  const { columns, onColumnsViewChange } = useColumns('columnsMezclado');
  const [idsSelected, setIdsSelected] = useState([]);

  const dataMezclado = useMemo(() => parseFunction(deviceMixingData, []), [deviceMixingData]);
  const dataMezcladoMapa = useMemo(
    () =>
      dataMezclado.length > 0 && idsSelected.length > 0
        ? dataMezclado.filter((_mezclado, indexMezclado) => idsSelected.includes(indexMezclado))
        : [],
    [dataMezclado, idsSelected]
  );

  useEffect(() => {
    if (!idsSelected.length)
      if (dataMezclado.length > 0) {
        setIdsSelected([0]);
      }
  }, [dataMezclado, idsSelected.length]);

  return (
    <>
      {isDeviceMixingDataFetching && <FullScreenLoader />}
      <ScreenMapSlide
        toolbar={<ViewHeader title="Mezclado" dateSelector="range" />}
        MapComponent={Map}
        mapMarkers={dataMezcladoMapa}
        slideContent={
          <Table
            columns={columns}
            data={dataMezclado}
            options={{
              pagination: false,
              onRowsSelect: (currentRowsSelected, allRowsSelected) => {
                setIdsSelected(allRowsSelected.map((row) => row.dataIndex));
              },
              disableToolbarSelect: true,
              selectableRows: 'multiple',
              filter: false,
              rowsSelected: idsSelected,
            }}
            onColumnViewChange={onColumnsViewChange}
            rowsSelected={idsSelected}
          />
        }
        toggleLabel="tabla"
      />
    </>
  );
}

export default Mezclado;
