import ThreeDotsMenu from 'components/three-dots-menu';

export default function FormCard({
  iconLabel,
  label,
  menuItems = [],
  children,
}) {
  return (
    <div className="form-card">
      <div className="form-card-title-icon">
        <h3>{iconLabel}</h3>
      </div>
      <div className="form-card-title">
        <h2>{label}</h2>
        {menuItems.length > 0 && (
          <div className="card-three-dots-menu">
            <ThreeDotsMenu menuItems={menuItems} />
          </div>
        )}
      </div>
      <div className="form-card-content">{children}</div>
    </div>
  );
}
