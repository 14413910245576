import { DeviceDataEvent, DeviceEventAfimilk, filterAndSortyByAfimilk } from 'utils/device/event/sip';

type FlatDeviceEventAfimilk = DeviceDataEvent & DeviceEventAfimilk['data'];

export default function parseAfimilk(data: DeviceDataEvent[]): FlatDeviceEventAfimilk[] {
  return filterAndSortyByAfimilk(data).flatMap((event) => {
    const { data, ...baseProperties } = event;

    return data.map((afimilk) => {
      return {
        ...baseProperties,
        ...afimilk,
      } as unknown as FlatDeviceEventAfimilk;
    });
  });
}
