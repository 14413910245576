import { useSelector } from 'react-redux';
import { useGetDeviceAllQuery } from 'store/admin/adminDevicesApi';
import { selectIsUserAdmin } from 'store/config/configSlice';

export const useAdminGetDevices = () => {
  const isUserAdmin = useSelector(selectIsUserAdmin);
  const { data, isFetching } = useGetDeviceAllQuery(undefined, { skip: !isUserAdmin });

  return { data, isFetching };
};
