import { DeviceEventBase } from 'utils/device/event';

export type DeviceDataEventGAC = DeviceEventGAC;

export const ACTION_GAC = 'downloadUser';

type DeviceDataEventActionType = typeof ACTION_GAC;

export interface DeviceEventGAC extends DeviceEventBase {
  action: DeviceDataEventActionType;
  ltAvaiableTank: number;
  ltAvaiableUser: number;
  ltDownload: number;
  ltTotalsDownloaded: number;
  vehicle: string;
}

export function sortGac(data: DeviceEventGAC[], mostRecentFirst?: boolean) {
  return data
    .slice()
    .sort(
      (element1, element2) =>
        (mostRecentFirst && element1.date > element2.date && -1) ||
        (element1.date < element2.date && -1) ||
        1
    );
}
