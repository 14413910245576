import { Recipe } from 'utils/device/event/diet';
import { api as devicesApi, TAG_DEVICE, TAG_USER } from '../devices/devicesApi';

const api = devicesApi.injectEndpoints({
  endpoints: (builder) => ({
    addRecipe: builder.mutation<any, { receta: Recipe; serialNumber: string }>({
      query: ({ receta, serialNumber }) => {
        return {
          url: `device/addRecipe/${serialNumber}`,
          method: 'POST',
          body: receta,
        };
      },
      invalidatesTags: [TAG_USER],
    }),
    updateReceta: builder.mutation<any, { receta: Recipe; serialNumber: string }>({
      query: ({ receta, serialNumber }) => {
        return {
          url: `device/updateRecipe/${serialNumber}`,
          method: 'POST',
          body: receta,
        };
      },
      invalidatesTags: [TAG_USER],
    }),
    eliminarReceta: builder.mutation<any, { serialNumber: string; receta: Recipe }>({
      query: ({ serialNumber, receta: { id } }) => ({
        url: `device/deleteRecipe/${serialNumber}`,
        method: 'POST',
        body: { recipeId: id },
      }),
      invalidatesTags: [TAG_DEVICE],
    }),
  }),
  overrideExisting: true,
});

export const { useAddRecipeMutation, useUpdateRecetaMutation, useEliminarRecetaMutation } = api;
