import { useMemo } from 'react';
import { useThreeDayDescargas } from './useThreeDayDescargas';
import { useThreeDayLecturaComederos } from './useThreeDayLecturaComederos';
import { useThreeDayPesadas } from './useThreeDayPesadas';

export const useAccionesStats = () => {
  const pesadasSubtotales = useThreeDayPesadas();
  const descargasPoints = useThreeDayDescargas();
  const dataLecturaComederos = useThreeDayLecturaComederos();

  const points: any = useMemo(
    () =>
      pesadasSubtotales
        ?.concat(descargasPoints)
        .concat(dataLecturaComederos)
        .sort((a, b) => Number(a.date) - Number(b.date)),
    [dataLecturaComederos, descargasPoints, pesadasSubtotales]
  );

  return points || [];
};
