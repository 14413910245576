import { DeviceItem } from "utils/device";
import { Lot } from "utils/device/lot";

export const parseDescargas = (device: DeviceItem | null) =>
  device?.unloadGuides
    ?.map((descarga: any) => ({
      ...descarga,
      lots: descarga.lotIds
        .map((lotId: any) => device?.lots?.find((lot) => lot.id === lotId))
        .filter((l: any) => l) as Lot[],
    }))
    .sort((a, b) => {
      return a.id || b.id ? (!a.id ? -1 : !b.id ? 1 : a.id > b.id ? 1 : a.id < b.id ? -1 : 0) : 0;
    }) || [];
