import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import tw from 'twin.macro';

export const TextHeader = styled.h2`
  ${tw`text-2xl font-bold leading-7 text-neutral-200 sm:truncate sm:text-3xl sm:tracking-tight`}
`;

export const TextHeading = styled.h2`
  ${tw`text-lg font-semibold`}
`;

export const TextSubtitle = styled.p`
  ${tw`mt-1 max-w-2xl text-sm text-neutral-400`}
`;

export const TextParagraph = styled.p`
  ${tw`max-w-2xl text-base text-neutral-200 inline-block`}
`;

export const TextLink = styled(NavLink)`
  ${tw`text-base font-normal flex items-center px-2.5 py-1.5 rounded-md`}
  color: #C2C7CD80;

  &.active {
    ${tw`bg-haasten-800 text-off-white `}
  }
`;

export const TextStatisticWithIcon = ({
  Icon,
  children,
  color = '',
}: {
  Icon: React.FC;
  children: ReactNode;
  color?: string;
}) => {
  return (
    <div className="flex items-center gap-2">
      <div
        className={`w-7 h-7 py-1 px-1 rounded-md bg-haasten-700/50 ${color}`}
      >
        <Icon />
      </div>
      <TextParagraph>{children}</TextParagraph>
    </div>
  );
};
