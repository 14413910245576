import { UnloadGuide } from 'utils/device/lot';
import { api as devicesApi, TAG_USER } from '../devices/devicesApi';

const api = devicesApi.injectEndpoints({
  endpoints: (builder) => ({
    addUnloadGuide: builder.mutation<any, { descarga: UnloadGuide; serialNumber: string }>({
      query: ({ descarga, serialNumber }) => {
        return {
          url: `device/addUnloadGuide/${serialNumber}`,
          method: 'POST',
          body: descarga,
        };
      },
      invalidatesTags: [TAG_USER],
    }),
    updateUnloadGuide: builder.mutation<any, { descarga: UnloadGuide; serialNumber: string }>({
      query: ({ descarga, serialNumber }) => {
        return {
          url: `device/updateUnloadGuide/${serialNumber}`,
          method: 'POST',
          body: descarga,
        };
      },
      invalidatesTags: [TAG_USER],
    }),
    deleteUnloadGuide: builder.mutation<any, { descarga: UnloadGuide; serialNumber: string }>({
      query: ({ descarga, serialNumber }) => ({
        url: `device/deleteUnloadGuide/${serialNumber}`,
        method: 'POST',
        body: descarga,
      }),
      invalidatesTags: [TAG_USER],
    }),
  }),
  overrideExisting: true,
});

export const { useAddUnloadGuideMutation, useUpdateUnloadGuideMutation, useDeleteUnloadGuideMutation } = api;
