import {
  TableSubsumaRowValue,
  TableSubsumaResultadoRow,
} from 'components/table/TableSubsum';
import { RecipeIngredient, Recipe } from 'utils/device/event/diet';
import { Ingredient } from 'utils/device/stock';
import { redondearDosDecimales } from 'utils/helpers/math';

type AttributeToSum = 'kgMV' | 'kgMS' | 'porcentajeMateriaSeca' | 'priceTotal';
type ExtraAttributes = any;

export type RecipeIngredientSummed = RecipeIngredient &
  TableSubsumaRowValue & {
    kgMV: number;
    kgMS: number;
  };

type RecipeIngredientResultado = RecipeIngredient &
  TableSubsumaResultadoRow<AttributeToSum, ExtraAttributes>;

export type RecipeIngredientFormatted =
  | RecipeIngredientSummed
  | RecipeIngredientResultado;

const attributesPesadasToSum: AttributeToSum[] = [
  'kgMV',
  'kgMS',
  'porcentajeMateriaSeca',
  'priceTotal',
];

function newAttributesToSumRow(
  isFinal: boolean = false
): RecipeIngredientResultado {
  return {
    ...attributesPesadasToSum.reduce(
      (acc, curr): RecipeIngredientResultado => ({
        ...acc,
        [curr]: [, 0],
      }),
      {
        rowType: 'resultado',
        isResultadoFinal: isFinal,
      } as RecipeIngredientResultado
    ),
    extra: [] as any,
  };
}

export default function parseDataPesadas(
  receta: Recipe,
  stockIngredients: Ingredient[] = []
): RecipeIngredientFormatted[] {
  const { ingredients = [] } = receta || {};

  // Filtrar alimentos nuevos que no se hayan guardado y se hayan eliminado,
  // o sea que en el medio de la edicion de la receta se saco el alimento nuevo
  const filteredIngredients = ingredients.filter(
    (ingredient) => !(ingredient.nuevo && ingredient.deleted)
  );

  // Obtener totalKgTC y totalKgMS (se usan en el siguiente map)
  const totalKgMS = filteredIngredients
    .filter((ingredient) => !ingredient.deleted)
    .reduce(function (accumulated, current) {
      const currentKgMS = current.value * (current.dryMatterPercentage || 0);
      return accumulated + currentKgMS;
    }, 0);

  let currTotal = newAttributesToSumRow(true);

  return filteredIngredients.reduce(
    (acc, ingredient, index): RecipeIngredientFormatted[] => {
      const { value, dryMatterPercentage, name } = ingredient || {};

      const kgMS = value * (dryMatterPercentage || 0);

      const priceTotal =
        (stockIngredients.find(
          (stockIngredient) => stockIngredient.name === name
        )?.price || 0) * value;

      const currRow: RecipeIngredientSummed = {
        ...ingredient,
        kgMV: redondearDosDecimales(value),
        kgMS: redondearDosDecimales(kgMS),
        porcentajeMateriaSeca:
          totalKgMS > 0 ? redondearDosDecimales(kgMS / totalKgMS) : 0,
        priceTotal,
        orden: index + 1,
        rowType: 'value',
        semaforo: [],
      };

      currTotal = sumRowAttributes(currTotal, currRow);

      return [
        ...acc,
        currRow,
        ...(index === filteredIngredients.length - 1 ? [currTotal] : []),
      ];
    },
    [] as RecipeIngredientFormatted[]
  );
}

function sumRowAttributes(
  currSubsuma: RecipeIngredientResultado,
  row: RecipeIngredientSummed
): RecipeIngredientResultado {
  let newCurrSubsuma = { ...currSubsuma };
  attributesPesadasToSum.forEach((key) => {
    if (!row.deleted && row[key])
      newCurrSubsuma[key][1] = redondearDosDecimales(
        newCurrSubsuma[key][1] + row[key]
      );
  });
  return newCurrSubsuma;
}
