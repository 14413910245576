import moment, { Moment } from 'moment';
import { DeviceEventCas, groupCasDataBySilosAndCleanFalsePositives, sortCas } from 'utils/device/event/cas';

// Devuelve un arreglo con las fechas entre startDate y endDate, un elemento por hora
const getRangeOfDates = (startDate: Date, endDate: Date | string) => {
  const start = moment.utc(startDate);
  const end = moment.utc(endDate);

  const range: Moment[] = [];

  while (start <= end) {
    range.push(moment.utc(start));
    start.add(1, 'hours');
  }

  return range;
};

type DataCasPointHour = {
  year: number;
  month: number;
  day: number;
  hour: number;
  points: DeviceEventCas[];
  averageIth: number;
  kgConsumidos: number;
  fecha: string;
};

// Devuelve un objeto con los datos de cada silo agrupados por hora (25 puntos por día por silo, entre la minima y maxima fecha del arreglo data)
export const getKgConsumidosPorHora = (data: DeviceEventCas[] = []) => {
  // Agrupar por silo
  let dataGroupedBySilo = groupCasDataBySilosAndCleanFalsePositives(sortCas(data));

  let stats: Record<string, DataCasPointHour[]> = {};

  // Agrupar los datos de cada silo por hora
  Object.entries(dataGroupedBySilo).forEach(([siloName, siloData]) => {
    const startDate = siloData[0]?.date;
    const lastDate = siloData?.[siloData?.length - 1]?.date;

    stats[siloName] = getRangeOfDates(new Date(startDate), new Date(lastDate)).map((d) => {
      const year = d.year();
      const month = d.month() + 1;
      const day = d.date();
      const hour = d.hours();

      const points = siloData?.filter((item) => {
        if (!item.temperature || !item.humidity) return false;

        const pointFecha = moment.utc(new Date(item.date), 'DD-MM-YYYY HH:mm:ss');
        return (
          pointFecha.year() === d.year() &&
          pointFecha.month() + 1 === d.month() + 1 &&
          pointFecha.date() === d.date() &&
          pointFecha.hours() === d.hours()
        );
      });

      const averageIth = points?.reduce((acc, point) => acc + point.ithValue, 0) / points?.length;
      const kgConsumidos = points?.reduce((acc, point) => acc + (point.kgConsumidos || 0), 0);

      return {
        year,
        month,
        day,
        hour,
        points,
        averageIth,
        kgConsumidos,
        fecha: `${day}/${month} ${hour}hs`,
      };
    });
  });

  return stats;
};
