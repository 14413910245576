export const columnsGacVehicles = [
  {
    name: 'id',
    label: 'ID',
    options: { filter: true, sort: true },
  },
  {
    name: 'name',
    label: 'Nombre',
    options: { filter: true, sort: true },
  }
];
