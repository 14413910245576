import {
  MiddlewareAPI,
  isRejectedWithValue,
  Middleware,
} from '@reduxjs/toolkit';
// import {  toast } from 'react-toastify';

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (
  next
) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  // fix para q no salga el banner ni bien hace el login
  if (
    isRejectedWithValue(action) &&
    action?.meta?.arg?.endpointName !== 'getUser'
  ) {
    // toast.error('La operación no se pudo completar.')
    console.log(
      `Falló la comunicación con el servidor (${action?.meta?.arg?.endpointName}).`
    );
  }

  return next(action);
};
