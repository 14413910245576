import { HAASTEN_LIGHT } from 'components/colors';
import { DeviceEvent } from 'utils/device/event/sip/event';

export default function parseDeviceEventsHistory(
  data: DeviceEvent[] = [],
  onEventoClick?: (event: DeviceEvent) => any
) {
  return data
    .map((element) => ({
      ...element,
      action: element.data?.action,
      description: getEventoDescription(element, onEventoClick),
    }))
    .sort((element1, element2) => (element1.date > element2.date && -1) || 1);
}

function getEventoDescription(
  element: DeviceEvent,
  onEventoClick: (event: DeviceEvent) => any = () => {}
) {
  const onClick = () => onEventoClick(element);
  let descripcion: any = '';
  switch (element.data?.action) {
    case 'addRecipeFromServer':
    case 'addRecipeFromClient':
    case 'recipeAddFromDevice':
    case 'recipeAddFromClient':
    case 'recipeCreateFromDevice':
    case 'recipeCreateFromClient':
      descripcion = (
        <>
          {getExpandableDescription(
            element.data?.action,
            element?.data?.newValue?.name
          )}
          <VerMasBoton onClick={onClick} />
        </>
      );
      break;
    case 'recipeUpdateFromDevice':
    case 'recipeUpdateFromClient':
    case 'updatedRecipeFromClient':
    case 'updatedRecipeFromServer':
      descripcion = (
        <>
          {getExpandableDescription(
            element.data?.action,
            element?.data?.prevValue?.name
          )}
          <VerMasBoton onClick={onClick} />
        </>
      );
      break;
    case 'recipeDeleteFromDevice':
    case 'recipeDeleteFromClient':
      descripcion = getExpandableDescription(
        element.data?.action,
        element?.data?.prevValue?.name
      );
      break;
    case 'lotUpdatefromClient':
    case 'lotUpdateFromDevice':
      descripcion = (
        <>
          {getExpandableDescription(
            element.data?.action,
            element?.data?.prevValue?.name
          )}
          <VerMasBoton onClick={onClick} />
        </>
      );
      break;
    case 'unloadGuideCreateFromClient':
    case 'unloadGuideCreateFromDevice':
      descripcion = (
        <>
          {getExpandableDescription(
            element.data?.action,
            element?.data?.newValue?.name
          )}
        </>
      );
      break;
    case 'unloadGuideUpdateFromDevice':
    case 'unloadGuideUpdateFromClient':
      descripcion = (
        <>
          {getExpandableDescription(
            element.data?.action,
            element?.data?.prevValue?.name
          )}
          <VerMasBoton onClick={onClick} />
        </>
      );
      break;
    case 'unloadGuideDeleteFromDevice':
    case 'unloadGuideDeleteFromClient':
      descripcion = getExpandableDescription(
        element.data?.action,
        element?.data?.prevValue?.name
      );
      break;

    case 'updateLotNameFromClient':
      descripcion = getUpdateDescription(
        element.data?.action,
        'nombre',
        'lote',
        element?.data?.prevValue,
        element?.data?.newValue
      );
      break;
    case 'updateLotHeadsCountFromClient':
      descripcion = getUpdateDescription(
        element.data?.action,
        'contador de cabezas',
        'lote',
        element?.data?.prevValue,
        element?.data?.newValue
      );
      break;
    case 'dryMatterUpdateFromClient':
    case 'dryMatterUpdateFromDevice':
      descripcion = getUpdateDescription(
        element.data?.action,
        'materia seca',
        element?.data?.ingredient,
        element?.data?.prevValue * 100,
        element?.data?.newValue * 100
      );
      break;
    case 'priceUpdateFromClient':
    case 'priceUpdateFromDevice':
      descripcion = getUpdateDescription(
        element.data?.action,
        'precio',
        element?.data?.ingredient,
        element?.data?.prevValue,
        element?.data?.newValue
      );
      break;
    case 'stockUpdateFromClient':
      descripcion = getUpdateDescription(
        element.data?.action,
        'stock',
        element?.data?.ingredient,
        element?.data?.prevValue,
        element?.data?.newValue,
        element?.data?.detail
      );
      break;
    case 'stockUpdateFromDevice':
      descripcion = getUpdateDescription(
        element.data?.action,
        'stock',
        element?.data?.ingredient,
        element?.data?.prevValue,
        element?.data?.newValue
      );
      break;
    case 'nameUpdateFromClient':
    case 'nameUpdateFromDevice':
      descripcion = getUpdateDescription(
        element.data?.action,
        'nombre',
        element?.data?.ingredient,
        element?.data?.prevValue,
        element?.data?.newValue
      );
      break;
    case 'riskIngredientUpdateFromClient':
    case 'riskIngredientUpdateFromDevice':
      descripcion = getUpdateDescription(
        element.data?.action,
        'riesgo',
        element?.data?.ingredient,
        element?.data?.prevValue,
        element?.data?.newValue || !element?.data?.prevValue
      );
      break;
    case 'automaticCorrectionUpdateFromClient':
    case 'automaticCorrectionUpdateFromDevice':
      descripcion = getUpdateDescription(
        element.data?.action,
        'correción automática',
        element?.data?.ingredient,
        element?.data?.prevValue,
        element?.data?.newValue
      );
      break;
    case 'numberAutomaticUpdateFromClient':
    case 'numberAutomaticUpdateFromDevice':
      descripcion = getUpdateDescription(
        element.data?.action,
        'Nº A',
        element?.data?.ingredient,
        element?.data?.prevValue,
        element?.data?.newValue
      );
      break;
    case 'isAutomatedUpdateFromClient':
    case 'isAutomatedUpdateFromDevice':
      descripcion = getUpdateDescription(
        element.data?.action,
        'automatizado',
        element?.data?.ingredient,
        element?.data?.prevValue,
        element?.data?.newValue
      );
      break;
    case 'modeUpdateFromClient':
    case 'modeUpdateFromDevice':
      descripcion = getUpdateDescription(
        element.data?.action,
        'modo',
        element?.data?.ingredient,
        element?.data?.prevValue,
        element?.data?.newValue
      );
      break;

    default:
      break;
  }
  return descripcion;
}

function getUpdateDescription(
  action: string,
  property: string,
  element: any,
  prevValue: any,
  newValue: any,
  detail?: string
) {
  const from = action.toLowerCase().includes('device')
    ? 'el dispositivo'
    : 'la web';
  return `Se actualizó ${property} de ${element} de ${prevValue} a ${newValue} desde ${from}. ${
    detail ? `Detalle: ${detail}` : ''
  }`;
}

function getExpandableDescription(action: string, element: any) {
  const parsedAction = action.toLowerCase().includes('update')
    ? 'actualizó'
    : action.toLowerCase().includes('delete')
    ? 'eliminó'
    : action.toLowerCase().includes('add') ||
      action.toLowerCase().includes('create')
    ? 'creó'
    : '';
  const parsedProperty = action.toLowerCase().includes('recipe')
    ? 'receta'
    : action.toLowerCase().includes('lot')
    ? 'lote'
    : action.toLowerCase().includes('unloadguide')
    ? 'guía de descarga'
    : '';

  const from = action.toLowerCase().includes('device')
    ? 'el dispositivo'
    : 'la web';

  return `Se ${parsedAction} ${parsedProperty} ${element} desde ${from}. `;
}

function VerMasBoton({
  onClick = () => {},
}: {
  onClick: (value?: any) => any;
}) {
  return (
    <>
      <span
        onClick={() => onClick()}
        style={{ cursor: 'pointer', color: HAASTEN_LIGHT }}
      >
        Ver más...
      </span>
    </>
  );
}
