import CheckIcon from '@material-ui/icons/Check';

export const columnsLotes = [
  {
    name: 'id',
    label: 'Balanza',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value) => (value ? <CheckIcon style={{ color: '#4caf50' }} size="small" /> : null),
    },
  },
  {
    name: 'name',
    label: 'Lote',
    options: { filter: false, sort: true },
  },
  {
    name: 'headsCount',
    label: 'Cabezas',
    options: { filter: false, sort: true },
  },
];

export const columnsLotesV2 = columnsLotes.concat([
  {
    name: 'kgHeads',
    label: 'kg/Cabezas MS',
    options: { filter: false, sort: true },
  },
]);

export const columnsLotesV6 = columnsLotesV2.concat([
  {
    name: 'category',
    label: 'Categoría',
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value) => {
        if (value === 'nonProducing') return 'Secas';
        if (value === 'milking') return 'Ordeñe';
        if (value === 'infirmary') return 'Enfermería';
        if (value === 'other') return 'Otros';
        return '';
      },
    },
  },
]);
