import { customBodyRenderDate } from 'utils/helpers/body-renders';

export const columnsAfimilk = [
  {
    name: 'date',
    label: 'Fecha',
    options: {
      filter: false,
      sort: false,
      viewColumns: false,
      customBodyRender: customBodyRenderDate,
    },
  },
  {
    name: 'Indice',
    label: 'Indice',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'Estado y patio',
    label: 'Estado y patio',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'Total de animales',
    label: 'Total de animales',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'DEL',
    label: 'DEL',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'Ordeñadas si',
    label: 'Ordeñadas si',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'No Ordeñadas',
    label: 'No Ordeñadas',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'Producción Total',
    label: 'Producción Total',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'Leche Últimas 24H',
    label: 'Leche Últimas 24H',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'Última semana',
    label: 'Última semana',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'Min vaca',
    label: 'Min vaca',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'Min leche',
    label: 'Min leche',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'Max vaca',
    label: 'Max vaca',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'Max leche',
    label: 'Max leche',
    options: {
      filter: false,
      sort: false,
    },
  },
];
