import { useSelector } from 'react-redux';
import { selectUser } from 'store/config/configSlice';

const ROLE_ADMIN_USER = 'adminUser';

export function ProtectedView({ active, children }) {
  const { roles = [] } = useSelector(selectUser);
  if (!active || roles.includes(ROLE_ADMIN_USER)) return children;
  return null;
}
