import Chip from 'design-system/chip';
import { IconQuestionMark } from 'design-system/icon';
import { ReactNode } from 'react';

export const ChipQuestionMark = ({ children }: { children?: ReactNode }) => {
  return (
    <Chip
      chipClassName="text-neutral-400"
      iconClassName="bg-neutral-400/10"
      Icon={<IconQuestionMark />}
    >
      <span>{children}</span>
    </Chip>
  );
};
