import { FullScreenLoader } from 'components/loader';
import { useLastMonthQuery } from 'hooks/useCalendarQuery';
import { useMemo } from 'react';
import { useGetDeviceDataQuery } from 'store/devices/devicesApi';
import { DeviceEventGAC } from 'utils/device/event/gac/gac';
import { GacChart } from './GacChart';
import parseFunction from './parser';
import ViewHeader from 'design-system/view-header';

export type ChartDataPoint = {
  date: string;
  temperature: number;
  humidity: number;
};

export function GAC() {
  const { data: deviceData = [], isLoading } = useLastMonthQuery(useGetDeviceDataQuery);
  const gacData = useMemo(() => parseFunction(deviceData as any as DeviceEventGAC[]), [deviceData]);

  return (
    <>
      <ViewHeader title="GAC" />
      {isLoading && <FullScreenLoader />}
      {!gacData.length ? (
        <div className="content-view px-8">
          <p className="text-2xl font-bold">No hay datos para mostrar</p>
        </div>
      ) : (
        <GacChart dataGacHistorico={gacData} />
      )}
    </>
  );
}
