import { customBodyRenderDate } from 'utils/helpers/body-renders';

export const columnsCmasHistorico = [
  {
    name: 'date',
    label: 'Fecha',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderDate,
    },
  },
  {
    name: 'ingredient',
    label: 'Ingrediente',
    options: { filter: true, sort: false },
  },
  {
    name: 'dryMatter',
    label: '%MS',
    options: { filter: false, sort: false },
  },
  {
    name: 'dryingMode',
    label: 'Modo Secado',
    options: { filter: false, sort: false },
  },
  {
    name: 'dryingTime',
    label: 'Tiempo secado (min)',
    options: { filter: false, sort: false },
  },
  {
    name: 'startingWeight',
    label: 'Peso Inicial (gr)',
    options: { filter: false, sort: false },
  },
  {
    name: 'finalWeight',
    label: 'Peso Final (gr)',
    options: { filter: false, sort: false },
  },
  {
    name: 'validationPercentage',
    label: '%Val',
    options: { filter: false, sort: false },
  },
  {
    name: 'numberOfCycles',
    label: 'N° Ciclos',
    options: { filter: false, sort: false },
  },
];
