import { HAASTEN_LIGHT, SECONDARY_ACCENT } from 'components/colors';
import { DatePickerComponent } from 'design-system/date-picker';
import { FormItem } from 'design-system/input';
import { TextHeading } from 'design-system/text';
import { useState } from 'react';
import { DeviceEventITH } from 'utils/device/event/ith';
import { redondearSinDecimales, redondearUnDecimales } from 'utils/helpers/math';
import { useHistoricoPromedioStats } from './useHistoricoPromedioStats';

const minPossibleTime = new Date();
const maxPossibleTime = new Date();
minPossibleTime.setHours(0, 0, 0, 0);
maxPossibleTime.setHours(23, 59, 59, 999);

interface ITHHistoricoPromedioProps {
  dataITHHistorico: (DeviceEventITH & { fecha: string })[];
}

export const ITHHistoricoPromedio = ({ dataITHHistorico }: ITHHistoricoPromedioProps) => {
  // Keep track of the selected date in the time picker
  const [startTime, setStartTime] = useState<Date | null>(minPossibleTime);
  const [endTime, setEndTime] = useState<Date | null>(maxPossibleTime);

  const stats = useHistoricoPromedioStats(dataITHHistorico).filter(
    (item) =>
      item.points?.length &&
      item.hour >= (startTime?.getHours() || 0) &&
      (!endTime?.getHours() || (endTime?.getHours() === 23 && endTime?.getMinutes() === 59)
        ? item.hour < 24
        : item.hour < endTime.getHours())
  );

  const averageHumidity = stats?.reduce((acc, point) => acc + point.averageHumidity, 0) / stats?.length;
  const averageTemperature = stats?.reduce((acc, point) => acc + point.averageTemperature, 0) / stats?.length;
  const averageIth = stats?.reduce((acc, point) => acc + point.averageIth, 0) / stats?.length;

  return (
    <div className="flex flex-col gap-4">
      <div className="grid gap-4 items-end">
        <div className="grid sm:grid-cols-2 gap-4 ">
          <div className="min-w-[80px] ">
            <FormItem label="Desde">
              <DatePickerComponent
                selected={startTime}
                onChange={(date: any) => setStartTime(date)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={60}
                timeCaption="Desde"
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                minTime={minPossibleTime}
                maxTime={endTime || maxPossibleTime}
              />
            </FormItem>
          </div>
          <div className="min-w-[80px]">
            <FormItem label="Hasta">
              <DatePickerComponent
                selected={endTime}
                onChange={(date: any) => setEndTime(date)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={60}
                timeCaption="Hasta"
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                minTime={startTime || minPossibleTime}
                maxTime={maxPossibleTime}
                injectTimes={[maxPossibleTime]}
              />
            </FormItem>
          </div>
        </div>

        <div className="grid sm:grid-cols-3 gap-4">
          <div
            className="p-2 rounded-xl border flex justify-center items-center text-center "
            style={{ borderColor: SECONDARY_ACCENT }}
          >
            Humedad: {redondearUnDecimales(averageHumidity)}%
          </div>
          <div
            className="p-2 rounded-xl border border-blue-500/70 flex justify-center items-center text-center"
            style={{ borderColor: HAASTEN_LIGHT }}
          >
            Temperatura: {redondearUnDecimales(averageTemperature)}°C
          </div>
          <div className="p-2 rounded-xl border border-purple-500/70 flex justify-center items-center text-center ">
            ITH: {redondearSinDecimales(averageIth)}
          </div>
        </div>
      </div>
    </div>
  );
};
