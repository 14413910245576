import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { logout } from 'store/config/configSlice';
import { UnloadGuide, Lot } from 'utils/device/lot';
import type { RootState } from '../store';

type DescargasState = {
  descargaEditing: UnloadGuide;
};

const initialState: DescargasState = {
  descargaEditing: {
    id: 0,
    name: '',
    lotIds: [],
  },
};

const slice = createSlice({
  name: 'descargas',
  initialState,
  reducers: {
    resetDescarga(state) {
      state.descargaEditing = {
        id: 0,
        name: '',
        lotIds: [],
      };
    },
    editDescarga(state, action: PayloadAction<any>) {
      state.descargaEditing = action.payload;
    },
    onDescargaFormChange(state, action: PayloadAction<UnloadGuide>) {
      state.descargaEditing = action.payload;
    },
    toggleLote(state, action: PayloadAction<Lot>) {
      if (!state.descargaEditing.lots) state.descargaEditing.lots = [];
      let lotFound = false;
      state.descargaEditing.lots = state.descargaEditing.lots.map((lot) => {
        if (lot.id === action.payload.id) {
          lotFound = true;
          return { ...lot, deleted: !lot.deleted };
        } else return lot;
      });
      if (!lotFound)
        state.descargaEditing.lots.push({
          ...action.payload,
          nuevo: true,
        });
    },
    onMoveDescargaRowUp(state, action: PayloadAction<{ rowIndex: number }>) {
      const { rowIndex } = action.payload;
      if (state.descargaEditing.lots && state.descargaEditing.lots.length > rowIndex && rowIndex > 0) {
        state.descargaEditing.lots.splice(rowIndex - 1, 0, state.descargaEditing.lots.splice(rowIndex, 1)[0]);
      }
    },
    onMoveDescargaRowDown(state, action: PayloadAction<{ rowIndex: number }>) {
      const { rowIndex } = action.payload;
      if (state.descargaEditing.lots && state.descargaEditing.lots.length > rowIndex + 1) {
        state.descargaEditing.lots.splice(rowIndex + 1, 0, state.descargaEditing.lots.splice(rowIndex, 1)[0]);
      }
    },
  },
  extraReducers: (builder) => {
    // Reset state on logout
    builder.addCase(logout, () => {
      return initialState;
    });
  },
});

export default slice.reducer;

export const selectDescargaEditing = (state: RootState) => state.descargas.descargaEditing;

export const descargasActions = slice.actions;

export const {
  resetDescarga,
  editDescarga,
  onDescargaFormChange,
  toggleLote,
  onMoveDescargaRowDown,
  onMoveDescargaRowUp,
} = slice.actions;
