import AppNavigationSidebar from 'components/app-navigation-sidebar';
import Icon from 'components/icons';
import { Logo } from 'design-system/logo';
import { AppMobileHeader } from 'design-system/sidebar';
import { useAuth } from 'hooks/useAuth';
import { useState } from 'react';

function AppNavigation() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const onSidebarOpen = () => setIsSidebarOpen(true);
  const onSidebarClose = () => setIsSidebarOpen(false);

  const { logout } = useAuth();

  return (
    <div className="app-navigation">
      <AppMobileHeader>
        <Icon name="hamburger-icon" onClick={onSidebarOpen} />
        <Logo />
      </AppMobileHeader>
      <AppNavigationSidebar
        isOpen={isSidebarOpen}
        onClose={onSidebarClose}
        onLogout={logout}
      />
    </div>
  );
}

export default AppNavigation;
