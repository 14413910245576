export const columnsAdminDevices = [
  {
    name: 'username',
    label: 'Usuario',
    options: { filter: false, sort: true },
  },
  {
    name: 'email',
    label: 'Email',
    options: { filter: false, sort: true },
  },
  {
    name: 'roles',
    label: 'Rol',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) =>
        Array.isArray(value) ? value.join(', ') : value,
    },
  },
];
