import { HAASTEN_LIGHT, SECONDARY_ACCENT } from 'components/colors';
import FormCard, { FormCardIcon } from 'design-system/form-card';
import { IconBarChart } from 'design-system/icon';
import {
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { DeviceEventITH } from 'utils/device/event/ith';
import { redondearUnDecimales } from 'utils/helpers/math';
import { useHistoricoPromedioStats } from './useHistoricoPromedioStats';

interface ITHPromedioChartsProps {
  dataITHHistorico: (DeviceEventITH & { fecha: string })[];
}

export const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="flex flex-col p-4 bg-white/90 rounded-md">
        <p className="text-black">{label}</p>
        {payload.map((item: any) => (
          <p
            key={item.name}
            className="font-bold"
            style={{
              color: item.stroke || item.fill || item.color,
            }}
          >
            {item.name}: {item.value}
          </p>
        ))}
      </div>
    );
  }

  return null;
};

export const ITHPromedioCharts = ({ dataITHHistorico }: ITHPromedioChartsProps) => {
  const stats = useHistoricoPromedioStats(dataITHHistorico);

  const data = stats.map((item) => ({
    name: item.fecha,
    Humedad: redondearUnDecimales(item.averageHumidity),
    Temperatura: redondearUnDecimales(item.averageTemperature),
    ITH: redondearUnDecimales(item.averageIth),
  }));

  return (
    <div className="content-view px-8 py-8">
      <FormCard
        title="Valores promedio por hora"
        icon={
          <FormCardIcon className="px-2 py-2">
            <IconBarChart />
          </FormCardIcon>
        }
        items={[
          {
            value: (
              // Temperatura y humedad
              <div className="w-full h-[70vh] relative">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                      top: 20,
                      right: 0,
                      left: 0,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" opacity={0.5} />
                    <XAxis dataKey="name" />
                    <YAxis yAxisId="left" orientation="left" stroke={SECONDARY_ACCENT} />
                    <YAxis yAxisId="right" orientation="right" stroke={HAASTEN_LIGHT} />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend verticalAlign="top" />
                    <Brush
                      dataKey="name"
                      height={30}
                      stroke={HAASTEN_LIGHT}
                      fill={'rgba(0,0,0,0)'}
                      startIndex={data.length - 15}
                    />
                    <Bar yAxisId="left" dataKey="Humedad" fill={SECONDARY_ACCENT} />
                    <Bar yAxisId="right" dataKey="Temperatura" fill={HAASTEN_LIGHT} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            ),
          },
          {
            value: (
              // ITH
              <div className="w-full h-[70vh] relative">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                      top: 20,
                      right: 0,
                      left: 0,
                      bottom: 5,
                    }}
                  >
                    <Line type="monotone" yAxisId="left" dataKey="ITH" name="ITH" stroke={'#a855f7'} />
                    <CartesianGrid stroke="#ccc" strokeDasharray="3 3" opacity={0.5} />
                    <XAxis dataKey="name" />
                    <YAxis yAxisId="left" dataKey="ITH" orientation="left" />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend verticalAlign="top" />
                    <Brush dataKey="name" height={30} stroke={HAASTEN_LIGHT} fill={'rgba(0,0,0,0)'} />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};
