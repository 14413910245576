import { useState } from 'react';

export function useModal<T>(): [
  boolean,
  (element: T | null) => void,
  () => void,
  T | null
] {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [element, setElement] = useState<T | null>(null);

  function onOpen(e: T | null) {
    setIsOpen(true);
    setElement(e);
  }

  function onClose() {
    setIsOpen(false);
    setElement(null);
  }

  return [isOpen, onOpen, onClose, element];
}
