import { FullScreenLoader } from 'components/loader';
import { ChipFail } from 'design-system/chip/chip-fail';
import TableRow from 'design-system/table/table-row';
import { TextSubtitle } from 'design-system/text';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { FiTrash2 } from 'react-icons/fi';
import { useDeleteDeviceMutation } from 'store/admin/adminDevicesApi';
import { useGetDeviceLatestControlMessageQuery } from 'store/devices/devicesApi';
import { Device } from 'utils/device';
import { customBodyRenderDate, customBodyRenderDateToLocal } from 'utils/helpers/body-renders';

interface AdminDevicesRowProps {
  device: Device;
}

export function AdminDevicesRow({ device }: AdminDevicesRowProps) {
  const { data: latestControlMessage } = useGetDeviceLatestControlMessageQuery({
    serialNumber: device.serialNumber,
  });

  const [onDeleteDevice, { isLoading: isDeletingDevice }] = useDeleteDeviceMutation();

  const onClickEliminarDevice = (device: Device) => {
    // eslint-disable-next-line no-restricted-globals
    const confirmEliminar = confirm(
      `¿Está seguro que desea eliminar el equipo ${device.serialNumber}? Esta operación no se puede revertir.`
    );
    if (confirmEliminar) onDeleteDevice(device.serialNumber);
  };

  const equipoDesconectado = moment.utc().diff(moment.utc(device.lastDataRecievedDate), 'hours') > 3;
  const fallaRelojUC =
    moment
      .utc(latestControlMessage?.date)
      .subtract(3, 'hours')
      .diff(moment.utc(latestControlMessage?.creationTime), 'minutes') > 20;
  const fallaUCDesconectada = moment.utc().diff(moment.utc(latestControlMessage?.date), 'hours') > 12;
  const fallaSDDaniada =
    latestControlMessage?.pAS === 0 ||
    latestControlMessage?.idLA === 0 ||
    latestControlMessage?.pASMC === 0 ||
    latestControlMessage?.idLAMC === 0;

  return (
    <>
      {isDeletingDevice && <FullScreenLoader />}
      <TableRow
        title={`${device.alias ? `${device.alias} - ` : ''}${device.serialNumber}`}
        titleChips={[
          ...(equipoDesconectado
            ? [
                <div className="transform scale-75 ">
                  <ChipFail>Desconectado</ChipFail>
                </div>,
              ]
            : []),
          ...(fallaRelojUC
            ? [
                <div className="transform scale-75 ">
                  <ChipFail>Error reloj UC</ChipFail>
                </div>,
              ]
            : []),
          ...(fallaUCDesconectada
            ? [
                <div className="transform scale-75 ">
                  <ChipFail>UC desconectada</ChipFail>
                </div>,
              ]
            : []),
          ...(fallaSDDaniada
            ? [
                <div className="transform scale-75 ">
                  <ChipFail>SD Dañada</ChipFail>
                </div>,
              ]
            : []),
        ]}
        infoLabels={[
          `Stock: ${device.sipnStock?.id || ''}`,
          `CMAS asociado: ${device.associatedCmas?.serialNumber || ''}`,
          `Consumo combustible x km: ${device.averageFuelConsumptionPerKilometer || ''}`,
          `Costo combustible x km: ${device.fuelCostPerKilometer || ''}`,
          `MS modifica receta: ${device.dryMatterModifiesRecipes ? 'Sí' : 'No'}`,
          `Recetas: ${device.recipes?.length || ''}`,
          `Guías de descarga: ${device.unloadGuides?.length || ''}`,
          `Lotes: ${device.lots?.length || ''}`,
          `Versión: ${device.ver || ''}`,
        ]}
        extraContent={
          <div className="flex flex-col gap-2 pt-2">
            <TextSubtitle className={equipoDesconectado ? '!text-red-500' : ''}>
              Última conexión MCRF:{' '}
              {moment.utc(device.lastDataRecievedDate).local().format('HH:mm:ss DD-MM-YYYY') || ''}
            </TextSubtitle>
            {!isEmpty(latestControlMessage) && (
              <>
                <TextSubtitle>Último mensaje de control: </TextSubtitle>
                <div className="flex flex-col gap-2 pl-4">
                  <span className={fallaRelojUC || fallaUCDesconectada ? '!text-red-500' : ''}>
                    Fecha: {customBodyRenderDateToLocal(latestControlMessage?.date)}
                  </span>
                  <span className={fallaRelojUC ? '!text-red-500' : ''}>
                    Hora guardada: {customBodyRenderDate(latestControlMessage?.creationTime)}
                  </span>
                  <span>Temperatura: {latestControlMessage?.temp}</span>
                  <span>vTractor: {latestControlMessage?.vTractor}</span>
                  <span>iUC: {latestControlMessage?.iUC}</span>
                  <span>Signal: {latestControlMessage?.signal}</span>
                  <span className={latestControlMessage?.pAS === 0 ? '!text-red-500' : ''}>
                    1°Arch.Enviar UC: {latestControlMessage?.pAS}
                  </span>
                  <span className={latestControlMessage?.idLA === 0 ? '!text-red-500' : ''}>
                    IDActual UC: {latestControlMessage?.idLA}
                  </span>
                  <span>Cantidad Datos UC: {latestControlMessage?.nAS}</span>
                  <span className={latestControlMessage?.pASMC === 0 ? '!text-red-500' : ''}>
                    1°Arch.Enviar MC: {latestControlMessage?.pASMC}
                  </span>
                  <span className={latestControlMessage?.idLAMC === 0 ? '!text-red-500' : ''}>
                    IDActual MC: {latestControlMessage?.idLAMC}
                  </span>
                  <span>Cantidad Datos MC: {latestControlMessage?.nASMC}</span>
                </div>
              </>
            )}
          </div>
        }
        action={
          <FiTrash2
            style={{
              color: '#fff',
              fontSize: '1.5rem',
              cursor: 'pointer',
            }}
            onClick={(event) => {
              event.stopPropagation();
              onClickEliminarDevice(device);
            }}
          />
        }
        clickable
      />
    </>
  );
}
