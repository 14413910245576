import { FormCardIcon } from 'design-system/form-card';
import { IconBarChart } from 'design-system/icon';
import { TextHeading } from 'design-system/text';
import { DeviceEventITH } from 'utils/device/event/ith';
import { ITHHistoricoPromedio } from './ITHHistoricoPromedio';

interface ITHPromedioRangoHorarioProps {
  dataITHHistorico: (DeviceEventITH & { fecha: string })[];
}

export const ITHPromedioRangoHorario = ({ dataITHHistorico }: ITHPromedioRangoHorarioProps) => {
  return (
    <div className="content-view px-8 py-8 ">
      <div className="bg-background-black tablet:rounded-lg text-neutral-200 border border-white/10  grid">
        <div className="flex justify-between items-center px-4 py-5 tablet:px-6 border-b border-white/10 ">
          <TextHeading>Valores promedio por rango horario</TextHeading>
          <FormCardIcon className="px-2 py-2">
            <IconBarChart />
          </FormCardIcon>
        </div>

        <div className="px-4 py-5 tablet:px-6 bg-neutral-900">
          <ITHHistoricoPromedio dataITHHistorico={dataITHHistorico} />
        </div>
      </div>
    </div>
  );
};
