import moment from 'moment';
import { DeviceEventBase } from 'utils/device/event';

export type DeviceDataEventCAS = DeviceEventCas;

export const ACTION_CAS = 'currentStateSilo';

type DeviceDataEventActionType = typeof ACTION_CAS;

export type Silo = {
  id: number;
  alias: string;
  days: number;
  heads: number;
};

export interface DeviceEventCas extends DeviceEventBase {
  action: DeviceDataEventActionType;
  ithValue: number;
  siloName: string;
  temperature: number;
  humidity: number;
  axisPosition: string;
  actualWeight: number;
}

export function sortCas(data: DeviceEventCas[], mostRecentFirst?: boolean) {
  return data
    .slice()
    .sort(
      (element1, element2) =>
        (mostRecentFirst && element1.date > element2.date && -1) || (element1.date < element2.date && -1) || 1
    );
}

export type DeviceEventCasWithExtraData = DeviceEventCas & {
  fecha?: string;
  hora?: string;
  shortFechaHora?: string;
  shortHora?: string;
  shortFecha?: string;
  kgConsumidos?: number;
};

// Group Cas data by silo names and add fecha field
const groupCasDataBySilos = (data: DeviceEventCas[] = []) =>
  data.reduce((acc, item) => {
    const { siloName } = item;
    if (!acc[siloName]) {
      acc[siloName] = [];
    }
    acc[siloName].push({
      ...item,
      fecha: moment.utc(item.date).format('DD-MM-YYYY HH:mm:ss'),
      hora: moment.utc(item.date).format('HH:mm:ss'),
      shortFechaHora: moment.utc(item.date).format('DD-MM HH:mm'),
      shortFecha: moment.utc(item.date).format('DD-MM'),
      shortHora: moment.utc(item.date).format('HH:mm'),
    });
    return acc;
  }, {} as Record<string, DeviceEventCasWithExtraData[]>);

// Remove all rows with a value of actualWeight equal or bigger than the previous one
const cleanFalsePositiveCasData = (data: DeviceEventCas[] = []) => {
  const sortedData = sortCas(data);

  let previousValue = 0;
  return sortedData.filter((item) => {
    const { actualWeight } = item;

    const diferencia = actualWeight - previousValue;

    // Filtrar las filas con una diferencia positiva menor a 200kg (es un valor de ruido)
    if (previousValue > 0 && diferencia >= 0 && diferencia < 200) {
      return false;
    }
    previousValue = actualWeight;
    return true;
  });
};

export const groupCasDataBySilosAndCleanFalsePositives = (data: DeviceEventCas[] = []) => {
  const casDataRecord = groupCasDataBySilos(sortCas(data));

  return Object.keys(casDataRecord).reduce((acc, siloName) => {
    acc[siloName] = cleanFalsePositiveCasData(casDataRecord[siloName]).map((item, index, arr) => ({
      ...item,
      kgConsumidos: index === 0 ? 0 : arr[index - 1].actualWeight - item.actualWeight, // Add kgConsumidos field
    }));
    return acc;
  }, {} as Record<string, DeviceEventCasWithExtraData[]>);
};
