import { useSelector } from 'react-redux';
import { useGetUserAllQuery } from 'store/admin/adminUsersApi';
import { selectIsUserAdmin } from 'store/config/configSlice';

export const useAdminGetUsers = () => {
  const isUserAdmin = useSelector(selectIsUserAdmin);
  const { data, isFetching } = useGetUserAllQuery(undefined, { skip: !isUserAdmin });

  return { data, isFetching };
};
