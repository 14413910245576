import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { logout } from 'store/config/configSlice';
import {
  Recipe,
  RecipeIngredient,
  RECIPE_TYPE_HEADS,
} from 'utils/device/event/diet';
import { Ingredient } from 'utils/device/stock';
import type { RootState } from '../store';

type RecetasState = {
  recetaEditing: Recipe;
};

const initialState: RecetasState = {
  recetaEditing: {
    id: undefined,
    mixingTime: 0,
    type: RECIPE_TYPE_HEADS,
    name: '',
    ingredients: [],
  },
};

const slice = createSlice({
  name: 'recetas',
  initialState,
  reducers: {
    resetReceta(state) {
      state.recetaEditing = {
        id: undefined,
        mixingTime: 0,
        type: RECIPE_TYPE_HEADS,
        name: '',
        ingredients: [],
      };
    },
    editReceta(state, action: PayloadAction<any>) {
      state.recetaEditing = action.payload;
    },
    onFormChange(state, action: PayloadAction<Recipe>) {
      state.recetaEditing = action.payload;
    },
    editRecetaRow(
      state,
      action: PayloadAction<{ rowIndex: number; newValue: any }>
    ) {
      const { rowIndex, newValue } = action.payload;
      if (
        state.recetaEditing.ingredients &&
        state.recetaEditing.ingredients.length > rowIndex
      ) {
        state.recetaEditing.ingredients[rowIndex].value = newValue;
      }
    },
    toggleAlimento(state, action: PayloadAction<Ingredient>) {
      const { name, dryMatterPercentage } = action.payload;
      if (!state.recetaEditing.ingredients)
        state.recetaEditing.ingredients = [];
      let alimentoFound = false;
      state.recetaEditing.ingredients = state.recetaEditing.ingredients.reduce(
        (acc, ingredient) => {
          if (ingredient.name === name) {
            alimentoFound = true;
            if (ingredient.nuevo) return acc;
            else
              return [...acc, { ...ingredient, deleted: !ingredient.deleted }];
          } else return [...acc, ingredient];
        },
        [] as RecipeIngredient[]
      );
      if (!alimentoFound)
        state.recetaEditing.ingredients.push({
          name,
          dryMatterPercentage,
          value: 0,
          nuevo: true,
        });
    },
    onMoveRowUp(state, action: PayloadAction<{ rowIndex: number }>) {
      const { rowIndex } = action.payload;
      if (
        state.recetaEditing.ingredients &&
        state.recetaEditing.ingredients.length > rowIndex &&
        rowIndex > 0
      ) {
        state.recetaEditing.ingredients.splice(
          rowIndex - 1,
          0,
          state.recetaEditing.ingredients.splice(rowIndex, 1)[0]
        );
      }
    },
    onMoveRowDown(state, action: PayloadAction<{ rowIndex: number }>) {
      const { rowIndex } = action.payload;
      if (
        state.recetaEditing.ingredients &&
        state.recetaEditing.ingredients.length > rowIndex + 1
      ) {
        state.recetaEditing.ingredients.splice(
          rowIndex + 1,
          0,
          state.recetaEditing.ingredients.splice(rowIndex, 1)[0]
        );
      }
    },
  },
  extraReducers: (builder) => {
    // Reset state on logout
    builder.addCase(logout, () => {
      return initialState;
    });
  },
});

export default slice.reducer;

export const selectRecetaEditing = (state: RootState) =>
  state.recetas.recetaEditing;

export const selectRecetaEditingIngredients = createSelector(
  selectRecetaEditing,
  (receta) => receta.ingredients
);

export const recetasActions = slice.actions;

export const {
  resetReceta,
  editReceta,
  onFormChange,
  editRecetaRow,
  toggleAlimento,
  onMoveRowUp,
  onMoveRowDown,
} = slice.actions;
