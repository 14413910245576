import GoogleMapReact from 'google-map-react';
import { useEffect, useState } from 'react';
import { ParsedDeviceDataEventMixing } from '../parser';
import Map, { MapMarker, DEFAULT_ZOOM, DEFAULT_CENTER } from 'components/map';

function MapMezlcado({
  markers = [],
  isFocused = false,
}: {
  markers: ParsedDeviceDataEventMixing[];
  isFocused: boolean;
}) {
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [center, setCenter] = useState(DEFAULT_CENTER);

  useEffect(() => {
    if (isFocused) setZoom(DEFAULT_ZOOM);
    else setZoom(DEFAULT_ZOOM - 2);
    const newCenter = getMarkersCenter(markers);
    if (newCenter)
      setCenter({
        ...newCenter,
      });
  }, [isFocused, markers]);

  return (
    <Map center={center} zoom={zoom}>
      {markers.map((marker, idx) => (
        <MapMarker
          key={`marker-inicio-${idx}`}
          lat={marker.coordenadasInicioMezclado.lat}
          lng={marker.coordenadasInicioMezclado.lng}
          label={marker.hora}
          className="marker-mezclado-inicio"
        />
      ))}
      {markers.map((marker, idx) => (
        <MapMarker
          key={`marker-fin-${idx}`}
          lat={marker.coordenadasFinMezclado.lat}
          lng={marker.coordenadasFinMezclado.lng}
          label={marker.hora}
          className="marker-mezclado-fin"
        />
      ))}
    </Map>
  );
}

export default MapMezlcado;

function getMarkersCenter(
  markers: ParsedDeviceDataEventMixing[]
): GoogleMapReact.Coords | undefined {
  return markers.reduce(
    (acc, curr) => ({
      lat:
        ((acc?.lat || curr.coordenadasInicioMezclado.lat) +
          curr.coordenadasInicioMezclado.lat) /
        2,
      lng:
        ((acc?.lng || curr.coordenadasInicioMezclado.lng) +
          curr.coordenadasInicioMezclado.lng) /
        2,
    }),
    markers.length
      ? {
          lat: markers[0].coordenadasInicioMezclado.lat,
          lng: markers[0].coordenadasInicioMezclado.lng,
        }
      : undefined
  );
}
