import TableView from 'design-system/table-view';
import ViewHeader from 'design-system/view-header';
import { useColumns } from 'hooks/useColumns';
import { useModal } from 'hooks/useModal';
import { useSelector } from 'react-redux';
import { selectDeviceSelected } from 'store/devices/devicesSlice';
import { isDeviceVersionAtLeast, VERSION } from 'utils/helpers/validation';
import ModalEditarLote from './modal-editar-lote';

function Lotes() {
  const deviceSelected = useSelector(selectDeviceSelected);
  const { columns, onColumnsViewChange } = useColumns(
    isDeviceVersionAtLeast(VERSION.SIX, deviceSelected)
      ? 'columnsLotesV6'
      : isDeviceVersionAtLeast(VERSION.TWO, deviceSelected)
      ? 'columnsLotesV2'
      : 'columnsLotes'
  );
  const [isOpenEditarLote, onOpenEditarLote, onCloseEditarLote] = useModal();

  return (
    <>
      {isOpenEditarLote && <ModalEditarLote onClose={onCloseEditarLote} />}
      <ViewHeader
        title="Lotes"
        action={{
          label: 'Editar Lote',
          onClick: () => onOpenEditarLote(null),
          disabled: !!!deviceSelected?.lots?.length,
          isProtected: true,
        }}
      />
      <TableView
        title="Lotes"
        data={deviceSelected?.lots || []}
        columns={columns}
        onColumnViewChange={onColumnsViewChange}
        options={{
          print: false,
          download: false,
          filter: false,
          viewColumns: false,
          pagination: false,
        }}
      />
    </>
  );
}

export default Lotes;
