import { ReactNode } from 'react';
import clsx from 'clsx'

export default function Card({ title, subtitle, icon, className, children, }: CardProps) {
  return (
    <div className={clsx("drop-shadow-[0_0_1px_rgba(255,255,255,0.25)] bg-background-black tablet:rounded-lg text-neutral-200", className)}>
      {(title || subtitle) && (
        <div className="px-4 py-5 tablet:px-6">
          <h3 className="text-lg font-medium leading-6">{title}</h3>
          <p className="mt-1 max-w-2xl text-sm text-neutral-300">{subtitle}</p>
          {icon && <div className="absolute top-5 right-6">{icon}</div>}
        </div>
      )}
      <div>{children}</div>
    </div>
  );
}

interface CardProps {
  title?: string;
  subtitle?: string;
  icon?: ReactNode;
  className?: string;
  children: ReactNode;
}
