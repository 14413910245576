import Input from 'components/input';
import FormCard, { FormCardIcon } from 'design-system/form-card';
import { IconComment } from 'design-system/icon';
import { useConfig } from 'hooks/useConfig';

function ConfigEmail() {
  const {
    loggedUser: { email, secondaryEmail },
    validInputs,
    onEmailChange,
    onSecondaryEmailChange,
    isDirty,
    onSaveChanges,
    revertChanges,
  } = useConfig();

  const canSaveOrCancel =
    isDirty && validInputs.email && validInputs.secondaryEmail;

  return (
    <FormCard
      title="Notificaciones"
      isCancelDisabled={!canSaveOrCancel}
      isSaveDisabled={!canSaveOrCancel}
      onCancel={revertChanges}
      onSave={onSaveChanges}
      icon={
        <FormCardIcon className='px-2 py-2'>
          <IconComment />
        </FormCardIcon>
      }
      items={[
        {
          label: 'E-mail',
          value: (
            <Input
              placeholder="principal@gmail.com"
              value={email || ''}
              onChange={onEmailChange}
              type={'email'}
            />
          ),
        },
        {
          label: 'E-mail secundario',
          value: (
            <Input
              placeholder="secundario@gmail.com"
              value={secondaryEmail || ''}
              onChange={onSecondaryEmailChange}
              type={'email'}
            />
          ),
        },
      ]}
    />
  );
}

export default ConfigEmail;
