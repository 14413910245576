import { TextHeading, TextSubtitle } from 'design-system/text';
import { useToggle } from 'hooks/useToggle';
import { ReactNode } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import styled from 'styled-components';
import tw from 'twin.macro';

export const TableRowTwoCols = styled.div`
  ${tw`flex flex-col items-end mobile:grid mobile:grid-cols-2 gap-4`}
`;

export default function TableRow({
  title,
  titleChips,
  subtitles,
  infoLabels,
  extraContent,
  action,
  clickable,
}: TableRowProps) {
  const [isInfoOpen, toggleInfoOpen] = useToggle(false);

  return (
    <div
      className={`flex flex-col px-4 py-5 tablet:px-6 ${clickable ? 'cursor-pointer hover:bg-neutral-800' : ''}`}
      onClick={(event) => {
        if (infoLabels?.length) {
          event.stopPropagation();
          toggleInfoOpen();
        }
      }}
    >
      <div className="flex justify-between items-center gap-2">
        {infoLabels?.length &&
          (isInfoOpen ? <FiChevronUp className="text-2xl" /> : <FiChevronDown className="text-2xl" />)}
        <div className="flex flex-col grow">
          <div className="flex gap-4">
            <TextHeading>{title}</TextHeading>
          </div>
          <div className="flex gap-2">
            {subtitles?.map((subtitle, index) => (
              <>
                {index > 0 && ' . '}
                <TextSubtitle key={index}>{subtitle}</TextSubtitle>
              </>
            ))}
          </div>
        </div>
        {action && <div>{action}</div>}
      </div>
      <div className="flex gap-2">{titleChips?.map((titleChip) => titleChip)}</div>
      {isInfoOpen && (
        <div className="flex flex-col gap-1 pl-8 ">
          {infoLabels?.map((subtitle, index) => (
            <TextSubtitle key={index}>{subtitle}</TextSubtitle>
          ))}
          {extraContent}
        </div>
      )}
    </div>
  );
}

interface TableRowProps {
  title: string;
  titleChips?: ReactNode[];
  subtitles?: string[];
  infoLabels?: string[];
  action?: ReactNode;
  clickable?: boolean;
  extraContent?: any;
}
