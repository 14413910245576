import { HAASTEN_LIGHT, SECONDARY_ACCENT } from 'components/colors';
import { DatePickerComponent } from 'design-system/date-picker';
import { FormCardIcon } from 'design-system/form-card';
import { IconBarChart } from 'design-system/icon';
import { FormItem } from 'design-system/input';
import { TextHeading } from 'design-system/text';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { DeviceEventCas, groupCasDataBySilosAndCleanFalsePositives, sortCas } from 'utils/device/event/cas';
import { redondearSinDecimales, redondearUnDecimales } from 'utils/helpers/math';

const minPossibleTime = new Date();
const maxPossibleTime = new Date();
minPossibleTime.setHours(0, 0, 0, 0);
maxPossibleTime.setHours(23, 59, 59, 999);

interface CasHistoricoPromedioSelectorsITHProps {
  deviceData: DeviceEventCas[];
}

export const CasHistoricoPromedioSelectorsITH = ({ deviceData }: CasHistoricoPromedioSelectorsITHProps) => {
  // Keep track of the selected date in the time picker
  const [startTime, setStartTime] = useState<Date | null>(minPossibleTime);
  const [endTime, setEndTime] = useState<Date | null>(maxPossibleTime);

  const { watch } = useFormContext();
  const [silo] = watch(['silo']);

  const dataForThisSilo = useMemo(
    () => groupCasDataBySilosAndCleanFalsePositives(sortCas(deviceData))?.[silo] || [],
    [deviceData, silo]
  );

  const dataForThisSiloFilteredByTime = useMemo(
    () =>
      dataForThisSilo.filter((item) => {
        const itemDate = moment(moment.utc(item.date).format('HH:mm:ss'), 'HH:mm:ss');
        const startTimeDate = moment(startTime, 'HH:mm:ss');
        const endTimeDate = moment(endTime, 'HH:mm:ss');

        const pass = itemDate.isBetween(startTimeDate, endTimeDate);

        return pass;
      }),
    [dataForThisSilo, startTime, endTime]
  );

  const averageHumidity =
    dataForThisSiloFilteredByTime?.reduce((acc, point) => acc + point.humidity, 0) /
    (dataForThisSiloFilteredByTime?.length || 1);
  const averageTemperature =
    dataForThisSiloFilteredByTime?.reduce((acc, point) => acc + point.temperature, 0) /
    (dataForThisSiloFilteredByTime?.length || 1);
  const averageIth =
    dataForThisSiloFilteredByTime?.reduce((acc, point) => acc + point.ithValue, 0) /
    (dataForThisSiloFilteredByTime?.length || 1);

  return (
    <div className="content-view px-8 py-8">
      <div className="bg-background-black tablet:rounded-lg text-neutral-200 border border-white/10  grid">
        <div className="flex justify-between items-center px-4 py-5 tablet:px-6 border-b border-white/10 ">
          <TextHeading>ITH promedio por rango horario</TextHeading>
          <FormCardIcon className="px-2 py-2">
            <IconBarChart />
          </FormCardIcon>
        </div>

        <div className="px-4 py-5 tablet:px-6 bg-neutral-900">
          <div className="flex flex-col gap-4">
            <div className="grid gap-4 items-end">
              <div className="grid sm:grid-cols-2 gap-4 ">
                <div className="min-w-[80px] ">
                  <FormItem label="Desde">
                    <DatePickerComponent
                      selected={startTime}
                      onChange={(date: any) => setStartTime(date)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={60}
                      timeCaption="Desde"
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      minTime={minPossibleTime}
                      maxTime={endTime || maxPossibleTime}
                    />
                  </FormItem>
                </div>
                <div className="min-w-[80px]">
                  <FormItem label="Hasta">
                    <DatePickerComponent
                      selected={endTime}
                      onChange={(date: any) => setEndTime(date)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={60}
                      timeCaption="Hasta"
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      minTime={startTime || minPossibleTime}
                      maxTime={maxPossibleTime}
                      injectTimes={[maxPossibleTime]}
                    />
                  </FormItem>
                </div>
              </div>

              <div className="grid sm:grid-cols-3 gap-4">
                <div
                  className="p-2 rounded-xl border flex justify-center items-center text-center "
                  style={{ borderColor: SECONDARY_ACCENT }}
                >
                  Humedad: {redondearUnDecimales(averageHumidity)}%
                </div>
                <div
                  className="p-2 rounded-xl border border-blue-500/70 flex justify-center items-center text-center"
                  style={{ borderColor: HAASTEN_LIGHT }}
                >
                  Temperatura: {redondearUnDecimales(averageTemperature)}°C
                </div>
                <div className="p-2 rounded-xl border border-purple-500/70 flex justify-center items-center text-center ">
                  ITH: {redondearSinDecimales(averageIth)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
