import { DeviceEventLoad } from 'utils/device/event/sip';
import { Ingredient } from 'utils/device/stock';
import { redondearDosDecimalesToString, redondearSinDecimalesToString } from 'utils/helpers/math';

type StockHistoricoRow = {
  name: string;
  toLoad: number;
  loaded: number;
  totalPriceToLoad: string;
  totalPriceLoaded: string;
  diferenciaKg: string;
  diferenciaPrice: string;
  porcentajeError: string;
  price: string;
};

export default function parseStockHistorico(
  dateRangeSelected: number,
  loadData: DeviceEventLoad[],
  stockIngredients?: Ingredient[],
  filterNotLoaded?: boolean
): StockHistoricoRow[] {
  const loadDataSummed = loadData.reduce((acc, curr) => {
    const { toLoad, loaded, ingredient, price } = curr;

    const porcentajeErrorCarga = (loaded * 100) / toLoad - 100;
    const shouldAddRow = !filterNotLoaded || porcentajeErrorCarga > -95;

    return shouldAddRow
      ? {
          ...acc,
          [ingredient]: {
            ingredient,
            toLoad: (acc[ingredient]?.toLoad || 0) + toLoad,
            loaded: (acc[ingredient]?.loaded || 0) + loaded,
            price: ((acc[ingredient]?.price || 0) + price) / 2,
          },
        }
      : acc;
  }, {} as Record<string, { ingredient: string; toLoad: number; loaded: number; price: number }>);

  return Object.values(loadDataSummed)
    .map((summedEvent) => {
      const { toLoad, loaded, ingredient, price } = summedEvent;

      const { stock = 0 } = stockIngredients?.find((i) => i.name === ingredient) || {};

      const totalPriceToLoad = price * toLoad;
      const totalPriceLoaded = price * loaded;
      const diferenciaKg = loaded - toLoad;
      const diferenciaPrice = totalPriceLoaded - totalPriceToLoad;
      const porcentajeError = (loaded * 100) / toLoad - 100;

      return {
        name: ingredient,
        toLoad,
        loaded,
        price: redondearDosDecimalesToString(price),
        totalPriceToLoad: redondearDosDecimalesToString(totalPriceToLoad),
        totalPriceLoaded: redondearDosDecimalesToString(totalPriceLoaded),
        diferenciaKg: redondearDosDecimalesToString(diferenciaKg),
        diferenciaPrice: redondearDosDecimalesToString(diferenciaPrice),
        porcentajeError: redondearDosDecimalesToString(porcentajeError),
        stock,
        diasRestantesAlimento: redondearSinDecimalesToString(stock / (toLoad / dateRangeSelected)),
        diasRestantesAlimentoPromedio: redondearSinDecimalesToString(stock / (loaded / dateRangeSelected)),
      };
    })
    .sort((a, b) => a.name.localeCompare(b.name));
}
