import GoogleMapReact from 'google-map-react';
import MapMarker from './marker';

const DEFAULT_CENTER = { lat: -31.673586, lng: -63.103127 };
const DEFAULT_ZOOM = 15;

function Map({
  defaultZoom = DEFAULT_ZOOM,
  defaultCenter = DEFAULT_CENTER,
  center = DEFAULT_CENTER,
  zoom = DEFAULT_ZOOM,
  children,
  GoogleMapReactProps,
}: {
  defaultZoom?: number;
  center?: GoogleMapReact.Coords;
  defaultCenter?: GoogleMapReact.Coords;
  zoom?: number;
  children?: React.ReactNode;
  GoogleMapReactProps?: GoogleMapReact.Props;
}) {
  const createMapOptions = (_maps: any) => ({
    mapTypeControl: true,
  });

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyCMFpi3reTxyZtrQXSu6atHlale2gyDEjM' }}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        center={center}
        zoom={zoom}
        options={createMapOptions}
        {...GoogleMapReactProps}
      >
        {children}
      </GoogleMapReact>
    </div>
  );
}

export default Map;

export { MapMarker, DEFAULT_CENTER, DEFAULT_ZOOM };
