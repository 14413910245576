import Schedule from '@material-ui/icons/Schedule';
import { DatePickerComponent } from 'design-system/date-picker';
import FormCard, { FormCardIcon } from 'design-system/form-card';
import { IconConfig } from 'design-system/icon';
import { FormItem, Input } from 'design-system/input';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateITHTimerMutation } from 'store/devices/devicesApi';
import { selectDeviceSelected } from 'store/devices/devicesSlice';

const minPossibleTime = new Date();
const maxPossibleTime = new Date();
minPossibleTime.setHours(0, 0, 0, 0);
maxPossibleTime.setHours(23, 59, 59, 999);

function ITHConfigHorarios() {
  const deviceSelected = useSelector(selectDeviceSelected);
  const { serialNumber = '' } = deviceSelected || {};

  return (
    <div className="flex flex-col">
      {[1, 2].map((sectorId) => (
        <div
          className="content-view px-8 py-8 flex flex-col gap-3"
          style={{
            zIndex: 10 - sectorId,
          }}
        >
          <FormCard
            key={sectorId}
            title={`Configuración ITH - Ventiladores Sector ${sectorId}`}
            subtitle={`${serialNumber}`}
            icon={
              <FormCardIcon className="px-2 py-2">
                <IconConfig />
              </FormCardIcon>
            }
            items={[
              {
                value: (
                  <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-2 sm:gap-6 md:gap-8">
                    {[1, 2, 3, 4, 5, 6].map((timerId) => (
                      <ITHConfigClock timerId={timerId} sectorId={sectorId} />
                    ))}
                  </div>
                ),
              },
              {
                label: 'Rango de trabajo ITH',
                value: <ITHConfigClockRango sectorId={sectorId} />,
              },
            ]}
          />
        </div>
      ))}
    </div>
  );
}

export default ITHConfigHorarios;

const NumberedClockIcon = ({ number }: { number: number }) => (
  <div className="relative w-fit p-2 text-5xl bg-neutral-900 rounded-xl">
    <div className="absolute bottom-1 left-1 bg-neutral-900 text-white !text-lg rounded-full w-6 h-6 flex items-center justify-center">
      {number}
    </div>
    <Schedule fontSize="inherit" />
  </div>
);

const ITHConfigClock = ({ timerId, sectorId }: { timerId: number; sectorId: number }) => {
  const deviceSelected = useSelector(selectDeviceSelected);
  const { serialNumber = '', fanTimers = [] } = deviceSelected || {};
  const [updateITHTimer] = useUpdateITHTimerMutation();

  const { start, stop } =
    fanTimers.find((timer) => timer.id === String(timerId) && timer.sector === String(sectorId)) || {};

  const [startTime, setStartTime] = useState<Date | null>(minPossibleTime);
  const [endTime, setEndTime] = useState<Date | null>(maxPossibleTime);

  useEffect(() => {
    if (!start || !stop) return;

    const formattedStart = moment(start, 'HH:mm').toDate();
    const formattedStop = moment(stop, 'HH:mm').toDate();

    setStartTime(formattedStart);
    setEndTime(formattedStop);
  }, [start, stop]);

  return (
    <div className="flex items-center gap-1 w-full">
      <NumberedClockIcon number={timerId} />

      <div className="w-full grid sm:grid-cols-2 gap-4 p-3 bg-neutral-500/20 rounded-xl">
        <div className="min-w-[80px] ">
          <FormItem label="ON">
            <DatePickerComponent
              selected={startTime}
              onChange={(date: Date) => {
                setStartTime(date);
              }}
              onCalendarClose={() => {
                const savedFormattedStart = moment(start, 'HH:mm').toDate();
                if (startTime !== savedFormattedStart) {
                  updateITHTimer({
                    id: String(timerId),
                    sector: String(sectorId),
                    start: moment(startTime).format('HH:mm'),
                    stop: moment(endTime).format('HH:mm'),
                    serialNumber,
                  });
                }
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={60}
              timeCaption="ON"
              dateFormat="HH:mm"
              timeFormat="HH:mm"
              minTime={minPossibleTime}
              maxTime={endTime || maxPossibleTime}
            />
          </FormItem>
        </div>
        <div className="min-w-[80px]">
          <FormItem label="OFF">
            <DatePickerComponent
              selected={endTime}
              onChange={(date: Date) => {
                setEndTime(date);
              }}
              onCalendarClose={() => {
                const savedFormattedStop = moment(stop, 'HH:mm').toDate();
                if (endTime !== savedFormattedStop) {
                  updateITHTimer({
                    id: String(timerId),
                    sector: String(sectorId),
                    start: moment(startTime).format('HH:mm'),
                    stop: moment(endTime).format('HH:mm'),
                    serialNumber,
                  });
                }
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={60}
              timeCaption="OFF"
              dateFormat="HH:mm"
              timeFormat="HH:mm"
              minTime={startTime || minPossibleTime}
              maxTime={maxPossibleTime}
              injectTimes={[maxPossibleTime]}
            />
          </FormItem>
        </div>
      </div>
    </div>
  );
};

const ITHConfigClockRango = ({ sectorId }: { sectorId: number }) => {
  const deviceSelected = useSelector(selectDeviceSelected);
  const { serialNumber = '', fanTimers = [] } = deviceSelected || {};
  const [updateITHTimer] = useUpdateITHTimerMutation();

  const { start, stop } = fanTimers.find((timer) => timer.id === '7' && timer.sector === String(sectorId)) || {};

  const [valueOn, setValueOn] = useState<number>(0);
  const [valueOff, setValueOff] = useState<number>(0);

  useEffect(() => {
    if (start) setValueOn(Number(start));
    if (stop) setValueOff(Number(stop));
  }, [start, stop]);

  return (
    <div className="w-full grid grid-cols-2 gap-2 sm:gap-6 md:gap-8">
      <div className="min-w-[80px]">
        <FormItem label="ITH ON">
          <Input
            key={valueOn}
            placeholder="ITH ON"
            defaultValue={valueOn ?? 0}
            onBlur={(event) => {
              const value = event.target.value;
              if (Number(value) !== valueOn) {
                updateITHTimer({
                  id: '7',
                  sector: String(sectorId),
                  start: String(value),
                  stop: String(valueOff),
                  serialNumber,
                });
              }
            }}
            type={'number'}
            min={0}
          />
        </FormItem>
      </div>
      <div className="min-w-[80px]">
        <FormItem label="ITH OFF">
          <Input
            key={valueOff}
            placeholder="ITH OFF"
            defaultValue={valueOff ?? 0}
            onBlur={(event) => {
              const value = event.target.value;
              if (Number(value) !== valueOff) {
                updateITHTimer({
                  id: '7',
                  sector: String(sectorId),
                  start: String(valueOn),
                  stop: String(value),
                  serialNumber,
                });
              }
            }}
            type={'number'}
            min={0}
          />
        </FormItem>
      </div>
    </div>
  );
};
