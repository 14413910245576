import { Chip } from '@material-ui/core';
import Button from 'components/button';
import Input from 'components/input';
import LiteTable from 'components/lite-table';
import { FullScreenLoader } from 'components/loader';
import ThreeDotsMenu from 'components/three-dots-menu';
import { useAdminGetUsers } from 'hooks/useAdminGetUsers';
import { useColumns } from 'hooks/useColumns';
import { useModal } from 'hooks/useModal';
import { useCallback, useMemo, useState } from 'react';
import { useDeleteUserMutation } from 'store/admin/adminUsersApi';
import { LoggedUser } from 'store/auth/authApi';
import ModalAsociarEquipoToUser from './ModalAsociarEquipoToUser';
import ModalCreateUser from './ModalCreateUser';

function AdminUsers() {
  const { columns } = useColumns('columnsAdminUsers');
  const [isOpenCreateUser, onOpenCreateUser, onCloseCreateUser] = useModal();
  const [isOpenAsociarEquipoToUser, onOpenAsociarEquipoToUser, onCloseAsociarEquipoToUser, usuarioSeleccionado] =
    useModal<LoggedUser>();
  const [searchInput, setSearchInput] = useState('');

  const { data: users = [], isFetching: isGetUserAllFetching } = useAdminGetUsers();

  const [onDeleteUser, { isLoading: isDeletingUser }] = useDeleteUserMutation();

  const onClickEliminarUser = useCallback(
    (user: LoggedUser) => {
      // eslint-disable-next-line no-restricted-globals
      const confirmEliminar = confirm(
        `¿Está seguro que desea eliminar el usuario ${user.username}? Esta operación no se puede revertir.`
      );
      if (confirmEliminar) onDeleteUser(user.username);
    },
    [onDeleteUser]
  );

  const rowsWithEliminar = useMemo(
    () =>
      users
        .filter(
          (user) =>
            !searchInput ||
            user.username.toLowerCase().includes(searchInput.trim().toLowerCase()) ||
            user.devices?.map((d) => d.serialNumber).some((d) => d.includes(searchInput.trim()))
        )
        .sort((a, b) => (a.username.toLocaleLowerCase() < b.username.toLocaleLowerCase() ? -1 : 1))
        .map((user) => ({
          ...user,
          serialNumbers: user.devices?.map((device) => (
            <Chip label={device.serialNumber} variant="outlined" color="primary" />
          )),
          accion: (
            <div style={{ textAlign: 'right' }}>
              <ThreeDotsMenu
                menuItems={[
                  {
                    onClick: () => {
                      onOpenAsociarEquipoToUser(user);
                    },
                    label: 'Asociar equipo',
                  },
                  {
                    onClick: () => {
                      onClickEliminarUser(user);
                    },
                    label: 'Eliminar usuario',
                  },
                ]}
              />
            </div>
          ),
        })),
    [onClickEliminarUser, onOpenAsociarEquipoToUser, searchInput, users]
  );

  return (
    <>
      {(isGetUserAllFetching || isDeletingUser) && <FullScreenLoader />}
      {isOpenCreateUser && <ModalCreateUser isOpen={isOpenCreateUser} onClose={onCloseCreateUser} />}
      {isOpenAsociarEquipoToUser && (
        <ModalAsociarEquipoToUser
          isOpen={isOpenAsociarEquipoToUser}
          onClose={onCloseAsociarEquipoToUser}
          user={usuarioSeleccionado}
        />
      )}
      <div className="content-view px-8 py-8 space-y-4">
        <div className="form-row" style={{ alignItems: 'end' }}>
          <Input
            placeholder="Nombre de usuario, ID equipo"
            label="Buscar usuario"
            value={searchInput}
            onChange={setSearchInput}
            type={'text'}
          />
          <Button onClick={onOpenCreateUser} variant="fill">
            Crear usuario
          </Button>
        </div>
        <LiteTable title="" data={rowsWithEliminar} columns={columns} />
      </div>
    </>
  );
}

export default AdminUsers;
