export const COLORS = [
  '#00b8d9',
  '#00875a',
  '#6a1b9a',
  '#d50000',
  '#ffab00',
  '#ff5630',
  '#ff8b00',
  '#4c9aff',
  '#0052cc',
  '#00b8d9',
  '#36b37e',
  '#ff5630',
  '#ff8b00',
  '#ffab00',
  '#f78da7',
  '#9900ef',
  '#4c9aff',
  '#0052cc',
  '#00b8d9',
  '#36b37e',
  '#00875a',
  '#ff5630',
  '#ff8b00',
  '#ffab00',
  '#f78da7',
  '#9900ef',
  '#4c9aff',
  '#0052cc',
  '#00b8d9',
  '#36b37e',
  '#00875a',
  '#ff5630',
  '#ff8b00',
  '#ffab00',
  '#f78da7',
  '#9900ef',
  '#4c9aff',
  '#0052cc',
  '#00b8d9',
  '#36b37e',
  '#00875a',
  '#ff5630',
  '#ff8b00',
  '#ffab00',
  '#f78da7',
  '#9900ef',
  '#4c9aff',
  '#0052cc',
];
