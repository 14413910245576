import TableSubsum, {
  addFilterValuesToColumns,
  Filters,
} from 'components/table/TableSubsum';
import Card from 'design-system/card';
import { useColumns } from 'hooks/useColumns';
import { useMemo } from 'react';
import { DeviceEventBase } from 'utils/device/event';

export default function TableSubsumView({
  title = '',
  data = [],
  columnsName = '',
  parseFunction,
  tableProps,
  extraParseFunctionArgs = [],
  onToggleRow,
}: TableViewProps) {
  const { columns, onColumnsViewChange, onFilterChange, filtersApplied } =
    useColumns(columnsName);

  const [parsedData, filterValues = {}] = useMemo(
    () =>
      parseFunction?.(
        data,
        filtersApplied as unknown as Filters,
        ...extraParseFunctionArgs
      ), // TODO: Typescript en useColumns
    [parseFunction, data, filtersApplied, extraParseFunctionArgs]
  );

  const columnsFiltered = useMemo(
    () => addFilterValuesToColumns(columns, filterValues),
    [columns, filterValues]
  );

  function handleToggleRow(rowIndex: number) {
    if (rowIndex < parsedData.length) {
      onToggleRow?.(parsedData[rowIndex]);
    }
  }

  return (
    <div className="px-8 py-8">
      <Card>
        <TableSubsum
          title={title}
          data={parsedData}
          columnas={columnsFiltered}
          onColumnsViewChange={onColumnsViewChange}
          onToggleRow={handleToggleRow}
          {...tableProps}
          tableOptions={{
            filter: true,
            onFilterChange,
            ...tableProps?.tableOptions,
          }}
        />
      </Card>
    </div>
  );
}

interface TableViewProps {
  title?: string;
  data: DeviceEventBase[];
  columnsName: string;
  parseFunction: (
    events: any[], // todo: type this correctly
    filters: Filters,
    ...extraArgs: any
  ) => [any[], any]; // todo: type this correctly
  extraParseFunctionArgs?: any;
  tableProps?: any;
  onToggleRow?: (event: any) => void;
}
