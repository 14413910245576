import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { api, LoggedUser } from 'store/auth/authApi';
import { DEVICE_TYPE_CMAS } from 'utils/device';

type ConfigState = {
  user: LoggedUser;
  token: string;
};

const initialState: ConfigState = {
  user: {
    username: localStorage.getItem('username') || '',
  },
  token: localStorage.getItem('token') || '',
};

const slice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
    setEmail(state, action: PayloadAction<string>) {
      state.user.email = action.payload;
    },
    setSecondaryEmail(state, action: PayloadAction<string>) {
      state.user.secondaryEmail = action.payload;
    },
    logout(state) {
      state.token = '';
      state.user = { username: '' };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.login.matchFulfilled, (state, action) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
    });
    builder.addMatcher(api.endpoints.getUser.matchFulfilled, (state, action) => {
      state.user = action.payload;
    });
  },
});

export default slice.reducer;

export const selectUser = (state: RootState) => state.config.user;
export const selectUserRoles = createSelector(selectUser, (user) => user.roles);
export const selectIsUserAdmin = createSelector(selectUserRoles, (roles) => roles?.includes('admin'));

export const selectToken = (state: RootState) => state.config.token;
export const selectDevices = createSelector(selectUser, (user) => user.devices);
export const selectCMASDevices = createSelector(selectDevices, (devices) =>
  devices?.filter((device) => device.type === DEVICE_TYPE_CMAS)
);

export const { setToken, setEmail, setSecondaryEmail, logout } = slice.actions;
