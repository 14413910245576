import { useLocation } from 'react-router';
import { ROUTES } from '.';
import { ROUTES_ADMIN } from './routes-admin';

// regex: /path-base/123 -> /path-base
export function isRouteValid(route: string) {
  const [pathBase] = route.match(/^\/(\w*-?)*/g) || [] || [];
  return (
    pathBase === '' ||
    pathBase === '/login' ||
    !!ROUTES.concat(ROUTES_ADMIN).find(({ links }) =>
      links.find(({ link }) => link === pathBase)
    )
  );
}

export function usePathname() {
  const { pathname } = useLocation();
  return (pathname.match(/^\/(\w+-?)+/g) || [])[0];
}
