import { FullScreenLoader } from 'components/loader';
import Select from 'design-system/select';
import ViewHeader from 'design-system/view-header';
import { useCalendarQuery } from 'hooks/useCalendarQuery';
import { useEffect, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useGetDeviceDataQuery } from 'store/devices/devicesApi';
import { DeviceEventCas } from 'utils/device/event/cas';
import { CasChartKgComidos } from './CasChart';
import { CasHistoricoTabla } from './CasHistoricoTabla';
import { CasHistoricoPromedioSelectorsITH } from './RangoHorairo/CasHistoricoPromedioSelectorsITH';
import { CasHistoricoPromedioSelectorsKgConsumidos } from './RangoHorairo/CasHistoricoPromedioSelectorsKgConsumidos';

function CasHistorico() {
  const { data: deviceData = [], isFetching: isDeviceDataFetching } = useCalendarQuery(useGetDeviceDataQuery);

  const silosNames = useMemo(
    () => [...new Set((deviceData as unknown as DeviceEventCas[]).map((item) => item.siloName))].sort(),
    [deviceData]
  );

  // Silo seleccionado
  const methods = useForm<{
    silo: string;
  }>();

  useEffect(() => {
    if (silosNames.length > 0) {
      methods.setValue('silo', silosNames[0]);
    }
  }, [methods, silosNames]);

  return (
    <FormProvider {...methods}>
      {isDeviceDataFetching && <FullScreenLoader />}
      <ViewHeader title="CAS Histórico" dateSelector="range" />

      {!deviceData.length ? (
        <div className="content-view px-8">
          <p className="text-2xl font-bold">No hay datos para mostrar</p>
        </div>
      ) : (
        <>
        <div className="w-full md:max-w-md flex px-8">
          <Controller
            name="silo"
            control={methods.control}
            render={({ field: { onChange, value } }) => (
              <Select
                name="silo"
                label="Silo"
                options={silosNames.map((name) => ({
                  name: name,
                  value: name,
                }))}
                value={value}
                onChangeValue={onChange}
                className="tablet:col-span-1"
              />
            )}
          />
        </div>
          <CasChartKgComidos deviceData={deviceData as unknown as DeviceEventCas[]} />
          <CasHistoricoPromedioSelectorsKgConsumidos deviceData={deviceData as unknown as DeviceEventCas[]} />
          <CasHistoricoPromedioSelectorsITH deviceData={deviceData as unknown as DeviceEventCas[]} />
          <CasHistoricoTabla deviceData={deviceData as unknown as DeviceEventCas[]} />
        </>
      )}
    </FormProvider>
  );
}

export default CasHistorico;
