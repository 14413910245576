import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLoginMutation, useGetUserQuery } from 'store/auth/authApi';
import {
  selectToken,
  selectUser,
  logout as logoutFromConfig,
} from 'store/config/configSlice';

export const useAuth = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();

  const token = useSelector(selectToken);
  const user = useSelector(selectUser);

  useEffect(() => {
    localStorage.setItem('token', token);
    localStorage.setItem('username', user?.username);
  }, [token, user]);

  const {
    data: loggedUser = { username: '' },
    isLoading: isLoadingLoggedUser,
    error,
    isError,
  } = useGetUserQuery(user?.username);

  const [
    login,
    { isError: isLoginRejected, isSuccess: isLoginSuccessful },
  ] = useLoginMutation();

  useEffect(() => {
    if (isLoginSuccessful) push('/');
  }, [isLoginSuccessful, push]);

  const logout = () => {
    dispatch(logoutFromConfig());
    push('/');
  };

  // Check if the user is the one passed as parameter
  const is = (username: string) => loggedUser.username === username;

  return {
    loggedUser: useMemo(
      () => ({
        token,
        user: loggedUser,
        isLoading: isLoadingLoggedUser,
        error,
        isError,
        isUserAdmin: loggedUser?.roles?.includes('admin'),
      }),
      [token, loggedUser, isLoadingLoggedUser, isError, error]
    ),
    tryLogin: { login, isLoginRejected, isLoginSuccessful },
    logout,
    is,
  };
};
