import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import tw from 'twin.macro';

export const FocusableItem = css`
  ${tw`inline-flex w-full min-h-[38px] justify-center rounded-md bg-transparent px-4 py-2 text-neutral-200 sm:text-sm font-medium focus:outline-none border border-gray-500 shadow-sm focus-visible:border-haasten-800 focus:border-haasten-800 focus:ring-haasten-800 invalid:border-danger-800 disabled:opacity-50 disabled:cursor-default`}
`;

export const FormItem = ({
  label,
  children,
}: {
  label?: string;
  children?: ReactNode;
}) => {
  return (
    <div className="relative w-full text-left flex flex-col">
      {label && <label>{label}</label>}
      {children}
    </div>
  );
};

export const Input = styled.input`
  ${FocusableItem}
`;

export const InputWithStartAdornment = styled(Input)`
  ${tw`pl-[40px]`}
`;
