import { useMemo } from 'react';
import { customBodyRenderDateOnly } from 'utils/helpers/body-renders';
import { useThreeDayPesadas } from '../Acciones/useThreeDayPesadas';

// Retorna las recetas para tres dias, agrupadas por lote y por fecha
// Retorna un array de objetos con la siguiente forma:
// [
//   {
//     date: '2021-08-01',
//     data: [
//       {
//         recipe: 'recipe 1',
//         data: [{...}, {...}, {...}]],
//       },
//       ...
//     ]
//   },
//   ...
// ]
export const useDispersionRecetas = () => {
  const pesadasSubtotales = useThreeDayPesadas();

  const points: any = useMemo(
    () =>
      pesadasSubtotales
        .sort((a: any, b: any) => Number(a.date) - Number(b.date))
        // Group by date ( d.date())
        .reduce((acc: any, item: any) => {
          const date = customBodyRenderDateOnly(item.date);
          const index = acc.findIndex((i: any) => i.date === date);
          if (index === -1) {
            acc.push({ date, data: [item] });
          } else {
            acc[index].data.push(item);
          }
          return acc;
        }, [])
        // For each date, group by recipe
        .map((d: any) => {
          const data = d.data.reduce((acc: any, item: any) => {
            const index = acc.findIndex((i: any) => i.recipe === item.recipe);
            if (index === -1) {
              acc.push({ recipe: item.recipe, data: [item] });
            } else {
              acc[index].data.push(item);
            }
            return acc;
          }, []);
          return { date: d.date, data };
        }),
    [pesadasSubtotales]
  );

  return points || [];
};
