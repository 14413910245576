import Input from 'components/input';
import { FullScreenLoader } from 'components/loader';
import Select from 'design-system/select';
import SidebarSlide from 'design-system/sidebar-slide';
import { useEffect, useState } from 'react';
import { useCreateDeviceMutation } from 'store/admin/adminDevicesApi';
import { Device, DEVICE_TYPE_CMAS, DEVICE_TYPE_ITH, DEVICE_TYPE_SIP } from 'utils/device';

const DEVICE_TYPES = [
  { name: DEVICE_TYPE_SIP, value: DEVICE_TYPE_SIP },
  { name: DEVICE_TYPE_ITH, value: DEVICE_TYPE_ITH },
  { name: DEVICE_TYPE_CMAS, value: DEVICE_TYPE_CMAS },
];

const DEVICE_VERS = [
  { name: '1.0', value: '1.0' },
  { name: '2.0', value: '2.0' },
];

const EMPTY_DEVICE: Device = {
  serialNumber: '',
  alias: '',
  type: DEVICE_TYPE_SIP,
  ver: '1.0',
};

function ModalCreateDevice({ onClose = () => {} }: { onClose: () => void }) {
  const [onCreateDevice, { isLoading: isCreatingDevice }] = useCreateDeviceMutation();

  const [newDevice, setNewDevice] = useState<Device>(EMPTY_DEVICE);
  const canGuardar = newDevice.serialNumber;

  useEffect(() => setNewDevice(EMPTY_DEVICE), []);

  const onSave = () => {
    onCreateDevice(newDevice);
    onClose();
  };

  const onDeviceDataChange = (key: keyof Device, value: any) => {
    setNewDevice((device) => ({ ...device, [key]: value }));
  };

  return (
    <SidebarSlide
      isOpen
      onClose={onClose}
      title="Nuevo equipo"
      actions={[
        {
          autoFocus: false,
          onClick: onClose,
          text: 'Cancelar',
          variant: 'text-danger',
        },
        {
          autoFocus: false,
          onClick: onSave,
          text: 'Guardar',
          variant: 'fill',
          disabled: !canGuardar,
        },
      ]}
    >
      {isCreatingDevice && <FullScreenLoader />}
      <div className="px-8 py-8">
        <div className="form">
          <Input
            placeholder="N° Serial"
            label="N° Serial"
            value={newDevice.serialNumber || ''}
            onChange={(value) => onDeviceDataChange('serialNumber', value)}
            required
          />
          <Input
            placeholder="Alias"
            label="Alias"
            value={newDevice.alias || ''}
            onChange={(value) => onDeviceDataChange('alias', value)}
          />
          <Select
            name="type"
            label="Tipo"
            options={DEVICE_TYPES}
            onChangeValue={(value: string) => {
              onDeviceDataChange('type', value);
            }}
            value={newDevice.type}
          />
          <Select
            name="ver"
            label="Versión"
            options={DEVICE_VERS}
            onChangeValue={(value: string) => {
              onDeviceDataChange('ver', value);
            }}
            value={newDevice.ver}
          />
        </div>
      </div>
    </SidebarSlide>
  );
}
export default ModalCreateDevice;
