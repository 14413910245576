import { capitalizeFirstLetter } from 'utils/helpers/body-renders';

export const columnsGacUsers = [
  {
    name: 'name',
    label: 'Usuario',
    options: { filter: true, sort: true },
  },
  {
    name: 'maxLiters',
    label: 'Lt Permitidos',
    options: { filter: false, sort: true },
  },
  {
    name: 'availableLiters',
    label: 'Lt Disponibles',
    options: { filter: false, sort: true },
  },
  {
    name: 'mode',
    label: 'Modo Despacho',
    options: { filter: false, sort: true, customBodyRender: capitalizeFirstLetter },
  },
  {
    name: 'manualAccess',
    label: 'Ingreso',
    options: {
      filter: false,
      sort: true,
      customBodyRender: (val) => (val === 'SI' ? 'Manual' : 'Tarjeta/Llavero'),
    },
  },
];
