import CheckIcon from '@material-ui/icons/Check';
import { customBodyRenderPercentage } from 'utils/helpers/body-renders';

export const columnsStock = [
  {
    name: 'id',
    label: 'Balanza',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value) =>
        value ? <CheckIcon style={{ color: '#4caf50' }} size="small" /> : null,
    },
  },
  {
    name: 'name',
    label: 'Ingrediente',
    options: { filter: false, sort: true },
  },
  {
    name: 'stock',
    label: 'Existencias (kg)',
    options: { filter: false, sort: true },
  },
  {
    name: 'price',
    label: 'Precio Kg',
    options: { filter: false, sort: true },
  },
  {
    name: 'dryMatterPercentage',
    label: '%MS',
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value) => value * 100,
    },
  },
];
