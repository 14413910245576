import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validateEmail } from 'utils/helpers/validation';
import {
  selectUser,
  setEmail,
  setSecondaryEmail,
} from '../store/config/configSlice';
import {
  useSetEmailToUserMutation,
  useSetSecondaryEmailToUserMutation,
} from 'store/config/configApi';
import { useAuth } from './useAuth';

export const useConfig = () => {
  const {
    loggedUser: { user: backendLoggedUser },
  } = useAuth();
  const dispatch = useDispatch();
  const loggedUser = useSelector(selectUser);

  const onEmailChange = useCallback((email) => dispatch(setEmail(email)), [
    dispatch,
  ]);

  const onSecondaryEmailChange = useCallback(
    (email) => dispatch(setSecondaryEmail(email)),
    [dispatch]
  );

  const [
    setEmailToUser,
    { isLoading: isSetEmailToUserLoading },
  ] = useSetEmailToUserMutation();
  const [
    setSecondaryEmailToUser,
    { isLoading: isSetSecondaryEmailToUserLoading },
  ] = useSetSecondaryEmailToUserMutation();

  const isDirty = useMemo(
    () =>
      backendLoggedUser.email !== loggedUser.email ||
      backendLoggedUser.secondaryEmail !== loggedUser.secondaryEmail,
    [
      backendLoggedUser.email,
      backendLoggedUser.secondaryEmail,
      loggedUser.email,
      loggedUser.secondaryEmail,
    ]
  );

  const validInputs = useMemo(
    () => ({
      email: validateEmail(loggedUser.email),
      secondaryEmail:
        loggedUser.secondaryEmail === '' ||
        validateEmail(loggedUser.secondaryEmail),
    }),
    [loggedUser.email, loggedUser.secondaryEmail]
  );

  const revertChanges = () => {
    onEmailChange(backendLoggedUser.email);
    onSecondaryEmailChange(backendLoggedUser.secondaryEmail);
  };

  const onSaveChanges = () => {
    setEmailToUser({ email: loggedUser.email });
    setSecondaryEmailToUser({ email: loggedUser.secondaryEmail });
  };

  return {
    loggedUser,
    validInputs,
    onEmailChange,
    onSecondaryEmailChange,
    isDirty,
    onSaveChanges,
    revertChanges,
    isFetching: isSetEmailToUserLoading || isSetSecondaryEmailToUserLoading,
  };
};
