import FormCard from 'components/form-card';
import { FullScreenLoader } from 'components/loader';
import { ChipQuestionMark } from 'design-system/chip/chip-question-mark';
import ViewHeader from 'design-system/view-header';
import { useLastMonthQuery } from 'hooks/useCalendarQuery';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useGetDeviceCMASDataQuery } from 'store/devices/devicesApi';
import { selectDeviceSelectedType } from 'store/devices/devicesSlice';
import { DEVICE_TYPE_CMAS } from 'utils/device';
import { customBodyRenderDateOnly, customBodyRenderTime } from 'utils/helpers/body-renders';
import parseFunction from './parser';

function Cmas() {
  const deviceSelectedType = useSelector(selectDeviceSelectedType);
  const { data: deviceCMASData = [], isFetching: isDeviceCMASDataFetching } = useLastMonthQuery(
    useGetDeviceCMASDataQuery,
    deviceSelectedType !== DEVICE_TYPE_CMAS // solo para equipos CMAS
  );

  const dataCmas = useMemo(() => parseFunction(deviceCMASData), [deviceCMASData]);

  return (
    <>
      {isDeviceCMASDataFetching && <FullScreenLoader />}
      <ViewHeader title="CMAS" />
      {!dataCmas?.length ? (
        <div className="content-view px-8">
          <ChipQuestionMark>No se encontraron datos para este equipo.</ChipQuestionMark>
        </div>
      ) : (
        <div className="content-view px-8 cmas-ingredientes-columns">
          {dataCmas.map(
            ({
              date,
              ingredient,
              dryMatter,
              dryingMode,
              dryingTime,
              finalWeight,
              numberOfCycles,
              startingWeight,
              validationPercentage,
            }) => (
              <FormCard iconLabel={ingredient.slice(0, 1)} label={`${ingredient}`}>
                <span>Fecha: {customBodyRenderDateOnly(date)}</span>
                <span>Hora: {customBodyRenderTime(date)}</span>
                <span>%MS: {dryMatter}%</span>
                <span>Modo Secado: {dryingMode}</span>
                <span>Peso Inicial: {startingWeight}gr</span>
                <span>Peso Final: {finalWeight}gr</span>
                <span>Tiempo de Secado: {dryingTime}min</span>
                <span>% Val: {validationPercentage}%</span>
                <span>Nº Ciclos: {numberOfCycles}</span>
              </FormCard>
            )
          )}
        </div>
      )}
    </>
  );
}

export default Cmas;
