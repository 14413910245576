import { Checkbox } from '@material-ui/core';
import LiteTable from 'components/lite-table';
import { FullScreenLoader } from 'components/loader';
import SidebarSlide from 'design-system/sidebar-slide';
import { useAdminGetDevices } from 'hooks/useAdminGetDevices';
import { useEffect, useMemo, useState } from 'react';
import { useAddDeviceToUserMutation, useRemoveDeviceFromUserMutation } from 'store/admin/adminUsersApi';
import { LoggedUser } from 'store/auth/authApi';

function ModalAsociarEquipoToUser({
  isOpen,
  onClose = () => {},
  user,
}: {
  isOpen: boolean;
  onClose: () => void;
  user: LoggedUser | null;
}) {
  const { data: devices = [], isFetching: isGetDeviceAllFetching } = useAdminGetDevices();
  const [onAddDeviceToUser] = useAddDeviceToUserMutation();
  const [onRemoveDeviceFromUser] = useRemoveDeviceFromUserMutation();

  const originalDevices = useMemo(() => (user?.devices || []).map((d) => d.serialNumber), [user?.devices]);
  const [newUserAssociatedDevices, setNewUserAssociatedDevices] = useState<string[]>([]);

  useEffect(() => {
    setNewUserAssociatedDevices(originalDevices);
  }, [originalDevices]);

  const onToggleDevice = (serialNumber: string) => {
    setNewUserAssociatedDevices((associatedDevices) =>
      associatedDevices.includes(serialNumber)
        ? associatedDevices.filter((d) => d !== serialNumber)
        : associatedDevices.concat(serialNumber)
    );
  };

  const devicesRows = useMemo(
    () =>
      devices.map((device) => {
        const isAssociated = newUserAssociatedDevices.some(
          (newAssociatedSerialNumber) => device.serialNumber === newAssociatedSerialNumber
        );
        const nuevo = isAssociated && !originalDevices.includes(device.serialNumber);
        const deleted = !isAssociated && originalDevices.includes(device.serialNumber);
        return {
          ...device,
          isAssociated,
          nuevo,
          deleted,
          associated: (
            <Checkbox
              value={isAssociated}
              onChange={() => onToggleDevice(device.serialNumber)}
              checked={isAssociated === true}
              color="primary"
            />
          ),
        };
      }),
    [devices, newUserAssociatedDevices, originalDevices]
  );

  const onSave = () => {
    if (user?.username)
      devicesRows.forEach((device) => {
        if (device.isAssociated && device.nuevo)
          onAddDeviceToUser({ username: user?.username, serialNumber: device.serialNumber });
        else if (device.deleted)
          onRemoveDeviceFromUser({ username: user?.username, serialNumber: device.serialNumber });
      });
    onClose();
  };

  return (
    <SidebarSlide
      isOpen={isOpen}
      onClose={onClose}
      title="Asociar equipo"
      actions={[
        {
          autoFocus: false,
          onClick: onClose,
          text: 'Cancelar',
          variant: 'text-danger',
        },
        {
          autoFocus: false,
          onClick: onSave,
          text: 'Guardar',
          variant: 'fill',
        },
      ]}
    >
      {isGetDeviceAllFetching && <FullScreenLoader />}
      <div className="px-8 py-8">
        <h3>Usuario {user?.username}</h3>
        <LiteTable
          title=""
          data={devicesRows}
          columns={[
            {
              name: 'associated',
              label: '',
            },
            {
              name: 'serialNumber',
              label: 'N° Serial',
            },
            {
              name: 'alias',
              label: 'Alias',
            },
            {
              name: 'type',
              label: 'Tipo',
            },
          ]}
        />
      </div>
    </SidebarSlide>
  );
}
export default ModalAsociarEquipoToUser;
