import { useToggle } from 'hooks/useToggle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HAASTEN_LIGHT } from 'components/colors';

function ScreenMapSlide({
  toolbar,
  MapComponent,
  mapMarkers = [],
  slideContent,
  toggleLabel = '',
  mapProps = {},
}) {
  const [isOpen, toggle] = useToggle(true);

  return (
    <div className="page-container">
      {toolbar ? <div className="page-selector-toolbar">{toolbar}</div> : null}
      <div className="content-view">
        <div className={`mapa ${isOpen ? 'open' : ''}`}>
          {MapComponent ? (
            <MapComponent
              markers={mapMarkers}
              isFocused={!isOpen}
              {...mapProps}
            />
          ) : null}
        </div>
        <div className={`slide ${isOpen ? 'open' : ''}`}>
          <div
            className={`toggle-button ${isOpen ? 'open' : ''}`}
            onClick={toggle}
          >
            <FontAwesomeIcon icon="chevron-right" color={HAASTEN_LIGHT} />
            <span>
              {isOpen ? `Ocultar ${toggleLabel}` : `Mostrar ${toggleLabel}`}
            </span>
          </div>
          {slideContent}
        </div>
      </div>
    </div>
  );
}

export default ScreenMapSlide;
