import Table, { TableProps } from 'components/table/Table';
import Card from 'design-system/card';

export default function TableView(props: TableProps) {
  return (
    <div className="px-8 py-8">
      <Card>
        <Table {...props} />
      </Card>
    </div>
  );
}
