import { customBodyRenderDate } from 'utils/helpers/body-renders';

export const columnsCasHistorico = [
  {
    name: 'date',
    label: 'Fecha',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderDate,
    },
  },
  {
    name: 'actualWeight',
    label: 'Peso actual',
    options: { filter: false, sort: false },
  },
  {
    name: 'kgConsumidos',
    label: 'Kg consumidos',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value) => {
        if (value < 0)
          return <span className="px-3 py-1 w-fit bg-haasten-light/50 rounded-md">{value} (ingreso stock)</span>;
        else return value;
      },
    },
  },
  {
    name: 'axisPosition',
    label: 'Nivelación',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'temperature',
    label: 'Tempertatura (°C)',
    options: { filter: false, sort: false },
  },
  {
    name: 'humidity',
    label: 'Humedad (%)',
    options: { filter: false, sort: false },
  },
  {
    name: 'ithValue',
    label: 'ITH',
    options: { filter: false, sort: false },
  },
];
