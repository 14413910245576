import { Device } from 'utils/device';
import { api as devicesApi, TAG_DEVICE } from '../devices/devicesApi';

export const api = devicesApi.injectEndpoints({
  endpoints: (builder) => ({
    getDeviceAll: builder.query<Device[], void>({
      query: () => ({ url: `device/all` }),
      providesTags: [TAG_DEVICE],
    }),
    createDevice: builder.mutation<any, Device>({
      query: (newDevice) => ({
        url: 'device/create',
        method: 'POST',
        body: newDevice,
      }),
      invalidatesTags: [TAG_DEVICE],
    }),
    deleteDevice: builder.mutation<any, string>({
      query: (serialNumber) => ({
        url: `device/delete/${serialNumber}`,
        method: 'POST',
        body: {},
      }),
      invalidatesTags: [TAG_DEVICE],
    }),
  }),
});

export const {
  useGetDeviceAllQuery,
  useCreateDeviceMutation,
  useDeleteDeviceMutation,
} = api;
