import { useCustomCalendarQuery } from 'hooks/useCalendarQuery';
import { useMemo } from 'react';
import parseDataPesadas, { DeviceEventLoadFormatted } from 'screens/pesadas/parser';
import { useGetDeviceLoadDataQuery } from 'store/devices/devicesApi';
import { DeviceEventBase } from 'utils/device/event';
import { today, twoDaysAgo } from 'utils/helpers';
import { customBodyRenderDate } from 'utils/helpers/body-renders';

export const useThreeDayPesadas = () => {
  // Pesadas data
  const { data: deviceLoadData = [] } = useCustomCalendarQuery(useGetDeviceLoadDataQuery, twoDaysAgo, today);
  const pesadasData = useMemo(() => parseDataPesadas(deviceLoadData, {}, false), [deviceLoadData]);
  const pesadasSubtotales = useMemo(
    () =>
      pesadasData?.[0].reduce((acc, item, index) => {
        if (item.rowType === 'resultado' && !item.isResultadoFinal) {
          const previousRow = pesadasData?.[0][index - 1];
          const date = (previousRow as DeviceEventBase)?.date;
          const formattedDate = customBodyRenderDate(date);

          const recetaToLoad = Array.isArray(item.toLoad) ? item.toLoad[0] + item.toLoad[1] : item.toLoad || 0;
          const recetaLoaded = Array.isArray(item.loaded) ? item.loaded[0] + item.loaded[1] : item.loaded;
          const recetaDiferenciaKg = Array.isArray(item.diferenciaKg)
            ? item.diferenciaKg[0] + item.diferenciaKg[1]
            : item.diferenciaKg || 0;
          const recetaDiferenciaKgPorcentaje = (recetaDiferenciaKg / recetaToLoad) * 100;

          return [
            ...acc,
            {
              diferenciaKg: recetaDiferenciaKg,
              diferenciaKgPorcentaje: recetaDiferenciaKgPorcentaje,
              loaded: recetaLoaded,
              toLoad: recetaToLoad,
              date,
              formattedDate,
              recipe: Array.isArray(previousRow?.recipe) ? previousRow?.recipe[0] : previousRow?.recipe,
              tipo: 'Receta',
            },
          ];
        }
        return acc;
      }, [] as Partial<Omit<DeviceEventLoadFormatted, 'loaded' | 'toLoad'> & { date: string; formattedDate: string; diferenciaKgPorcentaje: number; loaded: number; toLoad: number }>[]),
    [pesadasData]
  );

  return pesadasSubtotales || [];
};
