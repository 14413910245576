import Table from 'components/table/Table';
import { useColumns } from 'hooks/useColumns';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDeviceSelectedStock } from 'store/devices/devicesSlice';
import { recetasActions, selectRecetaEditingIngredients } from 'store/recetas/recetasSlice';
import { Ingredient } from 'utils/device/stock';

function TablaAlimentos() {
  const dispatch = useDispatch();
  const { columns: columnsAlimentos } = useColumns('columnsTablaAlimentos');

  const recetaEditingIngredients = useSelector(selectRecetaEditingIngredients);

  const sipnStockSelected = useSelector(selectDeviceSelectedStock);

  const stockInBalanzaOrReceta = useMemo(() => {
    let ingredients =
      sipnStockSelected?.ingredients?.filter(
        (ingredient) => ingredient.id || recetaEditingIngredients.find((ing) => ing.name === ingredient.name)
      ) || [];
    return ingredients.concat(
      recetaEditingIngredients.filter(
        (ingredient) => !ingredients.find((i) => i.name === ingredient.name)
      ) as unknown as Ingredient[]
    );
  }, [recetaEditingIngredients, sipnStockSelected?.ingredients]);

  const recetaAlimentosNombres = useMemo(() => {
    return recetaEditingIngredients.filter((ingredient) => !ingredient.deleted).map((ingredient) => ingredient.name);
  }, [recetaEditingIngredients]);

  const alimentosSelected = useMemo(
    () =>
      stockInBalanzaOrReceta.reduce(function (accumulated, current, index) {
        if (recetaAlimentosNombres.includes(current.name)) accumulated.push(index);
        return accumulated;
      }, [] as number[]),
    [stockInBalanzaOrReceta, recetaAlimentosNombres]
  );

  const onRowsSelect = (currentRowsSelected: any[]) => {
    if (currentRowsSelected.length === 0)
      alimentosSelected.forEach((currentRow) =>
        dispatch(recetasActions.toggleAlimento(stockInBalanzaOrReceta[currentRow]))
      );
    else
      currentRowsSelected.forEach((currentRow) =>
        dispatch(recetasActions.toggleAlimento(stockInBalanzaOrReceta[currentRow.dataIndex]))
      );
  };
  return (
    <Table
      title="Alimentos"
      columns={columnsAlimentos}
      data={stockInBalanzaOrReceta}
      options={{
        pagination: false,
        onRowsSelect,
        disableToolbarSelect: true,
        selectableRows: 'multiple',
        filter: false,
      }}
      rowsSelected={alimentosSelected}
    />
  );
}
export default TablaAlimentos;
