import styled from 'styled-components';
import tw from 'twin.macro';

const ButtonBase = styled.button`
  ${tw`cursor-pointer justify-center text-sm font-medium text-neutral-200 shadow-sm focus:outline-none disabled:opacity-50 disabled:cursor-default`}
`;

export const ButtonLogout = styled.button`
  ${tw`cursor-pointer text-danger-700 w-full flex justify-center items-center gap-4`}

  svg {
    ${tw`fill-current`}
  }
`;

export const ButtonFill = styled(ButtonBase)`
  ${tw`inline-flex rounded-md border border-transparent bg-haasten-700 py-2 px-4 enabled:hover:bg-haasten-800 focus:outline-none`}
`;

export const ButtonText = styled(ButtonBase)``;

export const ButtonTextDanger = styled(ButtonText)`
  ${tw`text-danger-700 hover:text-danger-800`}
`;
