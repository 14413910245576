import Chip from 'design-system/chip';
import { IconCross } from 'design-system/icon';
import { ReactNode } from 'react';

export const ChipFail = ({ children }: { children?: ReactNode }) => {
  return (
    <Chip
      chipClassName="text-danger-600"
      iconClassName="bg-danger-600/20"
      Icon={<IconCross />}
    >
      <span>{children}</span>
    </Chip>
  );
};
