import Chip from 'design-system/chip';
import { IconSuccess } from 'design-system/icon';
import { ReactNode } from 'react';

export const ChipSuccess = ({ children }: { children?: ReactNode }) => {
  return (
    <Chip
      chipClassName="text-green-500"
      iconClassName="bg-green-500/10"
      Icon={<IconSuccess />}
    >
      <span>{children}</span>
    </Chip>
  );
};
