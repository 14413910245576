import Chip from 'design-system/chip';
import { IconInfo } from 'design-system/icon';
import { ReactNode } from 'react';

export const ChipInfo = ({ children }: { children?: ReactNode }) => {
  return (
    <Chip
      chipClassName="text-indigo-500"
      iconClassName="bg-indigo-500/10"
      Icon={<IconInfo />}
    >
      <span>{children}</span>
    </Chip>
  );
};
