import styled from 'styled-components';
import tw from 'twin.macro';

export const SidebarLogo = styled.div`
  ${tw`flex justify-between items-center px-2.5 py-2.5 h-16 tablet:justify-center`}

  button {
    ${tw`tablet:hidden`}
  }
`;

export const SidebarSection = styled.div`
  ${tw`flex justify-center items-center px-2.5 py-2.5 h-16 `}
`;

export const SidebarGroup = styled.div`
  ${tw`flex flex-col items-stretch gap-2 px-2.5`}
`;

export const SidebarHeader = styled.div`
  ${tw`px-2.5 py-1`}
`;

export const SidebarRoute = styled.div`
  ${tw`px-2.5 py-0`}
`;

export const AppMobileHeader = styled.div`
  ${tw`flex justify-start items-center px-2.5 py-2.5 h-16 border-b border-slate-800 select-none tablet:hidden`}

  > img {
    ${tw`py-1.5`}
  }
`;
