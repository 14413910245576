import LiteTable from 'components/lite-table';
import SidebarSlide from 'design-system/sidebar-slide';
import { useColumns } from 'hooks/useColumns';
import { DeviceEvent } from 'utils/device/event/sip/event';
import { customBodyRenderChangesArray } from 'utils/helpers/body-renders';

function EventHistoryDetail({
  isOpen,
  onClose = () => {},
  evento,
}: {
  isOpen: boolean;
  onClose: () => any;
  evento: DeviceEvent;
}) {
  const { columns: columnsGacUsers } = useColumns('columnsGacUsers');
  const { columns: columnsGacVehicles } = useColumns('columnsGacVehicles');

  const { data: { action = '' } = {} } = evento || {};
  const parsedProperty = action.toLowerCase().includes('recipe')
    ? 'receta'
    : action.toLowerCase().includes('lot')
    ? 'lote'
    : action.toLowerCase().includes('unloadguide')
    ? 'guía de descarga'
    : action.toLowerCase().includes('pumpuser')
    ? 'usuario'
    : action.toLowerCase().includes('pumpvehicle')
    ? 'vehiculo'
    : '';
  const parsedAction = action.toLowerCase().includes('update')
    ? 'update'
    : action.toLowerCase().includes('delete')
    ? 'delete'
    : action.toLowerCase().includes('add') || action.toLowerCase().includes('create')
    ? 'add'
    : '';

  const columnsGacUsersRenderDif = columnsGacUsers.map((col: any) => ({
    ...col,
    options: {
      ...col.options,
      customBodyRender: col.options?.customBodyRender || customBodyRenderChangesArray,
    },
  }));

  const columnsGacVehiclesRenderDif = columnsGacVehicles.map((col: any) => ({
    ...col,
    options: {
      ...col.options,
      customBodyRender: col.options?.customBodyRender || customBodyRenderChangesArray,
    },
  }));

  return (
    <SidebarSlide
      isOpen={isOpen}
      onClose={onClose}
      title={
        parsedAction === 'add'
          ? `Nueva ${parsedProperty} ${evento?.data?.newValue?.name}`
          : `Cambios ${parsedProperty} ${evento?.data?.prevValue?.name}`
      }
    >
      <div className="px-8 py-8">
        {parsedProperty === 'usuario' ? (
          <LiteTable
            title="Usuario"
            data={[parseToDiferencias(evento?.data?.prevValue, evento?.data?.newValue)]}
            columns={columnsGacUsersRenderDif}
          />
        ) : parsedProperty === 'vehiculo' ? (
          <LiteTable
            title="Vehículo"
            data={[parseToDiferencias(evento?.data?.prevValue, evento?.data?.newValue)]}
            columns={columnsGacVehiclesRenderDif}
          />
        ) : null}
      </div>
    </SidebarSlide>
  );
}

export default EventHistoryDetail;

function parseToDiferencias(prevValue: any, newValue: any) {
  if (!prevValue) return newValue;
  return Object.keys(prevValue).reduce(
    (acc, currKey) => ({
      ...acc,
      [currKey]: prevValue[currKey] === newValue[currKey] ? newValue[currKey] : [prevValue[currKey], newValue[currKey]],
    }),
    {}
  );
}
