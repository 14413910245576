import {
  filterByTableFilters,
  Filters,
  TableSubsumaResultadoRow,
  TableSubsumaRowValue,
} from 'components/table/TableSubsum';
import { DeviceEventLoad, filterAndSortyByLoad, filterValuesInData } from 'utils/device/event/sip';
import { redondearDosDecimales } from 'utils/helpers/math';

type AttributeToSum = 'amount' | 'loaded' | 'toLoad' | 'priceToLoad' | 'priceLoaded' | 'kgTeoricoMs' | 'kgCargadosMs';
type FilterableAttributes = 'user' | 'recipe' | 'ingredient';

export type DeviceEventLoadIngredientSummed = DeviceEventLoad &
  TableSubsumaRowValue & {
    priceToLoad: number;
    priceLoaded: number;
    porcentajeKg: number;
  };

type DeviceEventLoadIngredientResultado = TableSubsumaResultadoRow<AttributeToSum, string> & {
  [key in FilterableAttributes]: string[];
};

export type DeviceEventLoadFormatted = DeviceEventLoadIngredientSummed | DeviceEventLoadIngredientResultado;

const attributesPesadasToSum: AttributeToSum[] = [
  'amount',
  'loaded',
  'toLoad',
  'priceToLoad',
  'priceLoaded',
  'kgTeoricoMs',
  'kgCargadosMs',
];

function newAttributesToSumRow(isFinal: boolean = false): DeviceEventLoadIngredientResultado {
  return {
    ...attributesPesadasToSum.reduce(
      (acc, curr): DeviceEventLoadIngredientResultado => ({
        ...acc,
        [curr]: [, 0],
      }),
      {
        rowType: 'resultado',
        isResultadoFinal: isFinal,
      } as DeviceEventLoadIngredientResultado
    ),
  };
}

export default function parseDataHistoricoDietas(
  data: DeviceEventLoad[],
  filters: Filters = {},
  filterNotLoaded: boolean
): [DeviceEventLoadFormatted[], { [key in FilterableAttributes]: DeviceEventLoadFormatted[key][] }] {
  const dataSortedByLoad = filterAndSortyByLoad(data);
  const [userValues, recipeValues, ingredientValues] = [
    filterValuesInData(dataSortedByLoad, 'user'),
    filterValuesInData(dataSortedByLoad, 'recipe'),
    filterValuesInData(dataSortedByLoad, 'ingredient'),
  ];

  const loadData = filterByTableFilters(dataSortedByLoad, filters);

  let currTotal = newAttributesToSumRow(true);

  return [
    loadData.reduce((acc, event, index): DeviceEventLoadFormatted[] => {
      const { toLoad, loaded, price = 0, ms } = event || {};

      const porcentajeKg = redondearDosDecimales((loaded * 100) / toLoad - 100);
      const shouldAddRow = !filterNotLoaded || porcentajeKg > -95;

      const kgTeoricoMs = redondearDosDecimales((toLoad * ms) / 100);
      const kgCargadosMs = redondearDosDecimales((loaded * ms) / 100);

      const currRow: DeviceEventLoadIngredientSummed = {
        ...event,
        priceToLoad: redondearDosDecimales(price * toLoad),
        priceLoaded: redondearDosDecimales(price * loaded),
        porcentajeKg,
        kgTeoricoMs,
        kgCargadosMs,
        isSelected: true,
        rowType: 'value',
        semaforo: [
          {
            key: 'porcentajeKg',
            isWarning: Math.abs(porcentajeKg) > 5 && Math.abs(porcentajeKg) < 15,
            isDanger: Math.abs(porcentajeKg) >= 15,
          },
        ],
      };

      currTotal = shouldAddRow ? sumRowAttributes(currTotal, currRow) : currTotal;

      return [
        ...acc,
        ...(shouldAddRow ? [currRow] : []),
        ...(index === loadData.length - 1
          ? [
              {
                ...currTotal,
                user: userValues,
                recipe: recipeValues,
                ingredient: ingredientValues,
              },
            ]
          : []),
      ];
    }, [] as DeviceEventLoadFormatted[]),
    { user: userValues, recipe: recipeValues, ingredient: ingredientValues },
  ];
}

function sumRowAttributes(
  currSubsuma: DeviceEventLoadIngredientResultado,
  row: DeviceEventLoadIngredientSummed
): DeviceEventLoadIngredientResultado {
  let newCurrSubsuma = { ...currSubsuma };
  attributesPesadasToSum.forEach((key) => {
    if (row.isSelected && row[key]) newCurrSubsuma[key][1] = redondearDosDecimales(newCurrSubsuma[key][1] + row[key]);
  });
  return newCurrSubsuma;
}
