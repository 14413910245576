import LOGO_IMG from 'assets/img/favicon-haasten.png';
import Input from 'components/input';
import { ChipFail } from 'design-system/chip/chip-fail';
import { IconEyeOff, IconEyeOn, IconLock, IconUser } from 'design-system/icon';
import { TextHeader } from 'design-system/text';
import { useAuth } from 'hooks/useAuth';
import { useToggle } from 'hooks/useToggle';
import { MouseEvent, useState } from 'react';
import { getBackground } from './utils';

function Login() {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const canSubmit = username && password;
  const [isPasswordVisible, togglePasswordVisibility] = useToggle(false);

  const {
    tryLogin: { login, isLoginRejected },
  } = useAuth();

  const handleSubmit = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    login({ username, password });
  };

  return (
    <div className="w-full h-full relative tablet:grid tablet:grid-cols-3">
      <div className="absolute top-0 left-0 h-full w-full z-10 bg-black/80 flex items-center justify-center px-4 sm:px-6 lg:px-8 tablet:relative tablet:col-span-2 tablet:bg-transparent">
        <div className="w-full max-w-md">
          <div>
            <img className="mx-auto h-12 w-auto mb-3" src={LOGO_IMG} alt="logo haasten" />
            <div className="text-center">
              <TextHeader>Iniciar sesión</TextHeader>
            </div>
          </div>
          <form className="mt-8 space-y-6">
            <div className="-space-y-px flex flex-col gap-4">
              <Input
                placeholder="Usuario"
                value={username || ''}
                onChange={(u: string) => setUsername(u)}
                endAdornment={
                  <span className="opacity-50">
                    <IconUser />
                  </span>
                }
              />
              <Input
                endAdornment={
                  <button
                    onClick={togglePasswordVisibility}
                    className={isPasswordVisible ? 'opacity-100' : 'opacity-50'}
                    type="button"
                  >
                    {isPasswordVisible ? <IconEyeOn /> : <IconEyeOff />}
                  </button>
                }
                onChange={(p: string) => setPassword(p)}
                placeholder="Contraseña"
                type={isPasswordVisible ? 'text' : 'password'}
              />
              <button
                type="submit"
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-haasten-700 py-2 px-4 text-sm font-medium text-white hover:bg-haasten-800 focus:outline-none focus:ring-2 focus:ring-haasten-600 focus:ring-offset-2"
                disabled={!canSubmit}
                onClick={handleSubmit}
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <span className="h-5 w-5 text-haasten-600 group-hover:text-haasten-600">
                    <IconLock />
                  </span>
                </span>
                Iniciar sesión
              </button>
            </div>
            {isLoginRejected && <ChipFail>Usuario o contraseña incorrectos.</ChipFail>}
          </form>
        </div>
      </div>
      <img
        src={getBackground()}
        alt="fondo login"
        className="absolute top-0 left-0 w-full h-screen tablet:relative tablet:col-span-1"
      />
    </div>
  );
}

export default Login;
