import { HAASTEN_LIGHT } from 'components/colors';
import { Brush, CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { CustomTooltip } from 'screens/ith-historico/ITHPromedioCharts';
import { ChartDataPoint } from '.';

export default function ITHChart({ data }: { data: ChartDataPoint[] }) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart data={data}>
        <Line type="monotone" yAxisId="left" dataKey="ITH" name="ITH" stroke={"#a855f7"}  />
        <CartesianGrid stroke="#ccc" strokeDasharray="3 3" opacity={0.5} />
        <XAxis dataKey="date" />
        <YAxis yAxisId="left" dataKey="ITH" orientation="left" />
        <Tooltip content={<CustomTooltip />} />
        <Legend verticalAlign="top" />
        <Brush dataKey="date" height={30} stroke={HAASTEN_LIGHT} fill={'rgba(0,0,0,0)'} />
      </LineChart>
    </ResponsiveContainer>
  );
}
