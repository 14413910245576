import React, { useState } from 'react';
import { Slider } from '@material-ui/core';

export default function TimeFilter({
  onSetRangoHorario = () => {},
}: {
  onSetRangoHorario: (value: number[]) => void;
}) {
  const [rangoHorario, setRangoHorario] = useState([0, 24]);

  const handleChange = (
    _event: React.ChangeEvent<{}>,
    newValue: number | number[]
  ) => {
    if (Array.isArray(newValue) && newValue[0] !== newValue[1]) {
      setRangoHorario(newValue);
    }
  };

  const handleChangeCommited = (
    _event: React.ChangeEvent<{}>,
    newValue: number | number[]
  ) => {
    if (Array.isArray(newValue) && newValue[0] !== newValue[1]) {
      onSetRangoHorario(newValue);
    }
  };

  function valuetext(value: any) {
    return `${value}:00`;
  }

  return (
    <div className="slider">
      <Slider
        value={rangoHorario}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommited}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        getAriaValueText={valuetext}
        valueLabelFormat={valuetext}
        step={1}
        marks={marks}
        min={0}
        max={24}
      />
    </div>
  );
}

const marks = [
  {
    value: 3,
    label: '3:00',
  },
  {
    value: 9,
    label: '9:00',
  },
  {
    value: 15,
    label: '15:00',
  },
  {
    value: 21,
    label: '21:00',
  },
];
