import GoogleMapReact from 'google-map-react';
import { useEffect, useState } from 'react';
import Map, { MapMarker, DEFAULT_ZOOM, DEFAULT_CENTER } from 'components/map';
import { HAASTEN_LIGHT } from 'components/colors';
import {
  FormattedDeviceEventGeneralLocation,
  FormattedDeviceEventLoad,
  FormattedDeviceEventDownload,
} from '../parser';
import { MapMarkerDot } from 'components/map/marker';

type DeviceEventWithCoordinates =
  | FormattedDeviceEventGeneralLocation
  | FormattedDeviceEventLoad
  | FormattedDeviceEventDownload;

function MapUbicacion({
  eventsGeneralLocation = [],
  eventsLoad = [],
  eventsDownload = [],
  isFocused = false,
  ...props
}: {
  eventsGeneralLocation: FormattedDeviceEventGeneralLocation[];
  eventsLoad: FormattedDeviceEventLoad[];
  eventsDownload: FormattedDeviceEventDownload[];
  isFocused: boolean;
}) {
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [center, setCenter] = useState(DEFAULT_CENTER);
  const [map, setMap] = useState<any>();
  const [maps, setMaps] = useState<any>();
  const [polylineGeneralLocation, setPolylineGeneralLocation] = useState<any>(
    null
  );

  useEffect(() => {
    if (isFocused) setZoom(DEFAULT_ZOOM);
    else setZoom(DEFAULT_ZOOM - 2);
    const newCenter = getMarkersCenter([
      ...eventsGeneralLocation,
      ...eventsLoad,
      ...eventsDownload,
    ]);
    if (newCenter)
      setCenter({
        ...newCenter,
      });
  }, [eventsDownload, eventsGeneralLocation, eventsLoad, isFocused]);

  useEffect(() => {
    if (maps && map)
      if (eventsGeneralLocation.length > 0) {
        const polyline = new maps.Polyline({
          path: parseGeneralLocationToPolyline(eventsGeneralLocation),
          strokeColor: HAASTEN_LIGHT,
          strokeOpacity: 0.7,
          strokeWeight: 5,
        });
        if (polylineGeneralLocation) polylineGeneralLocation.setMap(null);
        polyline.setMap(map);
        setPolylineGeneralLocation(polyline);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maps, map, eventsGeneralLocation]);

  useEffect(() => {
    if (
      eventsGeneralLocation.length === 0 &&
      polylineGeneralLocation !== null
    ) {
      polylineGeneralLocation.setMap(null);
    }
  }, [eventsGeneralLocation, polylineGeneralLocation]);

  return (
    <Map
      center={center}
      zoom={zoom}
      {...props}
      GoogleMapReactProps={{
        yesIWantToUseGoogleMapApiInternals: true,
        onGoogleApiLoaded: ({ map, maps }) => {
          setMap(map);
          setMaps(maps);
        },
      }}
    >
      {eventsGeneralLocation.map(({ coordinates: { lat, lng }, date }, idx) => (
        <MapMarkerDot
          key={`marker-general-${idx}`}
          lat={lat}
          lng={lng}
          label={date}
          className="marker-ubicacion-general"
        />
      ))}
      {eventsLoad.map(({ coordinates: { lat, lng }, ingredient }, idx) => (
        <MapMarker
          key={`marker-load-${idx}`}
          lat={lat}
          lng={lng}
          label={ingredient}
          className="marker-load"
        />
      ))}
      {eventsDownload.map(
        ({ coordinates: { lat, lng }, downloadGuide }, idx) => (
          <MapMarker
            key={`marker-download-${idx}`}
            lat={lat}
            lng={lng}
            label={`${downloadGuide}`}
            className="marker-download"
          />
        )
      )}
    </Map>
  );
}

export default MapUbicacion;

function getMarkersCenter(
  markers: DeviceEventWithCoordinates[]
): GoogleMapReact.Coords | undefined {
  return markers.reduce(
    (acc, curr) => {
      const { lat, lng } = curr.coordinates;
      return {
        lat: ((acc?.lat || lat) + lat) / 2,
        lng: ((acc?.lng || lng) + lng) / 2,
      };
    },
    markers.length
      ? {
          lat: markers[0].coordinates.lat,
          lng: markers[0].coordinates.lng,
        }
      : undefined
  );
}

function parseGeneralLocationToPolyline(
  events: DeviceEventWithCoordinates[]
): { lat: number; lng: number }[] {
  return events.map((event) => ({
    lat: event.coordinates.lat,
    lng: event.coordinates.lng,
  }));
}
