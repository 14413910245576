import { HAASTEN_LIGHT } from 'components/colors';
import { DeviceEvent } from 'utils/device/event/sip/event';

export default function parseDeviceEventsHistory(
  data: DeviceEvent[] = [],
  onEventoClick?: (event: DeviceEvent) => any
) {
  return data
    .map((element) => ({
      ...element,
      action: element.data?.action,
      description: getEventoDescription(element, onEventoClick),
    }))
    .sort((element1, element2) => (element1.date > element2.date && -1) || 1);
}

function getEventoDescription(element: DeviceEvent, onEventoClick: (event: DeviceEvent) => any = () => {}) {
  const onClick = () => onEventoClick(element);
  let descripcion: any = '';
  switch (element.data?.action) {
    case 'updatePumpUserFromServer':
    case 'updatePumpUserFromDevice':
    case 'pumpUserUpdateFromServer':
    case 'pumpUserUpdateFromDevice':
    case 'pumpUserAddFromServer':
    case 'pumpUserAddFromDevice':
    case 'pumpVehicleUpdateFromServer':
    case 'pumpVehicleUpdateFromDevice':
      descripcion = (
        <>
          {getExpandableDescription(element.data?.action, element?.data?.newValue?.name)}
          <VerMasBoton onClick={onClick} />
        </>
      );
      break;

    case 'fuelQuantityUpdateFromServer':
    case 'fuelQuantityUpdateFromDevice':
      descripcion = getUpdateDescription(
        element.data?.action,
        'cantidad de combustible',
        'vehiculo',
        element?.data?.prevValue,
        element?.data?.newValue
      );
      break;
    case 'fuelPriceUpdateFromServer':
    case 'fuelPriceUpdateFromDevice':
      descripcion = getUpdateDescription(
        element.data?.action,
        'precio de combustible',
        'vehiculo',
        element?.data?.prevValue,
        element?.data?.newValue
      );
      break;

    default:
      break;
  }
  return descripcion;
}

function getUpdateDescription(
  action: string,
  property: string,
  element: any,
  prevValue: any,
  newValue: any,
  detail?: string
) {
  const from = action.toLowerCase().includes('device') ? 'el dispositivo' : 'la web';
  return `Se actualizó ${property} de ${element} de ${prevValue} a ${newValue} desde ${from}. ${
    detail ? `Detalle: ${detail}` : ''
  }`;
}

function getExpandableDescription(action: string, element: any) {
  const parsedAction = action.toLowerCase().includes('update')
    ? 'actualizó'
    : action.toLowerCase().includes('delete')
    ? 'eliminó'
    : action.toLowerCase().includes('add') || action.toLowerCase().includes('create')
    ? 'creó'
    : '';
  const parsedProperty = action.toLowerCase().includes('recipe')
    ? 'receta'
    : action.toLowerCase().includes('lot')
    ? 'lote'
    : action.toLowerCase().includes('unloadguide')
    ? 'guía de descarga'
    : action.toLowerCase().includes('pumpuser')
    ? 'usuario'
    : action.toLowerCase().includes('pumpvehicle')
    ? 'vehiculo'
    : '';

  const from = action.toLowerCase().includes('device') ? 'el dispositivo' : 'la web';

  return `Se ${parsedAction} ${parsedProperty} ${element} desde ${from}. `;
}

function VerMasBoton({ onClick = () => {} }: { onClick: (value?: any) => any }) {
  return (
    <>
      <span onClick={() => onClick()} style={{ cursor: 'pointer', color: HAASTEN_LIGHT }}>
        Ver más...
      </span>
    </>
  );
}
