import { HAASTEN_LIGHT, ORANGE, SECONDARY_ACCENT } from 'components/colors';
import FormCard, { FormCardIcon } from 'design-system/form-card';
import { IconBarChart } from 'design-system/icon';
import {
  Bar,
  Brush,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { redondearUnDecimales } from 'utils/helpers/math';
import { useAccionesStats } from './useAccionesStats';

export const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="flex flex-col p-4 bg-white/100 rounded-md">
        <p className="text-black">{label}</p>
        {payload.filter(Boolean).map((item: any) => (
          <p
            key={item.name}
            className="font-bold"
            style={{
              color: item.stroke || item.fill || item.color,
            }}
          >
            {item.name === 'Receta' && Boolean(item.payload?.Receta) && (
              <>
                Receta {item.payload?.payload?.recipe}
                <br />
                {item.value} Kg Cargados <br />
                {item.payload?.payload?.toLoad} Kg Teórico{' '}
                <span style={{ color: 'red' }}>
                  ({Math.sign(item.payload?.payload?.diferenciaKgPorcentaje) > 0 ? '+' : ''}
                  {redondearUnDecimales(item.payload?.payload?.diferenciaKgPorcentaje)}%)
                </span>
              </>
            )}
            {item.name === 'Descarga' && Boolean(item.payload?.Descarga) && (
              <>
                Descarga {item.payload?.payload?.downloadGuide}
                <br />
                {item.value} Kg Descargados <br />
                {item.payload?.payload?.toDownload} Kg Teórico{' '}
                <span style={{ color: 'violet' }}>
                  ({Math.sign(item.payload?.payload?.porcentajeKg) > 0 ? '+' : ''}
                  {redondearUnDecimales(item.payload?.payload?.porcentajeKg)}%)
                </span>
              </>
            )}
            {item.name === 'Lectura Comedero' && Boolean(item.payload?.Comedero) && (
              <>Lectura de Comedero {item.payload?.payload?.lot}</>
            )}
          </p>
        ))}
      </div>
    );
  }

  return null;
};

export const AccionesChart = () => {
  const stats = useAccionesStats();

  const data = stats.map((item: any) => ({
    payload: item,
    name: item.formattedDate,
    Receta: item.tipo === 'Receta' && item.loaded,
    RecetaToLoad: item.tipo === 'Receta' && item.toLoad - item.loaded,
    Descarga: item.tipo === 'Descarga' && item.downloaded,
    DescargaToDownload: item.tipo === 'Descarga' && item.toDownload - item.downloaded,
    Comedero: (item.action === 'feederState' && 500) || undefined,
  }));

  return (
    <div className="content-view px-8 py-8">
      <FormCard
        title="Acciones del día"
        icon={
          <FormCardIcon className="px-2 py-2">
            <IconBarChart />
          </FormCardIcon>
        }
        items={[
          {
            value: (
              <div className="w-full h-[70vh] relative">
                <ResponsiveContainer width="100%" height="100%">
                  <ComposedChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                      top: 20,
                      right: 0,
                      left: 0,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" opacity={0.5} />
                    <XAxis dataKey="name" />
                    <YAxis yAxisId="left" orientation="left" />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend verticalAlign="top" />
                    <Brush
                      dataKey="name"
                      height={30}
                      stroke={HAASTEN_LIGHT}
                      fill={'rgba(0,0,0,0)'}
                      startIndex={data.length - 15}
                    />
                    <Bar yAxisId="left" dataKey="Receta" fill={ORANGE} fillOpacity={0.8} />
                    {/* <Bar
                      yAxisId="left"
                      dataKey="RecetaToLoad"
                      name="Receta Teórico"
                      fill={'red'}
                      stroke={'red'}
                      strokeDasharray={'3 3'}
                      strokeOpacity={1}
                      fillOpacity={0.2}
                    /> */}
                    <Bar yAxisId="left" dataKey="Descarga" fill={SECONDARY_ACCENT} />
                    {/*   <Bar
                      yAxisId="left"
                      dataKey="DescargaToDownload"
                      name="Descarga Teórico"
                      fill={'violet'}
                      stroke={'violet'}
                      strokeDasharray={'3 3'}
                      strokeOpacity={1}
                      fillOpacity={0.2}
                    /> */}
                    <Scatter yAxisId="left" dataKey="Comedero" name="Lectura Comedero" fill={HAASTEN_LIGHT} />
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};
