import { capitalize } from 'lodash';
import moment from 'moment';

export function customBodyRenderChangesArray(value, renderPercentage = false) {
  const renderValueAsPercentage = (value) => (Array.isArray(value) ? value.map((value) => value * 100) : value * 100);
  if (value)
    return Array.isArray(value) ? (
      <>
        <span
          style={{
            backgroundColor: 'rgba(255, 0, 0,0.2)',
            color: 'red',
          }}
        >
          {renderPercentage === true ? renderValueAsPercentage(value[0]) : value[0]}
        </span>{' '}
        <span
          style={{
            backgroundColor: 'rgba(139, 195, 74,0.2)',
            color: 'green',
          }}
        >
          {renderPercentage === true ? renderValueAsPercentage(value[1]) : value[1]}
        </span>
      </>
    ) : renderPercentage === true ? (
      renderValueAsPercentage(value)
    ) : (
      value
    );
  return;
}

export function customBodyRenderDate(value) {
  return moment.utc(value).format('DD-MM-YYYY HH:mm:ss');
}

export function customBodyRenderDateShort(value) {
  return moment.utc(value).format('DD-MM - HH:mm');
}

export function customBodyRenderDateToLocal(value) {
  return moment.utc(value).local().format('DD-MM-YYYY HH:mm:ss');
}

export function customBodyRenderDateOnly(value) {
  return moment.utc(value).format('DD-MM-YYYY');
}

export function customBodyRenderDateOnlyShort(value) {
  return moment.utc(value).format('DD-MM');
}

export function customBodyRenderTime(value) {
  return moment.utc(value).format('HH:mm');
}

export function customBodyRenderBoolean(value, trueValue = 'ON', falseValue = 'OFF') {
  return value === true ? trueValue : falseValue;
}

export function capitalizeFirstLetter(string = '') {
  return capitalize(string);
}

export function customBodyRenderPercentage(value, decimalPlaces = 0) {
  return Array.isArray(value)
    ? `${value[0] ? (value[0] * 100).toFixed(decimalPlaces) : ''} ${
        value[1] ? (value[1] * 100).toFixed(decimalPlaces) : ''
      }`
    : `${value ? (value * 100).toFixed(decimalPlaces) : ''}`;
}
