import { ROUTE_PATH } from '../types';

export const ROUTE_GROUP_HOME = 'Principal';

export const ROUTES_ADMIN = [
  {
    subgroup: ROUTE_GROUP_HOME,
    devices: [],
    links: [
      { label: 'Equipos', link: ROUTE_PATH.HOME },
      { label: 'Usuarios', link: ROUTE_PATH.ADMIN_USUARIOS },
    ],
  },
];
