import { ProtectedView } from 'components/protected-view';
import SpaceDivider from 'components/space-divider';
import ConfigEmail from './config-email';
import ConfigEquipos from './config-equipos';

function Configuracion() {
  return (
    <>
      <div className="content-view py-8 px-8">
        <ConfigEmail />
        <ProtectedView active>
          <>
            <SpaceDivider className="mb-4" />
            <ConfigEquipos />
          </>
        </ProtectedView>
      </div>
    </>
  );
}

export default Configuracion;
