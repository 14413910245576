import { DeviceEventCMAS, sortCMAS } from 'utils/device/event/cmas';

export default function parseDataCmas(
  data: DeviceEventCMAS[] = []
): DeviceEventCMAS[] {
  return Object.values(
    sortCMAS(data).reduce(
      (acc, curr) => {
        if (!acc[curr.ingredient])
          return {
            ...acc,
            [curr.ingredient]: curr,
          };
        else return acc;
      },
      {} as {
        [key: string]: DeviceEventCMAS;
      }
    )
  );
}
