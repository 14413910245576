import { RecipeIngredientFormatted } from 'screens/creador-recetas/tabla-receta/parser';
import { UnloadGuide } from 'utils/device/lot';
import { redondearDosDecimales } from 'utils/helpers/math';
import { DeviceEventBase } from '..';

export interface DeviceDietHistoryEvent extends DeviceEventBase {
  dangerousLoads: number;
  feedingPercentage?: number;
  realfeedingPercentage?: number;
  headCount: number;
  ingredients: number;
  recipeIngredients: number;
  loaded: number;
  name: string;
  realHeadCount?: number;
  toLoad: number;
  warnings: number;
  price?: number; // TODO: esta propiedad viene?
}

export type RecipeIngredient = {
  id?: number;
  dryMatterPercentage: number;
  name: string;
  deleted?: boolean;
  nuevo?: boolean;
  value: number;
};

export const RECIPE_TYPE_HEADS = 'heads';
export const RECIPE_TYPE_KG = 'kg';

export type Recipe = {
  id?: number;
  ingredients: RecipeIngredient[];
  mixingTime: number;
  name: string;
  realHeadCount?: number;
  unloadGuide?: UnloadGuide;
  type: typeof RECIPE_TYPE_HEADS | typeof RECIPE_TYPE_KG;
  sortKey?: number;
};

export function getMSNominalReceta(
  recetaParsed: RecipeIngredientFormatted[] = []
) {
  return Array.isArray(recetaParsed) &&
    recetaParsed.length > 0 &&
    recetaParsed[recetaParsed.length - 1].rowType === 'resultado' &&
    Array.isArray(recetaParsed[recetaParsed.length - 1].kgMV) &&
    Array.isArray(recetaParsed[recetaParsed.length - 1].kgMS) &&
    (recetaParsed[recetaParsed.length - 1].kgMV as [number, number])[1] !== 0
    ? redondearDosDecimales(
        ((recetaParsed[recetaParsed.length - 1].kgMS as [number, number])[1] /
          (recetaParsed[recetaParsed.length - 1].kgMV as [number, number])[1]) *
          100
      )
    : 0;
}
