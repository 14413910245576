export default function Loader() {
  return (
    <div className="loader-container">
      <div className="loader-bar-wrapper">
        <div className="loader-bar" />
      </div>
    </div>
  );
}

export function FullScreenLoader() {
  return (
    <div className="full-screen-loader-container">
      <div className="loader-container">
        <div className="loader-bar-wrapper">
          <div className="loader-bar" />
        </div>
      </div>
    </div>
  );
}

export const FullscreenTailwindLoader = ({ messages }: { messages?: string[] }) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex flex-col justify-center items-center bg-black/80 z-[999]">
      <div className="loader-container">
        <div className="loader-bar-wrapper">
          <div className="loader-bar" />
        </div>
      </div>
      {messages && messages.length > 0 && <span className="text-white text-xl font-bold ml-2">Loading</span>}
    </div>
  );
};
