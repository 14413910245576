import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { HAASTEN_LIGHT, SECONDARY_ACCENT } from 'components/colors';
import { FullScreenLoader } from 'components/loader';
import ViewHeader from 'design-system/view-header';
import { useLastFourDaysQuery } from 'hooks/useCalendarQuery';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { CartesianGrid, Label, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useGetDeviceDataQuery } from 'store/devices/devicesApi';
import { selectDeviceSelected } from 'store/devices/devicesSlice';
import {
  DeviceEventCas,
  DeviceEventCasWithExtraData,
  groupCasDataBySilosAndCleanFalsePositives,
  sortCas,
} from 'utils/device/event/cas';
import { redondearSinDecimales, redondearUnDecimales } from 'utils/helpers/math';

export type ChartDataPoint = {
  date: string;
  temperature: number;
  humidity: number;
};

export function CAS() {
  const { data: deviceData = [], isLoading } = useLastFourDaysQuery(useGetDeviceDataQuery);

  // Agrupar por silo
  let dataGroupedBySilo = useMemo(
    () => groupCasDataBySilosAndCleanFalsePositives(sortCas(deviceData as unknown as DeviceEventCas[])),
    [deviceData]
  );

  return (
    <>
      <ViewHeader title="CAS" />
      {isLoading && <FullScreenLoader />}
      {isEmpty(dataGroupedBySilo) ? (
        <div className="content-view px-8">
          <p className="text-2xl font-bold">No hay datos para mostrar</p>
        </div>
      ) : (
        <div className="px-8 pb-8 w-full grid xl:grid-cols-2 gap-4">
          {Object.keys(dataGroupedBySilo)
            .sort()
            .map((siloName) => (
              <SiloAccordion
                key={siloName}
                siloName={siloName}
                deviceData={deviceData as unknown as DeviceEventCas[]}
                dataGroupedBySilo={dataGroupedBySilo}
              />
            ))}
        </div>
      )}
    </>
  );
}

const SiloAccordion = ({
  deviceData,
  siloName,
  dataGroupedBySilo,
}: {
  deviceData: DeviceEventCas[];
  siloName: string;
  dataGroupedBySilo: Record<string, DeviceEventCasWithExtraData[]>;
}) => {
  const deviceSelected = useSelector(selectDeviceSelected);

  const dataForThisSilo = dataGroupedBySilo[siloName] || [];

  // Full data array for this silo with false positives
  const dataForThisSiloName = sortCas(deviceData as unknown as DeviceEventCas[]).filter(
    (item) => item.siloName === siloName
  );
  const lastDataForThisSilo = dataForThisSiloName[dataForThisSiloName.length - 1];
  const ultimoDatoRecibidoFecha = moment.utc(lastDataForThisSilo.date).format('DD-MM-YYYY HH:mm:ss');
  const ultimoDatoRecibidoShortFecha = moment.utc(lastDataForThisSilo.date).format('DD-MM');

  const currentStock = dataForThisSilo[dataForThisSilo.length - 1].actualWeight;

  // Group dataForThisSilo by shortFecha
  const dataGroupedByDate = dataForThisSilo.reduce((acc, item) => {
    const shortFecha = item.shortFecha || '';
    const currentData = acc[shortFecha] || [];
    acc[shortFecha] = [...currentData, item];
    return acc;
  }, {} as any);

  const consumoDiarioByDay = Object.keys(dataGroupedByDate).reduce((acc, item, index) => {
    const dataForThisDay = dataGroupedByDate[item];

    // Group dataForThisDay into arrays of points with decreasing actualWeight
    const dataForThisDaySegments = dataForThisDay.reduce((acc: any[][], item: { actualWeight: number }) => {
      const currentSegment = acc[acc.length - 1] || [];
      const lastItem = currentSegment[currentSegment.length - 1] || { actualWeight: item.actualWeight + 1 };
      if (item.actualWeight > lastItem.actualWeight) {
        acc.push([item]);
      } else {
        currentSegment.push(item);
        if (acc.length === 0) acc.push(currentSegment);
      }
      return acc;
    }, [] as DeviceEventCasWithExtraData[][]);

    // Iterate through each segment in dataForThisDaySegments and calculate the consumption for each segment
    const consumoDiario = dataForThisDaySegments.reduce((acc: number, item: string | any[]) => {
      const consumo = item[0].actualWeight - item[item.length - 1].actualWeight;
      acc += consumo;
      return acc;
    }, 0);

    acc[item] = consumoDiario;
    return acc;
  }, {} as Record<string, number>);

  // Remove from consumoDiarioByDay the key that matches ultimoDatoRecibidoShortFecha
  const consumoDiaroByDayExceptLastOne = Object.keys(consumoDiarioByDay).reduce((acc, item) => {
    if (item !== ultimoDatoRecibidoShortFecha) {
      acc[item] = consumoDiarioByDay[item];
    }
    return acc;
  }, {} as Record<string, number>);

  // Consumo promedio de los últimos 3 días (sin contar hoy) = suma de todos los consumos diarios / cantidad de consumos diarios
  const consumoPromedioLastThreeDays = redondearSinDecimales(
    Object.values(consumoDiaroByDayExceptLastOne).reduce((acc, item) => acc + item, 0) /
      Object.values(consumoDiaroByDayExceptLastOne).length
  );

  const consumoDiario = consumoDiarioByDay[ultimoDatoRecibidoShortFecha] || 0;
  const axis = dataForThisSilo[dataForThisSilo.length - 1].axisPosition;

  // Dias restantes = currentStock / consumoDiario
  const diasRestantes =
    Math.abs(currentStock) > 0 && Math.abs(consumoDiario) > 0 ? redondearSinDecimales(currentStock / consumoDiario) : 0;

  // Silo info for this silo
  const silo = deviceSelected?.silos?.find((silo) => siloName.includes(String(silo.id)));
  const siloHeads = silo?.heads || 0;

  const consumoCab = siloHeads > 0 ? redondearUnDecimales(consumoDiario / siloHeads) : 0;

  const siloDays = silo?.days || 0;

  useEffect(() => {
    if (siloDays > 0 && diasRestantes < siloDays)
      toast.error(
        `${siloName} - ${siloDays === 1 ? 'Queda' : 'Quedan'} ${siloDays} ${siloDays === 1 ? 'día' : 'días'}.`,
        {
          position: 'bottom-right',
        }
      );
  }, [diasRestantes, siloDays, siloName]);

  useEffect(() => {
    if (currentStock < 200)
      toast.warn(`${siloName} - Menos de 200kg.`, {
        position: 'bottom-right',
      });
  }, [currentStock, siloName]);

  useEffect(() => {
    if (consumoDiario < 10)
      toast.warn(`${siloName} - Sin consumo.`, {
        position: 'bottom-right',
      });
  }, [consumoDiario, siloName]);

  return (
    <Accordion
      className="h-fit"
      style={{
        backgroundColor: 'transparent',
        border: 'rgba(255, 255, 255, 0.1) 1px solid',
        boxShadow: '0px 0px 1px rgba(255,255,255,0.25)',
        color: 'white',
      }}
    >
      <AccordionSummary
        expandIcon={<FiChevronDown fontSize="32px" />}
        style={{
          color: 'white !important',
        }}
      >
        <div className="flex gap-1 w-full flex-col">
          <h3 className="text-xl font-bold leading-6 text-haasten-light whitespace-nowrap">
            {siloName}
            {silo?.alias ? ` - "${silo?.alias}"` : ''}
          </h3>
          <h3 className="text-lg font-medium leading-6 text-white">Stock actual: {currentStock}Kg</h3>
          <h3 className="text-lg font-medium leading-6 text-white">Consumo diario: {consumoDiario}Kg</h3>
          <h3 className="text-lg font-medium leading-6 text-white">
            Consumo promedio (3 días): {consumoPromedioLastThreeDays}Kg
          </h3>
          <h3 className="text-lg font-medium leading-6 text-white">Consumo cab: {consumoCab}</h3>
          <h3 className="text-lg font-medium leading-6 text-white">Días restantes: {diasRestantes}</h3>
          <h3 className="text-lg font-medium leading-6 text-white"> x, y, z: {axis}</h3>
          <p className="mt-1 max-w-2xl text-sm text-neutral-300">Último dato recibido: {ultimoDatoRecibidoFecha}hs</p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div
          className="w-full pl-0 aspect-square relative flex flex-col gap-2 border border-gray-500 rounded-md pt-3"
          key={siloName}
        >
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={dataGroupedByDate[ultimoDatoRecibidoShortFecha]}>
              <Line type="monotone" yAxisId="left" dataKey="actualWeight" name="Kg Silo" stroke={HAASTEN_LIGHT} />
              <Line type="monotone" yAxisId="right" dataKey="ithValue" name="ITH" stroke={SECONDARY_ACCENT} />
              <CartesianGrid stroke="#ccc" strokeDasharray="3 3" opacity={0.5} />
              <XAxis dataKey="shortFechaHora" />
              <YAxis yAxisId="left" dataKey="actualWeight" orientation="left">
                <Label
                  angle={-90}
                  value="Peso actual"
                  position="insideLeft"
                  color={HAASTEN_LIGHT}
                  style={{ textAnchor: 'middle', color: HAASTEN_LIGHT }}
                />
              </YAxis>
              <YAxis yAxisId="right" dataKey="ithValue" orientation="right">
                <Label
                  angle={90}
                  value="ITH"
                  position="insideRight"
                  color={HAASTEN_LIGHT}
                  style={{ textAnchor: 'middle', color: HAASTEN_LIGHT }}
                />
              </YAxis>
              <Tooltip content={<CustomTooltip />} />
              <Legend verticalAlign="top" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="flex flex-col p-4 bg-white/90 rounded-md">
        <p className="text-black">{label}</p>
        {payload.map((item: any) => (
          <p
            key={item.name}
            className="font-bold"
            style={{
              color: item.stroke || item.fill || item.color,
            }}
          >
            {item.name}: {item.value}
          </p>
        ))}
      </div>
    );
  }

  return null;
};
