import { ReactNode } from 'react';

export default function Chip({
  chipClassName,
  iconClassName,
  Icon,
  children,
}: {
  chipClassName?: string;
  iconClassName?: string;
  Icon?: ReactNode;
  children: ReactNode;
}) {
  return (
    <div
      className={`flex items-center w-full text-left ${chipClassName}`}
    >
      {Icon && (
        <div className={`rounded-full h-7 w-7 px-1 py-1 mr-2 ${iconClassName}`}>
          {Icon}
        </div>
      )}
      <span>{children}</span>
    </div>
  );
}
