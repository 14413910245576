import Input from 'components/input';
import ChipToggle from 'design-system/chip-toggle';
import { ChipInfo } from 'design-system/chip/chip-info';
import FormCard, { FormCardIcon } from 'design-system/form-card';
import { IconDevices } from 'design-system/icon';
import Select from 'design-system/select';
import moment from 'moment';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSetCmasToSipnMutation, useUpdateDeviceMutation } from 'store/config/configApi';
import { selectCMASDevices } from 'store/config/configSlice';
import { Device, isDeviceCas, isDeviceSIP } from 'utils/device';
import { isTrue } from 'utils/helpers/validation';

function CardConfigEquipo({ device }: { device: Device }) {
  const CMASDevices = useSelector(selectCMASDevices);

  const [updateDevice] = useUpdateDeviceMutation();
  const [setCmasToSipn] = useSetCmasToSipnMutation();

  const {
    alias = '',
    type,
    serialNumber,
    dryMatterModifiesRecipes,
    associatedCmas,
    averageFuelConsumptionPerKilometer,
    fuelCostPerLitre,
    lastDataRecievedDate,
    mixerMaxCapacity,
  } = device;

  const onUpdateAlias = (value: string) => {
    updateDevice({ ...device, alias: value });
  };

  const onDryMatterModifiesRecipesToggle = (value: any) => {
    updateDevice({ ...device, dryMatterModifiesRecipes: value });
  };

  const onAverageFuelConsumptionPerKilometerChange = (value: any) => {
    updateDevice({
      ...device,
      averageFuelConsumptionPerKilometer: Number(value),
    });
  };

  const onFuelCostPerLitreChange = (value: any) => {
    updateDevice({ ...device, fuelCostPerLitre: Number(value) });
  };

  const onMixerMaxCapacityChange = (value: any) => {
    updateDevice({ ...device, mixerMaxCapacity: Number(value) });
  };

  const CMAS_OPTIONS = useMemo(
    () =>
      CMASDevices?.map((cmasDevice: any) => ({
        name: `${cmasDevice.serialNumber}${cmasDevice.alias ? ` (${cmasDevice.alias})` : ''}`,
        value: `${cmasDevice.serialNumber}`,
      })),
    [CMASDevices]
  );

  return (
    <FormCard
      title={`Equipo ${serialNumber}${alias ? ` - ${alias}` : ''}`}
      subtitle={type}
      icon={
        <FormCardIcon className="px-2 py-2">
          <IconDevices />
        </FormCardIcon>
      }
      items={[
        ...(lastDataRecievedDate
          ? [
              {
                value: (
                  <ChipInfo>
                    Última conexión: {moment.utc(lastDataRecievedDate).local().format('HH:mm:ss DD-MM-YYYY')}
                  </ChipInfo>
                ),
              },
            ]
          : []),
        {
          label: 'Alias',
          value: (
            <Input
              placeholder="Cmas, N1, N2, ..."
              defaultValue={alias || ''}
              onBlur={(value: string) => {
                if (value !== alias) onUpdateAlias(value);
              }}
              type={'text'}
            />
          ),
        },
        ...(isDeviceSIP(device)
          ? [
              {
                label: 'CMAS asociado',
                value: (
                  <Select
                    label="CMAS asociado"
                    placeholder="Sin CMAS asociado"
                    options={CMAS_OPTIONS || []}
                    onChangeValue={(value: string) => {
                      setCmasToSipn({
                        sipnSerialNumber: serialNumber,
                        cmasSerialNumber: value,
                      });
                    }}
                    value={associatedCmas?.serialNumber}
                  />
                ),
              },
              {
                label: '',
                value: (
                  <ChipToggle
                    label="¿Modificar recetas al cambiar %MS?"
                    onToggle={onDryMatterModifiesRecipesToggle}
                    toggleValue={isTrue(dryMatterModifiesRecipes)}
                    noBorder
                  />
                ),
              },
              {
                label: 'Consumo promedio de combustible por km (lt)',
                value: (
                  <Input
                    placeholder="Consumo promedio"
                    defaultValue={averageFuelConsumptionPerKilometer ?? 0}
                    onBlur={(value: string) => {
                      if (Number(value) !== averageFuelConsumptionPerKilometer)
                        onAverageFuelConsumptionPerKilometerChange(value);
                    }}
                    type={'number'}
                  />
                ),
              },
              {
                label: 'Costo de combustible por litro ($)',
                value: (
                  <Input
                    placeholder="Costo"
                    defaultValue={fuelCostPerLitre ?? 0}
                    onBlur={(value: string) => {
                      if (Number(value) !== fuelCostPerLitre) onFuelCostPerLitreChange(value);
                    }}
                    type={'number'}
                  />
                ),
              },
              {
                label: 'Capacidad máxima Mixer (Kg)',
                value: (
                  <Input
                    placeholder="Capacidad"
                    defaultValue={mixerMaxCapacity ?? 0}
                    onBlur={(value: string) => {
                      if (Number(value) !== mixerMaxCapacity) onMixerMaxCapacityChange(value);
                    }}
                    type={'number'}
                  />
                ),
              },
            ]
          : []),
        ...(isDeviceCas(device)
          ? (device.silos || []).map((silo) => ({
              label: `Silo ${silo.id}`,
              value: (
                <div className="grid grid-cols-3 gap-3 w-full">
                  <Input
                    label="Alias"
                    placeholder="Alias"
                    defaultValue={silo.alias || ''}
                    onBlur={(value: string) => {
                      if (value !== silo.alias)
                        updateDevice({
                          ...device,
                          silos: device.silos?.map((s) => {
                            if (s.id === silo.id) return { ...s, alias: value };
                            return s;
                          }),
                        });
                    }}
                    type={'text'}
                  />
                  <Input
                    label="Cabezas"
                    placeholder="Cabezas"
                    defaultValue={silo.heads ?? 0}
                    onBlur={(value: string) => {
                      if (Number(value) !== silo.heads)
                        updateDevice({
                          ...device,
                          silos: device.silos?.map((s) => {
                            if (s.id === silo.id) return { ...s, heads: Number(value) };
                            return s;
                          }),
                        });
                    }}
                    type={'number'}
                  />
                  <Input
                    label="Días"
                    placeholder="Días"
                    defaultValue={silo.days ?? 0}
                    onBlur={(value: string) => {
                      if (Number(value) !== silo.days)
                        updateDevice({
                          ...device,
                          silos: device.silos?.map((s) => {
                            if (s.id === silo.id) return { ...s, days: Number(value) };
                            return s;
                          }),
                        });
                    }}
                    type={'number'}
                  />
                </div>
              ),
            }))
          : []),
      ]}
    />
  );
}

export default CardConfigEquipo;
