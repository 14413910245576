import {
  customBodyRenderDateTotalColumn,
  customBodyRenderSummedAttribute,
} from 'components/table/TableSubsum';
import { customBodyRenderPercentage } from 'utils/helpers/body-renders';

export const columnsHistoricoDietas = [
  {
    name: 'date',
    label: 'Fecha',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderDateTotalColumn,
    },
  },
  {
    name: 'name',
    label: 'Dieta',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'user',
    label: 'Usuario',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'warnings',
    label: 'Advertencias',
    options: { filter: false, sort: false },
  },
  {
    name: 'dangerousLoads',
    label: 'Peligrosas',
    options: { filter: false, sort: false },
  },
  {
    name: 'headCount',
    label: 'Cantidad Cabezas / KG',
    options: { filter: false, sort: false },
  },
  {
    name: 'realHeadCount',
    label: 'Cabezas reales',
    options: { filter: false, sort: false },
  },
  {
    name: 'feedingPercentage',
    label: 'Alimentacion Teórica %',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderPercentage,
    },
  },
  {
    name: 'realfeedingPercentage',
    label: 'Alimentación Real %',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderPercentage,
    },
  },
  {
    name: 'toLoad',
    label: 'Kg Teorico',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderSummedAttribute,
    },
  },
  {
    name: 'loaded',
    label: 'Kg Cargados',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderSummedAttribute,
    },
  },
  {
    name: 'toLoadDietCost',
    label: '$ Dieta Teórica',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderSummedAttribute,
    },
  },
  {
    name: 'loadedDietCost',
    label: '$ Dieta Cargada',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderSummedAttribute,
    },
  },
  {
    name: 'ingredientsOverOriginalIngredients',
    label: 'Nº Ingredientes',
    options: { filter: false, sort: false },
  },
];
