import { customBodyRenderDateToLocal } from 'utils/helpers/body-renders';

export const columnsGACHistorico = [
  {
    name: 'fecha',
    label: 'Fecha',
    options: {
      filter: false,
      sort: false,

      customBodyRender: customBodyRenderDateToLocal,
    },
  },
  {
    name: 'user',
    label: 'Usuario',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'ltDownload',
    label: 'Lt Despachados',
    options: { filter: false, sort: false },
  },
  {
    name: 'ltAvaiableUser',
    label: 'Lt Disponibles',
    options: { filter: false, sort: false },
  },
  {
    name: 'vehicle',
    label: 'Vehículo/Contenedor',
    options: {
      filter: true,
      sort: false,
    },
  },
];
