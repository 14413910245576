import logo from 'assets/img/Logo-verde-y-blanco-Haasten.png';
import styled from 'styled-components';
import tw from 'twin.macro';

export const LogoImg = styled.img`
  ${tw`w-auto h-full`}
`;

export function Logo() {
  return <LogoImg src={logo} alt="logo" />;
}
