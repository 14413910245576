import { FullScreenLoader } from 'components/loader';
import TableView from 'design-system/table-view';
import ViewHeader from 'design-system/view-header';
import { useCalendarQuery } from 'hooks/useCalendarQuery';
import { useColumns } from 'hooks/useColumns';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useGetDeviceDataQuery } from 'store/devices/devicesApi';
import { selectDeviceSelected } from 'store/devices/devicesSlice';
import { ITHPromedioCharts } from './ITHPromedioCharts';
import { ITHPromedioRangoHorario } from './ITHPromedioRangoHorario';
import parseFunction from './parser';

function ITHHistorico() {
  const deviceSelected = useSelector(selectDeviceSelected);
  const { data: deviceData = [], isFetching: isDeviceDataFetching } = useCalendarQuery(useGetDeviceDataQuery);

  const { columns, onColumnsViewChange } = useColumns('columnsITHHistorico');
  const dataITHHistorico = useMemo(() => parseFunction(deviceData), [deviceData]);

  // Hardcodeo para equipo 202227091
  const columns091 =
    deviceSelected?.serialNumber === '202227091'
      ? [
          ...columns,
          {
            name: 'A1',
            label: 'A1',
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value: string) => (value ? 'ON' : 'OFF'),
            },
          },
          {
            name: 'A5',
            label: 'A5',
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value: string) => (value ? 'ON' : 'OFF'),
            },
          },
        ]
      : columns;

  return (
    <>
      {isDeviceDataFetching && <FullScreenLoader />}
      <ViewHeader title="Histórico de ITH" dateSelector="range" maxDateRangeInDays={31} />
      <ITHPromedioCharts dataITHHistorico={dataITHHistorico} />
      <ITHPromedioRangoHorario dataITHHistorico={dataITHHistorico} />
      <TableView
        title="Valores históricos"
        data={dataITHHistorico}
        columns={columns091}
        onColumnViewChange={onColumnsViewChange}
        options={{
          pagination: false,
          filter: true,
        }}
      />
    </>
  );
}

export default ITHHistorico;
