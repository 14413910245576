import {
  DeviceDataEvent,
  DeviceEventDownload,
  DeviceEventGeneralLocation,
  DeviceEventLoad,
  filterAndSortyByDownload,
  filterAndSortyByGeneralLocation,
  filterAndSortyByLoad,
} from 'utils/device/event/sip';
import { customBodyRenderDate } from 'utils/helpers/body-renders';

type FormattedEventWithCoordinates<T> = Omit<T, 'coordinates'> & {
  coordinates: { lat: number; lng: number };
};

export type FormattedDeviceEventGeneralLocation = FormattedEventWithCoordinates<
  DeviceEventGeneralLocation
>;
export type FormattedDeviceEventLoad = FormattedEventWithCoordinates<
  DeviceEventLoad
>;
export type FormattedDeviceEventDownload = FormattedEventWithCoordinates<
  DeviceEventDownload
>;

export default function parseDataUbicacion(
  data: DeviceDataEvent[] = []
): [
  FormattedDeviceEventGeneralLocation[],
  FormattedDeviceEventLoad[],
  FormattedDeviceEventDownload[]
] {
  const dataGeneralLocation = parseCoordinates(
    filterAndSortyByGeneralLocation(data)
  );
  const dataLoad = parseCoordinates(filterAndSortyByLoad(data));
  const dataDownload = parseCoordinates(filterAndSortyByDownload(data));

  return [dataGeneralLocation, dataLoad, dataDownload];
}

function parseCoordinates<
  T extends DeviceEventGeneralLocation | DeviceEventLoad | DeviceEventDownload
>(data: T[]): FormattedEventWithCoordinates<T>[] {
  return data
    .filter((event) => event.coordinates)
    .map((event, idx) => ({
      ...event,
      date: customBodyRenderDate(event.date),
      coordinates: {
        lat: parseFloat(event.coordinates.split(',')[0]),
        lng: parseFloat(event.coordinates.split(',')[1]),
      },
    }))
    .filter((event) => event.coordinates.lat && event.coordinates.lng);
}
