export const columnsITHHistorico = [
  { name: 'fecha', label: 'Fecha', options: { filter: false, sort: false } },
  {
    name: 'temperature',
    label: 'Temperatura (˚C)',
    options: { filter: false, sort: false },
  },
  {
    name: 'humidity',
    label: 'Humedad (%)',
    options: { filter: false, sort: false },
  },
  {
    name: 'ITH',
    label: 'ITH',
    options: { filter: false, sort: false },
  },
];
