import { Device } from 'utils/device';
import {
  api as devicesApi,
  TAG_COLUMNA,
  TAG_DEVICE,
  TAG_USER,
} from '../devices/devicesApi';

export const api = devicesApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserColumns: builder.query<any, { username: string }>({
      query: ({ username }) => ({ url: `getUserColumns?username=${username}` }),
      providesTags: () => [TAG_COLUMNA],
    }),
    setUserColumns: builder.mutation<any, { username: string; columns: any }>({
      query: ({ username, columns }) => ({
        url: 'setUserColumns',
        method: 'POST',
        body: { username, columns },
      }),
      invalidatesTags: [TAG_COLUMNA],
    }),
    setEmailToUser: builder.mutation<any, { email: string }>({
      query: ({ email }) => ({
        url: 'setEmailToUser',
        method: 'POST',
        body: { email },
      }),
      invalidatesTags: [TAG_USER],
    }),
    setSecondaryEmailToUser: builder.mutation<any, { email: string }>({
      query: ({ email }) => ({
        url: 'setSecondaryEmailToUser',
        method: 'POST',
        body: { email },
      }),
      invalidatesTags: [TAG_USER],
    }),
    // updateAliasOfEquipo: builder.mutation<any, { alias:string, serialNumber: string }>({
    //   query: ({ alias, serialNumber }) => ({
    //     url: "updateAliasOfEquipo",
    //     method: "POST",
    //     body: { alias, serialNumber },
    //   }),
    //   invalidatesTags: [TAG_DEVICE],
    // }),
    setCmasToSipn: builder.mutation<
      any,
      { sipnSerialNumber: string; cmasSerialNumber: string }
    >({
      query: ({ sipnSerialNumber, cmasSerialNumber }) => ({
        url: `device/update/setCmasToSipn`,
        method: 'POST',
        body: { sipnSerialNumber, cmasSerialNumber },
      }),
      invalidatesTags: [TAG_DEVICE],
    }),
    updateDevice: builder.mutation<any, Device>({
      query: (device) => ({
        url: `device/update`,
        method: 'POST',
        body: device,
      }),
      invalidatesTags: [TAG_DEVICE],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetUserColumnsQuery,
  useSetUserColumnsMutation,
  useSetEmailToUserMutation,
  useSetSecondaryEmailToUserMutation,
  useUpdateDeviceMutation,
  useSetCmasToSipnMutation,
} = api;
