import {
  filterByTableFilters,
  Filters,
  RowExtraAttributes,
  TableSubsumaResultadoRow,
  TableSubsumaRowValue,
} from 'components/table/TableSubsum';
import { ACTION_LOAD_MANUAL, DeviceEventLoad, filterAndSortyByLoad, filterValuesInData } from 'utils/device/event/sip';
import { redondearDosDecimales } from 'utils/helpers/math';

type AttributeToSum = 'toLoad' | 'loaded' | 'diferenciaKg' | 'porcentajePrecio';
type ExtraAttributes = 'totalDietaTeorica' | 'totalDietaCargada';
type FilterableAttributes = 'user' | 'recipe';

export type DeviceEventLoadSummed = DeviceEventLoad &
  TableSubsumaRowValue & {
    diferenciaKg: number;
    porcentajePrecio: number;
    porcentajeKg: number;
    kgTeoricoMS: number;
    kgCargadosMS: number;
  };

type DeviceEventLoadResultado = TableSubsumaResultadoRow<AttributeToSum, ExtraAttributes> & {
  [key in FilterableAttributes]: string[];
};

export type DeviceEventLoadFormatted = DeviceEventLoadSummed | DeviceEventLoadResultado;

const attributesPesadasToSum: AttributeToSum[] = ['toLoad', 'loaded', 'diferenciaKg', 'porcentajePrecio'];

const extraAttributesPesadasToSum: ExtraAttributes[] = ['totalDietaTeorica', 'totalDietaCargada'];

function extraAttributesValuesFromRow(row?: DeviceEventLoadSummed): RowExtraAttributes<ExtraAttributes, number> {
  return {
    totalDietaTeorica: {
      label: 'Total Dieta Téorica ($)',
      value: redondearDosDecimales((row?.toLoad || 0) * (row?.price || 0)),
    },
    totalDietaCargada: {
      label: 'Total Dieta Cargada ($)',
      value: redondearDosDecimales((row?.loaded || 0) * (row?.price || 0)),
    },
  };
}

function newAttributesToSumRow(isFinal: boolean = false): DeviceEventLoadResultado {
  return {
    ...attributesPesadasToSum.reduce(
      (acc, curr): DeviceEventLoadResultado => ({
        ...acc,
        [curr]: [0, 0],
      }),
      {
        rowType: 'resultado',
        isResultadoFinal: isFinal,
      } as DeviceEventLoadResultado
    ),
    extra: extraAttributesValuesFromRow(),
  };
}

export default function parseDataPesadas(
  data: DeviceEventLoad[] = [],
  filters: Filters = {},
  removePorcentajeKgZeros: boolean
): [DeviceEventLoadFormatted[], { [key in FilterableAttributes]: DeviceEventLoadFormatted[key][] }] {
  const dataFilteredAndSortedByLoad = filterAndSortyByLoad(data);
  const [userValues, recipeValues] = [
    filterValuesInData(dataFilteredAndSortedByLoad, 'user'),
    filterValuesInData(dataFilteredAndSortedByLoad, 'recipe'),
  ];

  const loadData = filterByTableFilters(dataFilteredAndSortedByLoad, filters);

  let currSubsuma = newAttributesToSumRow();
  let currTotal = newAttributesToSumRow(true);

  return [
    loadData.reduce((acc, event, index): DeviceEventLoadFormatted[] => {
      const { action, recipe, toLoad, loaded, ms = 100, endRecipe, price = 0, addsToStock } = event || {};
      let nextReceta = index + 1 < loadData.length ? loadData[index + 1].recipe : '';
      const isFinReceta = endRecipe === 'yes' || endRecipe === 'yesAnticipated' || !!(recipe && recipe !== nextReceta);
      const porcentajeKg = redondearDosDecimales((loaded * 100) / toLoad - 100);
      const shouldAddRow = !removePorcentajeKgZeros || porcentajeKg > -95;

      const diferenciaKg = loaded - toLoad;

      const currRow: DeviceEventLoadSummed = {
        ...event,
        diferenciaKg,
        porcentajePrecio: redondearDosDecimales(price * diferenciaKg),
        porcentajeKg,
        kgTeoricoMS: toLoad * (ms / 100),
        kgCargadosMS: loaded * (ms / 100),
        isSelected: !!(action !== ACTION_LOAD_MANUAL && addsToStock),
        isAccionManual: action === ACTION_LOAD_MANUAL,
        rowType: 'value',
        semaforo: [
          {
            key: 'porcentajeKg',
            isWarning: Math.abs(diferenciaKg) > 10 && Math.abs(porcentajeKg) > 5 && Math.abs(porcentajeKg) < 15,
            isDanger: Math.abs(diferenciaKg) > 10 && Math.abs(porcentajeKg) >= 15,
          },
        ],
      };

      let currSubsumaRow = shouldAddRow ? sumRowAttributes(currSubsuma, currRow) : currSubsuma;

      if (isFinReceta) {
        currSubsuma = newAttributesToSumRow();
      }
      currTotal = shouldAddRow ? sumRowAttributes(currTotal, currRow) : currTotal;

      return [
        ...acc,
        ...(shouldAddRow ? [currRow] : []),
        ...(isFinReceta
          ? [
              {
                ...currSubsumaRow,
                user: userValues,
                recipe: recipeValues,
                isAnticipated: endRecipe === 'yesAnticipated',
              },
            ]
          : []),
        ...(index === loadData.length - 1
          ? [
              {
                ...currTotal,
                user: userValues,
                recipe: recipeValues,
              },
            ]
          : []),
      ];
    }, [] as DeviceEventLoadFormatted[]),
    { user: userValues, recipe: recipeValues },
  ];
}

function sumRowAttributes(currSubsuma: DeviceEventLoadResultado, row: DeviceEventLoadSummed): DeviceEventLoadResultado {
  let newCurrSubsuma = { ...currSubsuma };
  attributesPesadasToSum.forEach((key) => {
    if (row.isSelected && row[key])
      if (row[key] >= 0) newCurrSubsuma[key][1] += row[key];
      else newCurrSubsuma[key][0] += row[key];
  });
  extraAttributesPesadasToSum.forEach((key) => {
    if (row.isSelected) {
      let extraAttributesFromRows = extraAttributesValuesFromRow(row);
      if (newCurrSubsuma.extra) newCurrSubsuma.extra[key].value += extraAttributesFromRows[key].value as number;
    }
  });
  return newCurrSubsuma;
}
