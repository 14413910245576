import { Check, Close, PriorityHigh } from '@material-ui/icons';
import {
  customBodyRenderNumberValue,
  customBodyRenderSelectedColumn,
  customBodyRenderSummedAttribute,
} from 'components/table/TableSubsum';
import { customBodyRenderDate } from 'utils/helpers/body-renders';

export const columnsReporteCargas = [
  {
    name: 'isSelected',
    label: 'Sumar',
    options: {
      filter: false,
      sort: false,
      viewColumns: false,
      customBodyRender: customBodyRenderSelectedColumn,
    },
  },
  {
    name: 'date',
    label: 'Fecha',
    options: {
      filter: false,
      sort: false,
      viewColumns: false,
      customBodyRender: customBodyRenderDate,
    },
  },
  {
    name: 'state',
    label: 'Estado',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value) => {
        if (value === 'success')
          return (
            <span className="bg-green-700 text-white rounded-full p-1 py-2">
              <Check />
            </span>
          );
        if (value === 'warning')
          return (
            <span className="bg-yellow-600 text-white rounded-full p-1 py-2">
              <PriorityHigh />
            </span>
          );
        if (value === 'danger')
          return (
            <span className="bg-danger-600 text-white rounded-full p-1 py-2">
              <Close />
            </span>
          );

        return null;
      },
    },
  },
  {
    name: 'ingredient',
    label: 'Ingrediente',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'toLoad',
    label: 'Kg Teorico',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderSummedAttribute,
    },
  },
  {
    name: 'loaded',
    label: 'Kg Cargados',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderSummedAttribute,
    },
  },
  {
    name: 'porcentajeKg',
    label: '%Kg',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderNumberValue,
    },
  },
  {
    name: 'diferenciaKg',
    label: 'Diferencia Kg',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderSummedAttribute,
    },
  },
];
