import FormCard, { FormCardIcon } from 'design-system/form-card';
import { IconBarChart } from 'design-system/icon';
import { useMemo } from 'react';
import { CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useDispersionRecetas } from './useDispersionRecetas';

export const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="flex flex-col p-4 bg-white/100 rounded-md">
        <p className="text-black">{label}</p>
        {payload.map((item: any) => (
          <p
            key={item.name}
            className="font-bold"
            style={{
              color: item.stroke || item.fill || item.color,
            }}
          >
            <>
              Lote {item.dataKey}: {item.value} Kg
            </>
          </p>
        ))}
      </div>
    );
  }

  return null;
};

// Make a 15 color palette of primary and secondary colors
const COLORES = [
  '#FFC107',
  '#FF9800',
  '#FF5722',
  '#F44336',
  '#E91E63',
  '#9C27B0',
  '#673AB7',
  '#3F51B5',
  '#2196F3',
  '#03A9F4',
  '#00BCD4',
  '#009688',
  '#4CAF50',
  '#8BC34A',
  '#CDDC39',
  '#FFEB3B',
  '#FFC107',
  '#FF9800',
  '#FF5722',
  '#F44336',
  '#E91E63',
  '#9C27B0',
  '#673AB7',
];

export const DispersionRecetasChart = () => {
  const data = useDispersionRecetas();

  // Reduce el array de datos a un array de objetos con la fecha y el total de kg cargados por receta en ese día + payload (datos originales para ese dia)
  const dataLoadedStats = useMemo(
    () =>
      data.map((point: any) => ({
        date: point.date,
        payload: point,
        ...point.data.reduce((acc: any, item: any) => {
          acc[item.recipe] = item.data.reduce((acc: any, item: any) => {
            acc += item.loaded ? Number(item.loaded) : 0;
            return acc;
          }, 0);

          return acc;
        }, {}),
      })),
    [data]
  );

  // Set de recetas en dataLoadedStats (cada uno será una linea )
  const lines = [
    ...new Set(
      dataLoadedStats.flatMap((item: any) =>
        Object.keys(item).filter((key: string) => key !== 'date' && key !== 'payload')
      ) as string[]
    ),
  ];

  return (
    <div className="content-view px-8 py-8">
      <FormCard
        title="Dispersiones Lotes (3 días)"
        icon={
          <FormCardIcon className="px-2 py-2">
            <IconBarChart />
          </FormCardIcon>
        }
        items={[
          {
            value: (
              <div className="w-full h-[70vh] relative">
                <ResponsiveContainer width="100%" height="100%">
                  <ComposedChart
                    width={500}
                    height={300}
                    data={dataLoadedStats}
                    margin={{
                      top: 20,
                      right: 0,
                      left: 0,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" opacity={0.5} />
                    <XAxis dataKey="date" />
                    <YAxis yAxisId="left" orientation="left" />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend verticalAlign="top" />
                    {
                      // Create a line for each recetas
                      lines.map((line: string, index: number) => (
                        <Line type="monotone" yAxisId="left" dataKey={line} stroke={COLORES[index % COLORES.length]} />
                      ))
                    }
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};
