export const columnsStockHistorico = [
  {
    name: 'name',
    label: 'Ingrediente',
    options: { filter: true, sort: false },
  },
  {
    name: 'toLoad',
    label: 'A cargar (kg)',
    options: { filter: false, sort: false },
  },
  {
    name: 'loaded',
    label: 'Cargado (kg)',
    options: { filter: false, sort: false },
  },
  {
    name: 'totalPriceToLoad',
    label: 'Total $ a Cargar',
    options: { filter: false, sort: false },
  },
  {
    name: 'totalPriceLoaded',
    label: 'Total $ Cargado',
    options: { filter: false, sort: false },
  },
  {
    name: 'diferenciaKg',
    label: 'Diferencia Kg',
    options: { filter: false, sort: false },
  },
  {
    name: 'diferenciaPrice',
    label: 'Diferencia $',
    options: { filter: false, sort: false },
  },
  {
    name: 'porcentajeError',
    label: '% Error',
    options: { filter: false, sort: false },
  },
  {
    name: 'price',
    label: 'Precio Kg ($)',
    options: { filter: false, sort: false },
  },
  {
    name: 'stock',
    label: 'Existencias (Kg)',
    options: { filter: false, sort: false },
  },
  {
    name: 'diasRestantesAlimento',
    label: 'Días restantes alimento Teórico',
    options: { filter: false, sort: false },
  },
  {
    name: 'diasRestantesAlimentoPromedio',
    label: 'Días restantes alimento promedio',
    options: { filter: false, sort: false },
  },
];
