import { FullScreenLoader } from 'components/loader';
import ViewHeader from 'design-system/view-header';
import { useCalendarQuery } from 'hooks/useCalendarQuery';
import { useGetDeviceLoadDataQuery } from 'store/devices/devicesApi';
import { AccionesChart } from './Acciones/AccionesChart';
import { DispersionDescargasChart } from './DispersionDescargas/DispersionDescargasChart';
import { DispersionRecetasChart } from './DispersionRecetas/DispersionRecetasChart';

function ReportesSip() {
  const { isFetching: isDeviceLoadDataFetching } = useCalendarQuery(useGetDeviceLoadDataQuery);

  const isLoading = isDeviceLoadDataFetching;

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <ViewHeader title="Reportes" />
      <AccionesChart />
      <DispersionDescargasChart />
      <DispersionRecetasChart />
    </>
  );
}

export default ReportesSip;
