import Icon from 'components/icons';
import UserBadge from 'components/user-badge';
import { ButtonLogout } from 'design-system/button';
import { Logo } from 'design-system/logo';
import {
  SidebarGroup,
  SidebarHeader,
  SidebarLogo,
  SidebarRoute,
  SidebarSection,
} from 'design-system/sidebar';
import { TextHeading, TextLink } from 'design-system/text';
import { useRouting } from 'hooks/useRouting';
import React, { Fragment } from 'react';
import { useHistory } from 'react-router';
interface AppNavigationSidebarProps {
  isOpen?: boolean;
  onClose?: () => any;
  onLogout?: () => any;
}

const AppNavigationSidebar: React.FC<AppNavigationSidebarProps> = ({
  isOpen,
  onClose,
  onLogout,
}) => {
  let history = useHistory();
  const onLogoClick = () => {
    history.push('/');
    onClose?.();
  };
  const { routes } = useRouting();

  const handleClose = (event: any) => {
    event.stopPropagation();
    onClose?.();
  };

  return (
    <div
      className={`app-navigation-sidebar ${
        isOpen ? 'active' : ''
      } border-r border-[rgba(255,255,255,0.1)]`}
      data-testid="app-navigation-sidebar"
    >
      <SidebarLogo onClick={onLogoClick}>
        <Logo />
        <button onClick={handleClose}>
          <Icon name="clear" />
        </button>
      </SidebarLogo>
      <SidebarSection>
        <UserBadge />
      </SidebarSection>
      <SidebarGroup>
        {routes.map(
          (
            { subgroup = '', links = [] }: { subgroup: string; links: any[] },
            idx
          ) => (
            <Fragment key={`route-${idx}`}>
              <SidebarHeader>
                <TextHeading>{subgroup}</TextHeading>
              </SidebarHeader>
              {links.map(
                ({ label, link, onClick, purpose, Icon }: any, idx) => (
                  <Fragment key={`route-link-${idx}`}>
                    {idx === 0 ||
                      (purpose !== links[idx - 1].purpose && (
                        <div
                          className="space-divider"
                          key={`separator=${idx}`}
                        />
                      ))}
                    <SidebarRoute>
                      <TextLink
                        key={`navlink-${idx}`}
                        to={link}
                        exact={link === '/'}
                        onClick={() => {
                          onClick();
                          onClose?.();
                        }}
                      >
                        {Icon && (
                          <span className="w-4 h-4 mr-2">
                            <Icon />
                          </span>
                        )}
                        <span>{label}</span>
                      </TextLink>
                    </SidebarRoute>
                  </Fragment>
                )
              )}
            </Fragment>
          )
        )}
      </SidebarGroup>
      <SidebarSection>
        <ButtonLogout onClick={onLogout}>
          Cerrar sesión
          <Icon name="logout" size="s" />
        </ButtonLogout>
      </SidebarSection>
    </div>
  );
};

export default AppNavigationSidebar;
