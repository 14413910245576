import Input from 'components/input';
import { ChipInfo } from 'design-system/chip/chip-info';
import Select from 'design-system/select';
import { TableRowTwoCols } from 'design-system/table/table-row';
import { memo, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectDeviceSelected } from 'store/devices/devicesSlice';
import { UnloadGuide } from 'utils/device/lot';
import { VERSION, isDeviceVersionAtLeast } from 'utils/helpers/validation';

const TIPO_DESCARGA_OPTIONS = [
  {
    name: 'Seleccion Manual',
    value: 'manual',
  },
  {
    name: 'Automatica',
    value: 'automatica',
  },
  {
    name: 'Sin especificar',
    value: 'Sin especificar',
  },
];

const SOBRANTE_OPTIONS = [
  {
    name: 'Si',
    value: 'si',
  },
  {
    name: 'No',
    value: 'no',
  },
];

const TURNO_OPTIONS = [
  {
    name: 'Mañana',
    value: 'mañana',
  },
  {
    name: 'Tarde',
    value: 'tarde',
  },
  {
    name: 'Noche',
    value: 'noche',
  },
  {
    name: 'Sin especificar',
    value: 'Sin especificar',
  },
];

const DescargaForm = ({ descarga, editingEnabled }: { descarga?: UnloadGuide; editingEnabled?: boolean }) =>
  editingEnabled ? <DescargaFormEditable /> : descarga ? <DescargaFormBase descarga={descarga} /> : null;

export default memo(DescargaForm);

function DescargaFormEditable() {
  const { watch } = useFormContext<UnloadGuide>();
  const [id, name, associatedRecipe, unloadType, unloadSchedule, leftoverFood, transactionNumber] = watch([
    'id',
    'name',
    'associatedRecipe',
    'unloadType',
    'unloadSchedule',
    'leftoverFood',
    'transactionNumber',
  ]);
  return (
    <DescargaFormBase
      descarga={{
        id,
        name,
        associatedRecipe,
        unloadType,
        unloadSchedule,
        leftoverFood,
        transactionNumber,
      }}
      editingEnabled
    />
  );
}

function DescargaFormBase({ descarga, editingEnabled }: { descarga: Partial<UnloadGuide>; editingEnabled?: boolean }) {
  const methods = useFormContext<UnloadGuide>();
  const { setValue } = methods || {};

  const deviceSelected = useSelector(selectDeviceSelected);
  const isDeviceVersionTwo = isDeviceVersionAtLeast(VERSION.TWO, deviceSelected);
  const isDeviceVersionFive = isDeviceVersionAtLeast(VERSION.FIVE, deviceSelected);
  const isDeviceVersionSix = isDeviceVersionAtLeast(VERSION.SIX, deviceSelected);

  const RECIPE_OPTIONS = useMemo(
    () =>
      (deviceSelected?.recipes || []).map((recipe) => ({
        name: recipe.name,
        value: `${recipe.id}`,
      })),
    [deviceSelected?.recipes]
  );

  const showSelectID = !!descarga?.id;

  return (
    <div className="flex flex-col gap-2">
      <TableRowTwoCols>
        <div className={!showSelectID ? 'w-full mobile:col-span-2' : 'w-full'}>
          <Input
            label={'Nombre'}
            placeholder="Nombre"
            defaultValue={descarga?.name || ''}
            onBlur={(value: string) => {
              setValue?.('name', value || '');
            }}
            disabled={!editingEnabled}
            maxLength={isDeviceVersionFive ? 25 : 14}
          />
        </div>
        {showSelectID && (
          <div className="mb-1 w-full">
            <ChipInfo>ID de descarga {descarga?.id}</ChipInfo>
          </div>
        )}
      </TableRowTwoCols>
      <TableRowTwoCols>
        {isDeviceVersionTwo && (
          <Select
            name="Receta asociada"
            label="Receta asociada"
            placeholder="Sin receta asociada"
            placeholderEnabled
            options={RECIPE_OPTIONS}
            onChangeValue={(value: string) => {
              setValue?.('associatedRecipe', Number(value));
            }}
            value={`${descarga?.associatedRecipe || 0}`}
            disabled={!editingEnabled}
          />
        )}
        {isDeviceVersionFive && (
          <Select
            name="Tipo de Descarga"
            label="Tipo de Descarga"
            options={TIPO_DESCARGA_OPTIONS}
            onChangeValue={(value: string) => {
              setValue?.('unloadType', value);
            }}
            value={`${descarga?.unloadType || 'Sin especificar'}`}
            disabled={!editingEnabled}
          />
        )}
      </TableRowTwoCols>
      <TableRowTwoCols>
        {isDeviceVersionFive && (
          <Select
            name="Sobrante"
            label="Sobrante"
            options={SOBRANTE_OPTIONS}
            onChangeValue={(value: string) => {
              setValue?.('leftoverFood', value);
            }}
            value={`${descarga?.leftoverFood || 'no'}`}
            disabled={!editingEnabled}
          />
        )}
        {isDeviceVersionFive && (
          <Select
            name="Turno"
            label="Turno"
            options={TURNO_OPTIONS}
            onChangeValue={(value: string) => {
              setValue?.('unloadSchedule', value);
            }}
            value={`${descarga?.unloadSchedule || 'Sin especificar'}`}
            disabled={!editingEnabled}
          />
        )}
        {/* {isDeviceVersionSix && (
          <Input
            label={'Número de transacción'}
            placeholder="Número de transacción"
            defaultValue={descarga?.transactionNumber || ''}
            onBlur={(value: string) => {
              setValue?.('transactionNumber', parseInt(value || ''));
            }}
            disabled={!editingEnabled}
            maxLength={25}
            type={'number'}
          />
        )} */}
      </TableRowTwoCols>
    </div>
  );
}
