import Icon from 'components/icons';
import { useAuth } from 'hooks/useAuth';

interface UserBadgeProps {
  onClick?: () => any;
}

function UserBadge({ onClick }: UserBadgeProps) {
  const {
    loggedUser: { user },
  } = useAuth();
  return (
    <div className="user-badge-wrapper" onClick={onClick}>
      <Icon name="user" size="s" />
      <span>{user?.username}</span>
    </div>
  );
}

export default UserBadge;
