import {
  TableSubsumaResultadoRow,
  TableSubsumaRowValue,
} from 'components/table/TableSubsum';
import parseDataReceta, {
  RecipeIngredientFormatted,
} from 'screens/creador-recetas/tabla-receta/parser';
import { Recipe } from 'utils/device/event/diet';
import { Lot, UnloadGuide } from 'utils/device/lot';
import { redondearDosDecimales } from 'utils/helpers/math';

type AttributeToSum = 'kgLot' | 'kgLotMV';
type ExtraAttributes = any;

export type UnloadGuideSummed = Lot &
  TableSubsumaRowValue & {
    percentage: number;
    kgLot: number;
    kgLotMV: number;
    elaborationPercentage: number;
  };

type UnloadGuideResultado = TableSubsumaResultadoRow<
  AttributeToSum,
  ExtraAttributes
>;

export type UnloadGuideFormatted = UnloadGuideSummed | UnloadGuideResultado;

const attributesDescargaToSum: AttributeToSum[] = ['kgLot', 'kgLotMV'];

function newAttributesToSumRow(isFinal: boolean = false): UnloadGuideResultado {
  return {
    ...attributesDescargaToSum.reduce(
      (acc, curr): UnloadGuideResultado => ({
        ...acc,
        [curr]: [, 0] as unknown as [number, number],
      }),
      {
        rowType: 'resultado',
        isResultadoFinal: isFinal,
      } as UnloadGuideResultado
    ),
  };
}

export default function parseDataDescargas(
  data: UnloadGuide & { associatedRecipeData?: Recipe },
  withTotal?: boolean
): UnloadGuideFormatted[] {
  // Filtrar alimentos nuevos que no se hayan guardado y se hayan eliminado,
  // o sea que en el medio de la edicion de la receta se saco el alimento nuevo
  const filteredLots =
    data?.lots?.filter((lot) => !(lot.nuevo && lot.deleted)) || [];

  // Obtener factor de corrección de la receta asociada
  const parsedAssociatedRecipeData = data?.associatedRecipeData
    ? parseDataReceta(data?.associatedRecipeData)
    : [];
  let factorCorreccion =
    getRecetaMVTotal(parsedAssociatedRecipeData) /
    getRecetaMSTotal(parsedAssociatedRecipeData);
  if (!isFinite(factorCorreccion)) factorCorreccion = 1;

  const totalKgHeads = filteredLots
    .filter((lot) => !lot.deleted)
    .reduce(function (accumulated, current) {
      const elaborationPercentage =
        (data.lotsMetadata || {})[current.id]?.elaborationPercentage || 100;
      const kgLot =
        (current.headsCount * (current.kgHeads || 1) * elaborationPercentage) /
        100;
      return accumulated + kgLot;
    }, 0);
    
  let currTotal = newAttributesToSumRow(true);

  return filteredLots.reduce((acc, currLot, index): UnloadGuideFormatted[] => {
    const elaborationPercentage =
      (data.lotsMetadata || {})[currLot.id]?.elaborationPercentage || 100;
    const kgLot =
      (currLot.headsCount * (currLot.kgHeads || 1) * elaborationPercentage) /
      100;
    const kgLotMV = redondearDosDecimales(kgLot * factorCorreccion);
    const percentage = redondearDosDecimales(kgLot / totalKgHeads);

    const currRow: UnloadGuideFormatted = {
      ...currLot,
      percentage,
      kgLot,
      orden: index + 1,
      kgLotMV,
      elaborationPercentage,
      rowType: 'value',
    };

    currTotal = sumRowAttributes(currTotal, currRow);

    return [
      ...acc,
      currRow,
      ...(index === filteredLots.length - 1 && withTotal
        ? [
            {
              ...currTotal,
            },
          ]
        : []),
    ];
  }, [] as UnloadGuideFormatted[]);
}

function sumRowAttributes(
  currSubsuma: UnloadGuideResultado,
  row: UnloadGuideSummed
): UnloadGuideResultado {
  let newCurrSubsuma = { ...currSubsuma };
  attributesDescargaToSum.forEach((key) => {
    if (row[key]) newCurrSubsuma[key][1] += row[key];
  });
  return newCurrSubsuma;
}

function getRecetaMVTotal(recetaData: RecipeIngredientFormatted[]): number {
  if (
    recetaData.length > 0 &&
    recetaData[recetaData.length - 1].rowType === 'resultado'
  ) {
    let lastRow = recetaData[recetaData.length - 1];
    return Array.isArray(lastRow.kgMV)
      ? (lastRow.kgMV as Array<number>)[1]
      : (lastRow.kgMV as number);
  }
  return 0;
}

function getRecetaMSTotal(recetaData: RecipeIngredientFormatted[]): number {
  if (
    recetaData.length > 0 &&
    recetaData[recetaData.length - 1].rowType === 'resultado'
  ) {
    let lastRow = recetaData[recetaData.length - 1];
    return Array.isArray(lastRow.kgMS)
      ? (lastRow.kgMS as Array<number>)[1]
      : (lastRow.kgMS as number);
  }
  return 0;
}
