import {
  customBodyRenderNumberValue,
  customBodyRenderSelectedColumn,
  customBodyRenderValueTotalColumn,
} from 'components/table/TableSubsum';
import { customBodyRenderPercentage } from 'utils/helpers/body-renders';

export const columnsTablaCreadorDescargas = [
  {
    name: 'id',
    label: 'ID',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderValueTotalColumn,
    },
  },
  {
    name: 'name',
    label: 'Lote',
    options: { filter: false, sort: true },
  },
  {
    name: 'percentage',
    label: '%',
    options: {
      filter: false,
      sort: true,
      customBodyRender: customBodyRenderPercentage,
    },
  },
];

export const columnsTablaCreadorDescargasV2 = [
  {
    name: 'orden',
    label: 'Orden',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderSelectedColumn,
    },
  },
  {
    name: 'id',
    label: 'ID',
    options: {
      filter: false,
      sort: false,
    },
  },
  ...columnsTablaCreadorDescargas.slice(1, 2),
  {
    name: 'kgLot',
    label: 'Kg Lote MS',
    options: {
      filter: false,
      sort: true,
      customBodyRender: customBodyRenderNumberValue,
    },
  },
  {
    name: 'kgLotMV',
    label: 'Kg Lote MV',
    options: {
      filter: false,
      sort: true,
      customBodyRender: customBodyRenderNumberValue,
    },
  },
  {
    name: 'elaborationPercentage',
    label: '% Elaboracion Lote',
    options: { filter: false, sort: true },
  },
  ...columnsTablaCreadorDescargas.slice(2),
];
