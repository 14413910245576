import { DeviceType } from 'utils/device';
import { DeviceEventBase } from 'utils/device/event';

export const ACTION_ITH = 'actualState';

export type DeviceDataEventITH = DeviceEventITH;

type DeviceDataEventActionType = typeof ACTION_ITH;

export interface DeviceEventITH extends DeviceEventBase {
  action: typeof ACTION_ITH;
  temperature: number;
  humidity: number;
  ITH: number;
  V1: boolean;
  V2: boolean;
  V3: boolean;
  V4: boolean;
  V5: boolean;
  V6: boolean;
  V7: boolean;
  V8: boolean;
  V9: boolean;
  V10: boolean;
  V11: boolean;
  V12: boolean;
  A1: boolean;
  A2: boolean;
  A3: boolean;
  A4: boolean;
  A5: boolean;
  A6: boolean;
  A7: boolean;
  A8: boolean;
  A9: boolean;
  A10: boolean;
  A11: boolean;
  A12: boolean;
  fansON: number;
  sprinklersON: number;
}

export function sortIth(data: DeviceEventITH[], mostRecentFirst?: boolean) {
  return data
    .slice()
    .sort(
      (element1, element2) =>
        (mostRecentFirst && element1.date > element2.date && -1) ||
        (element1.date < element2.date && -1) ||
        1
    );
}
