import { customBodyRenderDate, customBodyRenderDateToLocal } from 'utils/helpers/body-renders';

export const columnsMensajesControlGac = [
  {
    name: 'date',
    label: 'Fecha',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderDateToLocal,
    },
  },
  {
    name: 'creationTime',
    label: 'Hora guardada',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderDate,
    },
  },
  {
    name: 'temp',
    label: 'Temperatura',
    options: { filter: false, sort: false },
  },
  {
    name: 'desFuenCaudal',
    label: 'desFuenCaudal',
    options: { filter: false, sort: false },
  },
  {
    name: 'desSensCaudal',
    label: 'desSensCaudal',
    options: { filter: false, sort: false },
  },
  { label: 'Litros descargados', name: 'ltTotalsDownloaded', options: { filter: false, sort: false } },
  {
    label: 'Litros disponibles',
    name: 'ltAvaiableTank',
    options: { filter: false, sort: false },
  },
  {
    name: 'patronCal',
    label: 'patronCal',
    options: { filter: false, sort: false },
  },
  {
    name: 'nASUC',
    label: 'nASUC',
    options: { filter: false, sort: false },
  },
  {
    name: 'idLA',
    label: 'idLA',
    options: { filter: false, sort: false },
  },
  {
    name: 'pASUC',
    label: 'pASUC',
    options: { filter: false, sort: false },
  },
  {
    label: 'Display',
    name: 'estadoDisplay',
    options: { filter: false, sort: false },
  },
];
