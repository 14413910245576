import AlertDialog from 'components/alert-dialog';
import Input from 'components/input';
import ChipToggle from 'design-system/chip-toggle';
import { ChipQuestionMark } from 'design-system/chip/chip-question-mark';
import { ChipSuccess } from 'design-system/chip/chip-success';
import FormCard from 'design-system/form-card';
import Select from 'design-system/select';
import SidebarSlide from 'design-system/sidebar-slide';
import { TableRowTwoCols } from 'design-system/table/table-row';
import { useModal } from 'hooks/useModal';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetDeviceTasksQuery, useUpdateLotMutation } from 'store/devices/devicesApi';
import { selectDeviceSelected } from 'store/devices/devicesSlice';
import { Lot } from 'utils/device/lot';
import { mutateWithToast } from 'utils/helpers/async/toast.helpers';
import { VERSION, isDeviceVersionAtLeast, isTrue } from 'utils/helpers/validation';

const SELECT_OPTIONS_CATEGORIA_LOTE = [
  { name: 'Secas', value: 'nonProducing' },
  { name: 'Ordeñe', value: 'milking' },
  { name: 'Enfermería', value: 'infirmary' },
  { name: 'Otros', value: 'other' },
];

function ModalEditarLote({ onClose = () => {} }: { onClose: () => void }) {
  const deviceSelected = useSelector(selectDeviceSelected);
  const isDeviceV2 = isDeviceVersionAtLeast(VERSION.TWO, deviceSelected);
  const isDeviceVersionFive = isDeviceVersionAtLeast(VERSION.FIVE, deviceSelected);
  const isDeviceVersionSix = isDeviceVersionAtLeast(VERSION.SIX, deviceSelected);
  const lots = useMemo(() => deviceSelected?.lots || [], [deviceSelected]);
  const [loteEditing, setLoteEditing] = useState<Lot>({} as Lot);

  const {
    id: loteId,
    name,
    headsCount,
    kgHeads,
    associatedMilkerIndex,
    milkerUpdatesHeadCount,
    category,
  } = loteEditing || {};

  const [newName, setNewName] = useState('');
  const [isOpenEliminarLote, onOpenEliminarLote, onCloseEliminarLote] = useModal();

  const [updateLot] = useUpdateLotMutation();
  const getDeviceTasks = useGetDeviceTasksQuery({ serialNumber: deviceSelected?.serialNumber || '' });

  const SELECT_OPTIONS_LOTE = useMemo(
    () =>
      lots.map(({ name }) => ({
        name,
        value: name,
      })),
    [lots]
  );

  const onSave = async () => {
    if (deviceSelected?.serialNumber) {
      const promise = updateLot({
        serialNumber: deviceSelected?.serialNumber,
        lot: { ...loteEditing, name: newName },
      });

      const onSuccess = () => {
        getDeviceTasks.refetch();
        onClose();
      };

      await mutateWithToast(promise, onSuccess, {
        pending: 'Guardando lote...',
        success: 'Lote guardado correctamente.',
        error: 'Error al guardar el lote.',
      });
    }
  };

  const onEliminarLote = () => {
    // eliminarLote({
    //   stock: stockId,
    //   name,
    // });
    onCloseEliminarLote();
    onClose();
  };

  const canGuardar = name !== '';

  useEffect(() => {
    if (lots.length > 0) {
      setLoteEditing(lots[0]);
      setNewName(lots[0].name);
    }
  }, [lots]);

  const onSelectLote = (value: string) => {
    const loteSelected = lots.find((lote) => lote.name === value);
    if (loteSelected) {
      setLoteEditing({
        ...loteSelected,
      });
      setNewName(loteSelected.name);
    }
  };

  const onNewHeadsCount = (value: string) =>
    setLoteEditing({
      ...loteEditing,
      headsCount: Number(value),
    });

  const onNewKgHeadsCount = (value: string) =>
    setLoteEditing({
      ...loteEditing,
      kgHeads: Number(value),
    });

  const onNewAssociatedMilkerIndex = (value: string) =>
    setLoteEditing({
      ...loteEditing,
      associatedMilkerIndex: Number(value),
    });

  const onChangeMilkerUpdatesHeadCount = (value: boolean) =>
    setLoteEditing({
      ...loteEditing,
      milkerUpdatesHeadCount: value,
    });

  const onNuevoNombre = (value: string) => setNewName(value);

  const onNuevaCategoria = (value: string) =>
    setLoteEditing({
      ...loteEditing,
      category: value,
    });

  return (
    <>
      <AlertDialog
        isOpen={isOpenEliminarLote}
        onClose={onCloseEliminarLote}
        title="Eliminar Lote"
        onPositiveClick={onEliminarLote}
        positiveLabel="Eliminar"
      >
        ¿Está seguro que desea eliminar el lote? Esta acción no se puede deshacer.
      </AlertDialog>
      <SidebarSlide
        isOpen
        onClose={onClose}
        title={'Editar lote'}
        actions={[
          {
            autoFocus: false,
            onClick: onOpenEliminarLote,
            text: 'Eliminar',
            variant: 'text-danger',
            disabled: !!loteId && loteId !== null,
            tooltip: loteId ? 'No pueden eliminarse lotes que estén en balanza.' : '',
          },
          {
            autoFocus: false,
            onClick: onSave,
            text: 'Guardar',
            disabled: !canGuardar,
            variant: 'fill',
          },
        ]}
      >
        <FormCard
          items={[
            {
              value: (
                <TableRowTwoCols className="mobile:flex">
                  <Select
                    name="Lote"
                    label="Lote"
                    placeholder="Lote"
                    options={SELECT_OPTIONS_LOTE}
                    onChangeValue={onSelectLote}
                    value={name}
                  />
                  <div className="mb-1">
                    {loteId ? (
                      <ChipSuccess>Lote guardado en balanza con ID {loteId ? loteId : '-'}.</ChipSuccess>
                    ) : (
                      <ChipQuestionMark>El lote no está guardado en la balanza.</ChipQuestionMark>
                    )}
                  </div>
                </TableRowTwoCols>
              ),
            },
            ...(!!loteId
              ? [
                  {
                    value: (
                      <Input
                        placeholder="Lote"
                        label="Nombre"
                        value={newName || ''}
                        onChange={onNuevoNombre}
                        maxLength={isDeviceVersionFive ? 25 : 14}
                      />
                    ),
                  },
                ]
              : []),
            {
              value: (
                <Input
                  placeholder="Nº Cabezas"
                  label="Nº Cabezas"
                  value={headsCount || ''}
                  type="number"
                  onChange={onNewHeadsCount}
                />
              ),
            },
            ...(isDeviceV2
              ? [
                  {
                    value: isDeviceV2 && (
                      <Input
                        placeholder="kg/Cabezas MS"
                        label="kg/Cabezas MS"
                        value={kgHeads || ''}
                        type="number"
                        onChange={onNewKgHeadsCount}
                      />
                    ),
                  },
                ]
              : []),
            ...(isDeviceVersionSix
              ? [
                  {
                    value: isDeviceVersionSix && (
                      <Input
                        placeholder="Identificador Ordeñadora"
                        label="Identificador Ordeñadora"
                        value={associatedMilkerIndex || ''}
                        type="number"
                        onChange={onNewAssociatedMilkerIndex}
                      />
                    ),
                  },
                  {
                    value: isDeviceVersionSix && (
                      <Select
                        name="Categoría"
                        label="Categoría"
                        placeholder="Sin categoría"
                        options={SELECT_OPTIONS_CATEGORIA_LOTE}
                        onChangeValue={onNuevaCategoria}
                        value={category}
                      />
                    ),
                  },
                ]
              : []),
            ...(isDeviceVersionSix
              ? [
                  {
                    value: isDeviceVersionSix && (
                      <ChipToggle
                        label="Modificar lote automáticamente"
                        onToggle={onChangeMilkerUpdatesHeadCount}
                        toggleValue={isTrue(milkerUpdatesHeadCount)}
                        noBorder
                      />
                    ),
                  },
                ]
              : []),
          ]}
        />
      </SidebarSlide>
    </>
  );
}

export default ModalEditarLote;
