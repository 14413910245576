import { useCustomCalendarQuery } from 'hooks/useCalendarQuery';
import { useMemo } from 'react';
import parseLecturaComederos from 'screens/lectura-comederos/parser';
import { useGetDeviceDataQuery } from 'store/devices/devicesApi';
import { today, twoDaysAgo } from 'utils/helpers';
import { customBodyRenderDate } from 'utils/helpers/body-renders';

export const useThreeDayLecturaComederos = () => {
  const { data: deviceData = [] } = useCustomCalendarQuery(useGetDeviceDataQuery, twoDaysAgo, today);
  const dataLecturaComederos = useMemo(() => parseLecturaComederos(deviceData), [deviceData]);
  const lecturaComederosPoints = useMemo(
    () =>
      dataLecturaComederos.map((item) => ({
        ...item,
        tipo: 'LecturaComederos',
        formattedDate: customBodyRenderDate((item as any).date),
      })),
    [dataLecturaComederos]
  );

  return lecturaComederosPoints || [];
};
