import { columnsAdminDevices } from './admin-devices';
import { columnsAdminUsers } from './admin-users';
import { columnsAfimilk } from './afimilk';
import { columnsCasHistorico } from './cas-historico';
import { columnsCmasHistorico } from './cmas-historico';
import { columnsTablaCreadorDescargas, columnsTablaCreadorDescargasV2 } from './creador-descargas';
import { columnsDescargas, columnsDescargasV5 } from './descargas';
import { columnsGACHistorico } from './gac-historico';
import { columnsGacUsers } from './gac-users';
import { columnsGacVehicles } from './gac-vehicles';
import { columnsHistoricoDescargas, columnsHistoricoDescargasV2 } from './historico-descargas';
import { columnsHistoricoDietas } from './historico-dietas';
import { columnsHistoricoIngredientes } from './historico-ingredientes';
import { columnsHistoricoLotes } from './historico-lotes';
import { columnsITHHistorico } from './ith-historico';
import { columnsLecturaComederos } from './lectura-comederos';
import { columnsLotes, columnsLotesV2, columnsLotesV6 } from './lotes';
import { columnsMensajesControl, columnsMensajesControlV5 } from './mensajes-control';
import { columnsMensajesControlGac } from './mensajes-control-gac';
import { columnsMezclado } from './mezclado';
import { columnsPesadas, columnsPesadasV5 } from './pesadas';
import { columnsTablaRecetaPorLote } from './receta-por-lote';
import { columnsRegistroEventos } from './registro-eventos';
import { columnsReporteCargas } from './reporte-cargas';
import { columnsDelpro } from './delpro';
import { columnsReporteLotes, columnsReporteLotesV2, columnsReporteLotesWithLC } from './reporte-lotes';
import { columnsStock } from './stock';
import { columnsStockHistorico } from './stock-historico';
import { columnsTablaAlimentos } from './tabla-alimentos';
import { columnsTablaCreadorRecetas } from './tabla-receta';
import { columnsTablaConsumosLote } from './tabla-consumos-lote';

const tableColumns = {
  columnsDescargas,
  columnsDescargasV5,
  columnsMezclado,
  columnsPesadas,
  columnsPesadasV5,
  columnsStock,
  columnsLotes,
  columnsLotesV2,
  columnsLotesV6,
  columnsCmasHistorico,
  columnsITHHistorico,
  columnsGACHistorico,
  columnsStockHistorico,
  columnsTablaAlimentos,
  columnsTablaCreadorRecetas,
  columnsHistoricoIngredientes,
  columnsRegistroEventos,
  columnsMensajesControl,
  columnsMensajesControlV5,
  columnsMensajesControlGac,
  columnsLecturaComederos,
  columnsHistoricoDietas,
  columnsHistoricoDescargas,
  columnsHistoricoDescargasV2,
  columnsHistoricoLotes,
  columnsTablaCreadorDescargas,
  columnsTablaCreadorDescargasV2,
  columnsAdminUsers,
  columnsAdminDevices,
  columnsGacUsers,
  columnsGacVehicles,
  columnsCasHistorico,
  columnsReporteCargas,
  columnsReporteLotes,
  columnsReporteLotesV2,
  columnsReporteLotesWithLC,
  columnsAfimilk,
  columnsTablaRecetaPorLote,
  columnsDelpro,
  columnsTablaConsumosLote,
};

export default tableColumns;
