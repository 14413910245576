import Button from 'components/button';
import { HAASTEN_LIGHT, SECONDARY_ACCENT } from 'components/colors';
import Input from 'components/input';
import { FullScreenLoader } from 'components/loader';
import Select from 'design-system/select';
import { TextHeading } from 'design-system/text';
import ViewHeader from 'design-system/view-header';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import parseDataCreadorDescargas from 'screens/creador-descargas/tabla-descarga/parser';
import { selectDescargas } from 'selectors/descargas.selectors';
import { useUpdateLotFeederStateMutation } from 'store/devices/devicesApi';
import { selectDeviceSelected } from 'store/devices/devicesSlice';
import { Device } from 'utils/device';
import { Lot, UnloadGuide } from 'utils/device/lot';
import { mutateWithToast } from 'utils/helpers/async/toast.helpers';
import { redondearUnDecimales } from 'utils/helpers/math';

const TIPO_CORRECCION = [
  {
    name: 'Porcentual',
    value: 'Porcentual',
  },
  {
    name: 'Kilogramos',
    value: 'Kilogramos',
  },
  {
    name: 'Centímetros',
    value: 'Centímetros',
  },
];

const TIPO_CORRECCION_PORCENTUAL = [
  {
    name: 'Dia Entero',
    value: 'Dia Entero',
  },
  {
    name: 'Proxima Carga',
    value: 'Proxima Carga',
  },
];

const TIPO_CORRECCION_CENTIMETROS = [
  {
    name: 'Mañana',
    value: 'Mañana',
  },
  {
    name: 'Tarde',
    value: 'Tarde',
  },
  {
    name: 'Noche',
    value: 'Noche',
  },
];

const TIPO_CORRECCION_KILOGRAMOS = [
  {
    name: 'Modificar Kg Lote',
    value: 'Modificar Kg Lote',
  },
  {
    name: 'Reemplazar Kg Lote',
    value: 'Reemplazar Kg Lote',
  },
];

function LecturaComederosRemota() {
  const deviceSelected = useSelector(selectDeviceSelected);
  const descargas: UnloadGuide[] = useSelector(selectDescargas);

  const lots = useMemo(() => deviceSelected?.lots || [], [deviceSelected]);

  const parsedLots = useMemo(
    () => parseDataDescargasIntoLots(lots, descargas, deviceSelected),
    [lots, descargas, deviceSelected]
  );

  const SELECT_OPTIONS_LOTE = useMemo(
    () =>
      lots.map(({ name }) => ({
        name,
        value: name,
      })),
    [lots]
  );

  const [loteEditing, setLoteEditing] = useState<Lot>({} as Lot);
  const { name } = loteEditing || {};

  const onSelectLote = (value: string) => {
    const loteSelected = lots.find((lote) => lote.name === value);
    if (loteSelected) {
      setLoteEditing(loteSelected);
    }
  };

  // State para el valor de la corrección
  const [correctionValue, setCorrectionValue] = useState(0);

  // State para el tipo de corrección
  const [typeOfCorrection, setTypeOfCorrection] = useState('Porcentual');

  // State para el tipo de corrección porcentual
  const [correctionTypePorcentual, setCorrectionTypePorcentual] = useState('Dia Entero');

  // State para el tipo de corrección centimetros
  const [correctionTypeCentimetros, setCorrectionTypeCentimetros] = useState('Mañana');

  // State para el tipo de corrección kilogramos
  const [correctionTypeKilogramos, setCorrectionTypeKilogramos] = useState('Modificar Kg Lote');

  // State para la guia de descarga seleccionada
  const [selectedUnloadGuide, setSelectedUnloadGuide] = useState<number>(0);

  const internalOption =
    typeOfCorrection === 'Porcentual'
      ? correctionTypePorcentual
      : typeOfCorrection === 'Centímetros'
      ? correctionTypeCentimetros
      : correctionTypeKilogramos;

  // save function
  const [updateLotFeederState, { isLoading: isUpdatingLotFeederState }] = useUpdateLotFeederStateMutation();

  const onSaveCorrection = useCallback(async () => {
    if (deviceSelected?.serialNumber && loteEditing.id) {
      const promise = updateLotFeederState({
        serialNumber: deviceSelected?.serialNumber,
        lotId: loteEditing.id,
        inputValue: correctionValue,
        typeOfCorrection,
        internalOption,
        unloadGuide: selectedUnloadGuide,
      });

      const onSuccess = () => {
        // Reset values
        setCorrectionValue(0);
        setTypeOfCorrection('Porcentual');
        setCorrectionTypePorcentual('Dia Entero');
        setCorrectionTypeCentimetros('Mañana');
        setCorrectionTypeKilogramos('Modificar Kg Lote');
        setSelectedUnloadGuide(0);
        setLoteEditing({} as Lot);
      };

      // Call mutation
      await mutateWithToast(promise, onSuccess, {
        pending: 'Guardando corrección...',
        success: 'Corrección guardada correctamente.',
        error: 'Error al guardar la corrección.',
      });
    }
  }, [
    correctionValue,
    deviceSelected?.serialNumber,
    internalOption,
    loteEditing.id,
    selectedUnloadGuide,
    typeOfCorrection,
    updateLotFeederState,
  ]);

  return (
    <>
      <ViewHeader title="Lectura de Comederos Remota" />
      {isUpdatingLotFeederState && <FullScreenLoader />}
      <div className="content-view px-8">
        <div className="bg-background-black tablet:rounded-lg text-neutral-200 border border-white/10  grid">
          <div className="flex justify-between items-center px-4 py-5 tablet:px-6 border-b border-white/10 ">
            <TextHeading>Corrección de Lectura de Comedero</TextHeading>
          </div>

          <div className="px-4 py-5 tablet:px-6 bg-neutral-900">
            <div className="flex flex-col gap-6">
              <Select
                name="Lote"
                label="Lote"
                placeholder="Lote"
                options={SELECT_OPTIONS_LOTE}
                onChangeValue={onSelectLote}
                value={name}
              />

              <div className="grid sm:grid-cols-3 gap-4">
                <div
                  className="p-2 rounded-xl border flex justify-center items-center text-center "
                  style={{ borderColor: SECONDARY_ACCENT }}
                >
                  Kg por día MV: {redondearUnDecimales(parsedLots.find((lot) => lot.name === name)?.kgLotMV || 0)}
                </div>
                <div
                  className="p-2 rounded-xl border flex justify-center items-center text-center "
                  style={{ borderColor: HAASTEN_LIGHT }}
                >
                  Kg por día MS: {redondearUnDecimales(parsedLots.find((lot) => lot.name === name)?.kgLotMS || 0)}
                </div>
              </div>

              <div className="mt-4 grid gap-4">
                <Select
                  name="Guía de Descarga"
                  label="Guía de Descarga"
                  placeholder="Guía de Descarga"
                  options={[
                    {
                      name: 'Sin especificar',
                      value: '0',
                    },
                  ].concat(
                    descargas.map((descarga) => ({
                      name: descarga.name,
                      value: String(descarga.id),
                    }))
                  )}
                  onChangeValue={(value: string) => setSelectedUnloadGuide(Number(value))}
                  value={selectedUnloadGuide}
                />
              </div>

              <div className="mt-4 grid sm:grid-cols-2 gap-4">
                <Select
                  name="Tipo de Corrección"
                  label="Tipo de Corrección"
                  placeholder="Tipo de Corrección"
                  options={TIPO_CORRECCION}
                  onChangeValue={(value: string) => setTypeOfCorrection(value)}
                  value={typeOfCorrection}
                />
                {
                  // Si el tipo de corrección es porcentual
                  typeOfCorrection === 'Porcentual' && (
                    <Select
                      name="Tipo de Corrección"
                      label="Tipo de Corrección"
                      placeholder="Tipo de Corrección"
                      options={TIPO_CORRECCION_PORCENTUAL}
                      onChangeValue={(value: string) => setCorrectionTypePorcentual(value)}
                      value={correctionTypePorcentual}
                    />
                  )
                }
                {
                  // Si el tipo de corrección es centimetros
                  typeOfCorrection === 'Centímetros' && (
                    <Select
                      name="Tipo de Corrección"
                      label="Tipo de Corrección"
                      placeholder="Tipo de Corrección"
                      options={TIPO_CORRECCION_CENTIMETROS}
                      onChangeValue={(value: string) => setCorrectionTypeCentimetros(value)}
                      value={correctionTypeCentimetros}
                    />
                  )
                }
                {
                  // Si el tipo de corrección es kilogramos
                  typeOfCorrection === 'Kilogramos' && (
                    <Select
                      name="Tipo de Corrección"
                      label="Tipo de Corrección"
                      placeholder="Tipo de Corrección"
                      options={TIPO_CORRECCION_KILOGRAMOS}
                      onChangeValue={(value: string) => setCorrectionTypeKilogramos(value)}
                      value={correctionTypeKilogramos}
                    />
                  )
                }
              </div>

              <div className="flex items-end gap-2">
                <Input
                  label={`Valor (${typeOfCorrection})`}
                  placeholder="Valor"
                  defaultValue={0}
                  onChange={(value: string) => setCorrectionValue(Number(value))}
                  type={'number'}
                  key={String(isUpdatingLotFeederState)}
                />
                <span className="mb-2">
                  {
                    // Si el tipo de corrección es porcentual
                    typeOfCorrection === 'Porcentual' && <span>%</span>
                  }
                  {
                    // Si el tipo de corrección es kilogramos
                    typeOfCorrection === 'Kilogramos' && <span>kg</span>
                  }
                  {
                    // Si el tipo de corrección es centimetros
                    typeOfCorrection === 'Centímetros' && <span>cm</span>
                  }
                </span>
              </div>
            </div>
          </div>

          {/* Botones de guardar y cancelar */}

          <div className={'bg-background-blac px-4 py-3 justify-end flex gap-4 sm:px-6'}>
            <Button variant="text-danger" onClick={() => setCorrectionValue(0)} disabled={correctionValue === 0}>
              Cancelar
            </Button>

            <Button variant="fill" onClick={onSaveCorrection} disabled={correctionValue === 0 || !loteEditing.id}>
              Guardar
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default LecturaComederosRemota;

const parseDataDescargasIntoLots = (lots: Lot[], descargas: UnloadGuide[], deviceSelected: Device | null) => {
  if (!deviceSelected) return [];

  // Parse descargas: for each descarga, get its associatedRecipeData from deviceSelected. Then, run the parseDataCreadorDescargas helper function on each descarga.
  const parsedDataDescargas = descargas
    .map((descarga) => {
      const associatedRecipeData = deviceSelected?.recipes?.find((recipe) => recipe.id === descarga.associatedRecipe);
      return parseDataCreadorDescargas(
        {
          ...descarga,
          associatedRecipeData,
        },
        true
      );
    })
    .flat();

  // Parse Lots: for each lot, get all items in parsedDataDescargas that have the same lot id. Then, sum all the kgLotMV
  const parsedLots = lots.map((lot) => {
    const parsedDataDescargasFiltered = parsedDataDescargas.filter((descarga: any) => descarga.id === lot.id);
    const kgLotMV = parsedDataDescargasFiltered.reduce((acc, curr) => acc + (curr as any).kgLotMV, 0);
    const kgLotMS = parsedDataDescargasFiltered.reduce((acc, curr) => acc + (curr as any).kgLot, 0);
    return {
      ...lot,
      kgLotMV,
      kgLotMS,
    };
  });

  return parsedLots;
};
