import { HAASTEN_LIGHT, SECONDARY_ACCENT } from 'components/colors';
import { Brush, CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { CustomTooltip } from 'screens/ith-historico/ITHPromedioCharts';
import { ChartDataPoint } from '.';

export default function tempHumidityChart({ data }: { data: ChartDataPoint[] }) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={data}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 5,
        }}
      >
        <Line type="monotone" yAxisId="left" dataKey="temperature" name="Temperatura" stroke={HAASTEN_LIGHT} />
        <Line type="monotone" yAxisId="right" name="Humedad" dataKey="humidity" stroke={SECONDARY_ACCENT} />
        <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis yAxisId="left" dataKey="temperature" orientation="left" />
        <YAxis yAxisId="right" dataKey="humidity" orientation="right" />
        <Tooltip content={<CustomTooltip />} />
        <Legend verticalAlign="top" />
        <Brush dataKey="date" height={30} stroke={HAASTEN_LIGHT} fill={'rgba(0,0,0,0)'} />
      </LineChart>
    </ResponsiveContainer>
  );
}
