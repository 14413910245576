import Button from 'components/button';
import { FullScreenLoader } from 'components/loader';
import SpaceDivider from 'components/space-divider';
import { ChipQuestionMark } from 'design-system/chip/chip-question-mark';
import FormCard, { FormCardIcon } from 'design-system/form-card';
import {
  IconDevices,
  IconHumidity,
  IconPercentage,
  IconSwitchOff,
  IconSwitchOn,
  IconTemperature,
} from 'design-system/icon';
import SidebarSlide from 'design-system/sidebar-slide';
import { TextStatisticWithIcon } from 'design-system/text';
import { useTodayQuery } from 'hooks/useCalendarQuery';
import { useModal } from 'hooks/useModal';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useGetDeviceDataQuery } from 'store/devices/devicesApi';
import { selectDeviceSelected } from 'store/devices/devicesSlice';
import { DeviceEventITH } from 'utils/device/event/ith';
import TempHumidityChart from './tempHumidityChart';
import parseFunction from './parser';
import ITHChart from './ithChart';

export type ChartDataPoint = {
  date: string;
  temperature: number;
  humidity: number;
};

function ITH() {
  const deviceSelected = useSelector(selectDeviceSelected);
  const { data: deviceDataToday = [], isLoading } = useTodayQuery(useGetDeviceDataQuery);
  const ithDataToday = useMemo(() => parseFunction(deviceDataToday as any as DeviceEventITH[]), [deviceDataToday]);
  const [isOpenTempHumidityChart, onOpenTempHumidityChart, onCloseTempHumidityChart] = useModal<any>();
  const [isOpenITHChart, onOpenITHChart, onCloseITHChart] = useModal<any>();

  const [latest] = ithDataToday.slice(-1) || [];

  const { type = '', serialNumber, alias } = deviceSelected || {};
  const { temperature, humidity, ITH } = latest || {};

  const chartData: ChartDataPoint[] = useMemo(
    () =>
      ithDataToday.map(({ date, temperature, humidity, ITH }) => ({
        date,
        temperature,
        humidity,
        ITH
      })),
    [ithDataToday]
  );

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <div className="content-view px-8 py-8">
        <FormCard
          title="Equipo ITH"
          subtitle={`${serialNumber}`}
          icon={
            <FormCardIcon className="px-2 py-2">
              <IconDevices />
            </FormCardIcon>
          }
          items={[
            ...(!latest
              ? [
                  {
                    value: (
                      <ChipQuestionMark>
                        No se encontraron datos para este equipo en la últimas 24 horas.
                      </ChipQuestionMark>
                    ),
                  },
                ]
              : []),
            {
              value: (
                <div className="flex flex-col gap-2">
                  <TextStatisticWithIcon Icon={IconTemperature} color="bg-haasten-500/70">
                    Temperatura: {temperature}˚C
                  </TextStatisticWithIcon>
                  <TextStatisticWithIcon Icon={IconHumidity} color="bg-indigo-500/70">
                    Humedad: {humidity}%
                  </TextStatisticWithIcon>
                  <TextStatisticWithIcon Icon={IconPercentage} color="bg-purple-500/70">
                    ITH: {ITH}
                  </TextStatisticWithIcon>
                  {serialNumber === '202227091' && (
                    <>
                      <TextStatisticWithIcon
                        Icon={latest?.A1 ? IconSwitchOn : IconSwitchOff}
                        color={latest?.A1 ? 'bg-haasten-500/70' : 'bg-red-500/70'}
                      >
                        A1: {latest?.A1 ? 'ON' : 'OFF'}
                      </TextStatisticWithIcon>
                      <TextStatisticWithIcon
                        Icon={latest?.A5 ? IconSwitchOn : IconSwitchOff}
                        color={latest?.A5 ? 'bg-haasten-500/70' : 'bg-red-500/70'}
                      >
                        A5: {latest?.A5 ? 'ON' : 'OFF'}
                      </TextStatisticWithIcon>
                    </>
                  )}
                </div>
              ),
            },
            {
              value: (
                <>
                  <div className="hidden mobile:block">
                    <div className="h-50v w-full">
                      <TempHumidityChart data={chartData} />
                    </div>
                  </div>
                  <div className="mobile:hidden flex justify-end">
                    <Button onClick={onOpenTempHumidityChart} variant="fill">
                      Ver gráfico
                    </Button>
                  </div>
                </>
              ),
            },
            {
              value: (
                <>
                  <div className="hidden mobile:block">
                    <div className="h-50v w-full">
                      <ITHChart data={chartData} />
                    </div>
                  </div>
                  <div className="mobile:hidden flex justify-end">
                    <Button onClick={onOpenITHChart} variant="fill">
                      Ver gráfico
                    </Button>
                  </div>
                </>
              ),
            },
          ]}
        />
      </div>
      <SidebarSlide
        isOpen={isOpenTempHumidityChart}
        onClose={onCloseTempHumidityChart}
        title={`${alias || type} - ${serialNumber}`}
      >
        <SpaceDivider />
        <div className="ith-chart-wrapper">
          <TempHumidityChart data={chartData} />
        </div>
      </SidebarSlide>

      <SidebarSlide isOpen={isOpenITHChart} onClose={onCloseITHChart} title={`${alias || type} - ${serialNumber}`}>
        <SpaceDivider />
        <div className="ith-chart-wrapper">
          <ITHChart data={chartData} />
        </div>
      </SidebarSlide>
    </>
  );
}

export default ITH;
