import { customBodyRenderDateTotalColumn, customBodyRenderNumberValue } from 'components/table/TableSubsum';

export const columnsHistoricoIngredientes = [
  {
    name: 'date',
    label: 'Fecha',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderDateTotalColumn,
    },
  },
  {
    name: 'recipe',
    label: 'Dieta',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'ingredient',
    label: 'Ingrediente',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'amount',
    label: 'Cabezas/Kg',
    options: { filter: false, sort: false },
  },
  {
    name: 'toLoad',
    label: 'Kg Teórico',
    options: { filter: false, sort: false },
  },
  {
    name: 'loaded',
    label: 'Kg Cargados',
    options: { filter: false, sort: false },
  },
  {
    name: 'kgTeoricoMs',
    label: 'Kg Teórico MS',
    options: { filter: false, sort: false, customBodyRender: customBodyRenderNumberValue },
  },
  {
    name: 'kgCargadosMs',
    label: 'Kg Cargados MS',
    options: { filter: false, sort: false, customBodyRender: customBodyRenderNumberValue },
  },
  {
    name: 'porcentajeKg',
    label: '% Error',
    options: { filter: false, sort: false },
  },
  {
    name: 'price',
    label: 'Precio Kg ($)',
    options: { filter: false, sort: false },
  },
  {
    name: 'priceToLoad',
    label: '$ Dieta Teórica',
    options: { filter: false, sort: false },
  },
  {
    name: 'priceLoaded',
    label: '$ Dieta Cargada',
    options: { filter: false, sort: false },
  },
  {
    name: 'user',
    label: 'Usuario',
    options: {
      filter: true,
      sort: false,
    },
  },
];
