import Button from 'components/button';
import Input from 'components/input';
import { FullScreenLoader } from 'components/loader';
import { ChipQuestionMark } from 'design-system/chip/chip-question-mark';
import { FormCardIcon } from 'design-system/form-card';
import { IconDevices } from 'design-system/icon';
import Table from 'design-system/table';
import { useAdminGetDevices } from 'hooks/useAdminGetDevices';
import { useModal } from 'hooks/useModal';
import { useState } from 'react';
import { Device } from 'utils/device';
import { AdminDevicesRow } from './AdminDevicesRow';
import ModalCreateDevice from './ModalCreateDevice';

function AdminDevices() {
  const [searchInput, setSearchInput] = useState('');
  const [isOpenCreateDevice, onOpenCreateDevice, onCloseCreateDevice] = useModal();

  const { data: devices = [], isFetching: isGetDeviceAllFetching } = useAdminGetDevices();

  const devicesSorted = devices
    .filter(
      (device) =>
        !searchInput ||
        device.alias.toLowerCase().includes(searchInput.toLowerCase()) ||
        device.type.toLowerCase().includes(searchInput.toLowerCase()) ||
        device.serialNumber.includes(searchInput)
    )
    .reduce(
      (acc, curr) => ({
        ...acc,
        [curr.type]: acc[curr.type] ? [...acc[curr.type], curr] : [curr],
      }),
      {} as { [k: string]: Device[] }
    );

  return (
    <>
      {isGetDeviceAllFetching ? (
        <FullScreenLoader />
      ) : !devices?.length ? (
        <div className="content-view px-8 py-8">
          <ChipQuestionMark>No se encontraron equipos.</ChipQuestionMark>
        </div>
      ) : (
        <>
          {isOpenCreateDevice && <ModalCreateDevice onClose={onCloseCreateDevice} />}
          <div className="content-view px-8 py-8 space-y-8 ">
            <div className="form-row" style={{ alignItems: 'end' }}>
              <Input
                placeholder="Tipo, serial o alias"
                label="Buscar equipo"
                value={searchInput}
                onChange={setSearchInput}
                type={'text'}
              />
              <Button onClick={onOpenCreateDevice} variant="fill">
                Crear equipo
              </Button>
            </div>

            {Object.entries(devicesSorted).map(([deviceType, devices], idx) => (
              <Table
                key={idx}
                title={`Equipos ${deviceType}`}
                Icon={
                  <FormCardIcon className="px-2 py-2">
                    <IconDevices />
                  </FormCardIcon>
                }
                rows={devices.map((device) => {
                  return <AdminDevicesRow key={device.serialNumber} device={device} />;
                })}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
}

export default AdminDevices;
