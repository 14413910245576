import { HAASTEN_LIGHT, SECONDARY_ACCENT } from 'components/colors';
import FormCard, { FormCardIcon } from 'design-system/form-card';
import { IconBarChart } from 'design-system/icon';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Bar, Brush, CartesianGrid, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { getKgConsumidosPorHora } from '../cas.helpers';
import { DeviceEventCas } from 'utils/device/event/cas';

export const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="flex flex-col p-4 bg-white/90 rounded-md">
        <p className="text-black">{label}</p>
        {payload.map((item: any) => (
          <p
            key={item.name}
            className="font-bold"
            style={{
              color: item.stroke || item.fill || item.color,
            }}
          >
            {item.name}: {item.value}
          </p>
        ))}
      </div>
    );
  }

  return null;
};

interface CasChartKgComidosProps {
  deviceData: DeviceEventCas[];
}

export const CasChartKgComidos = ({ deviceData }: CasChartKgComidosProps) => {
  const { watch } = useFormContext();
  const [silo] = watch(['silo']);

  const kgConsumidosPorHora = useMemo(() => getKgConsumidosPorHora(deviceData), [deviceData]);
  const kgConsumidosPorHoraForSilo = kgConsumidosPorHora?.[silo] || [];

  return (
    <div className="content-view px-8 py-8">
      <FormCard
        title="Kg comidos por hora"
        icon={
          <FormCardIcon className="px-2 py-2">
            <IconBarChart />
          </FormCardIcon>
        }
        items={[
          {
            value: (
              // Temperatura y humedad
              <div className="w-full h-[70vh] relative">
                <ResponsiveContainer width="100%" height="100%">
                  <ComposedChart
                    width={500}
                    height={300}
                    data={kgConsumidosPorHoraForSilo}
                    margin={{
                      top: 20,
                      right: 0,
                      left: 0,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" opacity={0.5} />
                    <XAxis dataKey="fecha" />
                    <YAxis yAxisId="left" orientation="left" stroke={SECONDARY_ACCENT} />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend verticalAlign="top" />
                    <Brush
                      dataKey="fecha"
                      height={30}
                      stroke={HAASTEN_LIGHT}
                      fill={'rgba(0,0,0,0)'}
                      startIndex={kgConsumidosPorHoraForSilo.length < 15 ? 0 : kgConsumidosPorHoraForSilo.length - 15}
                    />
                    <Bar yAxisId="left" dataKey="kgConsumidos" name="Kg comidos" fill={SECONDARY_ACCENT} />
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};
