import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectDevices } from 'store/config/configSlice';
import { selectDeviceSelected } from 'store/devices/devicesSlice';
import { ROUTE_PATH } from 'utils/constants/routes/types';
import { usePathname } from 'utils/constants/routes/utils';
import {
  Device,
  DeviceItem,
  DEVICE_TYPE_CMAS,
  DEVICE_TYPE_GAC,
  DEVICE_TYPE_ITH,
  DEVICE_TYPE_SIP,
  isDeviceCmas,
  isDeviceGac,
  isDeviceITH,
  isDeviceSIP,
  DEVICE_TYPE_CAS,
  isDeviceCas,
} from 'utils/device';

export const useEquipos = () => {
  const pathname = usePathname();
  const devices = useSelector(selectDevices);
  const deviceSelected = useSelector(selectDeviceSelected);

  const devicesList: DeviceItem[] = useMemo(
    () =>
      devices
        ?.filter(
          (equipo: Device) =>
            !deviceSelected ||
            (isDeviceGac(deviceSelected) && isDeviceGac(equipo)) ||
            (isDeviceCmas(deviceSelected) && isDeviceCmas(equipo)) ||
            (isDeviceITH(deviceSelected) && isDeviceITH(equipo)) ||
            (isDeviceSIP(deviceSelected) && isDeviceSIP(equipo)) ||
            (isDeviceCas(deviceSelected) && isDeviceCas(equipo))
        )
        .map((device: Device) => ({
          ...device,
          isSelected: deviceSelected?.serialNumber === device.serialNumber,
          link:
            device.type === DEVICE_TYPE_SIP
              ? `${pathname || ROUTE_PATH.PESADAS}/${device.serialNumber}`
              : device.type === DEVICE_TYPE_CMAS
              ? `${pathname || ROUTE_PATH.CMAS}/${device.serialNumber}`
              : device.type === DEVICE_TYPE_ITH
              ? `${pathname || ROUTE_PATH.ITH}/${device.serialNumber}`
              : device.type === DEVICE_TYPE_GAC
              ? `${pathname || ROUTE_PATH.GAC}/${device.serialNumber}`
              : device.type === DEVICE_TYPE_CAS
              ? `${pathname || ROUTE_PATH.CAS}/${device.serialNumber}`
              : '',
        })) || [],
    [devices, deviceSelected, pathname]
  );

  return devicesList;
};
