import {
  DeviceDataEvent,
  DeviceEventMixing,
  filterAndSortyByMixing,
} from 'utils/device/event/sip';
import { notUndefined } from 'utils/helpers';
import { customBodyRenderTime } from 'utils/helpers/body-renders';

export interface ParsedDeviceDataEventMixing {
  date: string; // fecha hora
  hora: string;
  tiempoMezclado: string;
  faltoMezclar: string;
  tiempoNoMezclado: string;
  desplazamientoMezclado: string;
  coordenadasInicioMezclado: {
    lat: number;
    lng: number;
  };
  coordenadasFinMezclado: {
    lat: number;
    lng: number;
  };
}

interface DeviceEventMixingNoUndefined extends DeviceEventMixing {
  mixingStartCoordinates: string;
  mixingEndCoordinates: string;
}

export default function parseDataMezclado(
  data: DeviceDataEvent[] = []
): ParsedDeviceDataEventMixing[] {
  return filterAndSortyByMixing(data)
    .filter(
      (
        element // TODO: preguntar a gonza si se pueden filtrar estos elementos que no tienen coordenadas
      ): element is DeviceEventMixingNoUndefined =>
        notUndefined(element.mixingStartCoordinates) &&
        notUndefined(element.mixingEndCoordinates)
    )
    .map((elemento) => {
      const {
        missedMixing,
        missedMixingTime,
        mixingEndCoordinates,
        mixingStartCoordinates,
        mixedDisplacement,
        date,
        mixingTime,
      } = elemento;

      const latLngInicio = mixingStartCoordinates.split(',');
      const latLngFin = mixingEndCoordinates.split(',');
      return {
        date,
        hora: customBodyRenderTime(date),
        tiempoMezclado: mixingTime,
        faltoMezclar: missedMixing ? 'Sí' : 'No',
        tiempoNoMezclado: missedMixingTime,
        desplazamientoMezclado: mixedDisplacement ? 'Sí' : 'No',
        coordenadasInicioMezclado: {
          lat: parseFloat(latLngInicio[0]),
          lng: parseFloat(latLngInicio[1]),
        },
        coordenadasFinMezclado: {
          lat: parseFloat(latLngFin[0]),
          lng: parseFloat(latLngFin[1]),
        },
      };
    });
}
