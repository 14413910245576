import { useMemo } from 'react';
import { getMSNominalReceta } from 'utils/device/event/diet';

function MSNominal({ recetaParsed = [] }) {
  const msNominal = useMemo(() => getMSNominalReceta(recetaParsed), [
    recetaParsed,
  ]);
  return <h4 style={{ margin: 0 }}>{`%MS Nominal: ${msNominal}%`}</h4>;
}
export default MSNominal;
