import { customBodyRenderDateToLocal } from 'utils/helpers/body-renders';

export const columnsRegistroEventos = [
  {
    name: 'date',
    label: 'Fecha',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderDateToLocal,
    },
  },
  {
    name: 'action',
    label: 'Acción',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => {
        return ACTION_TRANSLATE[value] || value;
      },
      // filterOptions: {
      //   names: ["Otros", "Recetas", "Stock"],
      //   logic(value, filters) {
      //     return !(
      //       (filters.includes("Stock") && EVENTOS_STOCK.includes(value)) ||
      //       (filters.includes("Recetas") && EVENTOS_RECETA.includes(value)) ||
      //       (filters.includes("Otros") && EVENTOS_OTROS.includes(value))
      //     );
      //   },
      // },
    },
  },
  {
    name: 'description',
    label: 'Descripción',
    options: {
      filter: false,
      sort: false,
    },
  },
];

const ACTION_TRANSLATE = {
  addRecipeFromServer: 'Agregar receta (servidor)',
  addRecipeFromClient: 'Agregar receta (web)',
  updatedRecipeFromServer: 'Actualizar receta (servidor)',
  updatedRecipeFromClient: 'Actualizar receta (web)',
  recipeCreateFromClient: 'Agregar receta (web)',
  recipeCreateFromDevice: 'Agregar receta (equipo)',
  recipeAddFromDevice: 'Crear receta (equipo)',
  recipeAddFromClient: 'Crear receta (web)',
  recipeUpdateFromDevice: 'Actualizar receta (equipo)',
  recipeUpdateFromClient: 'Actualizar receta (web)',
  recipeDeleteFromDevice: 'Eliminar receta (equipo)',
  recipeDeleteFromClient: 'Eliminar receta (web)',
  updateLotNameFromClient: 'Actualizar nombre de lote (web)',
  updateLotHeadsCountFromClient: 'Actualizar contador de cabezas en lote (web)',
  dryMatterUpdateFromClient: 'Actualizar materia seca (web)',
  dryMatterUpdateFromDevice: 'Actualizar materia seca (equipo)',
  priceUpdateFromClient: 'Actualizar precio (web)',
  stockUpdateFromClient: 'Actualizar stock (web)',
  nameUpdateFromClient: 'Actualizar nombre (web)',
  nameUpdateFromDevice: 'Actualizar nombre (equipo)',
  riskIngredientUpdateFromClient: 'Actualizar riesgo de ingrediente (web)',
  automaticCorrectionUpdateFromClient: 'Actualizar corrección automática (web)',
  numberAutomaticUpdateFromClient: 'Actualizar número automático (web)',
  lotUpdatefromClient: 'Actualizar lote (web)',
  isAutomatedUpdateFromClient: 'Actualizar automatizado (web)',
  isAutomatedUpdateFromDevice: 'Actualizar automatizado (equipo)',
  unloadGuideUpdateFromClient: 'Actualizar guía de descarga (web)',
  unloadGuideCreateFromClient: 'Crear guía de descarga (web)',
  lotUpdateFromDevice: 'Actualizar lote (equipo)',
  unloadGuideUpdateFromDevice: 'Actualizar guía de descarga (equipo)',
  priceUpdateFromDevice: 'Actualizar precio (equipo)',
  automaticCorrectionUpdateFromDevice: 'Actualizar corrección automática (equipo)',
  riskIngredientUpdateFromDevice: 'Actualizar riesgo de ingrediente (equipo)',
  numberAutomaticUpdateFromDevice: 'Actualizar número automático (equipo)',
  modeUpdateFromDevice: 'Actualizar modo (equipo)',
  modeUpdateFromClient: 'Actualizar modo (web)',
  unloadGuideDeleteFromDevice: 'Eliminar guía de descarga (equipo)',
  unloadGuideCreateFromDevice: 'Crear guía de descarga (equipo)',
  unloadGuideDeleteFromClient: 'Eliminar guía de descarga (web)',
  stockUpdateFromDevice: 'Actualizar stock (equipo)',
  // GAC
  updatePumpUserFromServer: 'Actualizar usuario (servidor)',
  updatePumpUserFromDevice: 'Actualizar usuario (equipo)',
  pumpUserUpdateFromServer: 'Actualizar usuario (servidor)',
  pumpUserUpdateFromDevice: 'Actualizar usuario (equipo)',
  pumpUserAddFromServer: 'Agregar usuario (servidor)',
  pumpUserAddFromDevice: 'Agregar usuario (equipo)',
  pumpVehicleUpdateFromServer: 'Actualizar vehículo (servidor)',
  pumpVehicleUpdateFromDevice: 'Actualizar vehículo (equipo)',
  fuelQuantityUpdateFromServer: 'Actualizar cantidad de combustible (servidor)',
  fuelQuantityUpdateFromDevice: 'Actualizar cantidad de combustible (equipo)',
  fuelPriceUpdateFromServer: 'Actualizar precio de combustible (servidor)',
  fuelPriceUpdateFromDevice: 'Actualizar precio de combustible (equipo)',
};
