import { customBodyRenderDate } from 'utils/helpers/body-renders';

export const columnsMezclado = [
  {
    name: 'date',
    label: 'Fecha',
    options: {
      filter: false,
      sort: true,
      customBodyRender: customBodyRenderDate,
    },
  },
  {
    name: 'tiempoMezclado',
    label: 'Tiempo Mezclado',
    options: { filter: true, sort: true, filterType: 'multiselect' },
  },
  {
    name: 'faltoMezclar',
    label: 'Falto Mezclar',
    options: { filter: false, sort: true },
  },
  {
    name: 'tiempoNoMezclado',
    label: 'Tiempo NO Mezclado',
    options: { filter: false, sort: true },
  },
  {
    name: 'desplazamientoMezclado',
    label: 'Desplazamiento durante el mezclado',
    options: { filter: true, sort: true },
  },
];
