import { Device } from 'utils/device';
import { api as devicesApi, TAG_USER, TAG_DEVICE } from '../devices/devicesApi';

export interface LoginRequest {
  username: string;
  password: string;
}

export interface UserResponse {
  token: string;
  user: LoggedUser;
}

export interface LoggedUser {
  username: string;
  email?: string;
  secondaryEmail?: string;
  columns?: {};
  roles?: string[];
  devices?: Device[];
  reportsSettings?: {};
}

export const api = devicesApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<UserResponse, LoginRequest>({
      query: (credentials) => ({
        url: 'login',
        method: 'POST',
        body: credentials,
        validateStatus: (response, result) =>
          response.status === 200 && result.token && result.user,
      }),
    }),
    getUser: builder.query<LoggedUser, string>({
      query: (username) => ({ url: `user/get/${username}` }),
      providesTags: [TAG_USER, TAG_DEVICE],
    }),
  }),
});

export const { useLoginMutation, useGetUserQuery } = api;
