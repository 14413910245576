import SpaceDivider from 'components/space-divider';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { selectDevices } from 'store/config/configSlice';
import CardConfigEquipo from './card-config-equipo';

function ConfigEquipos() {
  const devices = useSelector(selectDevices);

  return (
    <>
      {devices?.map((device) => (
        <Fragment key={device.serialNumber}>
          <CardConfigEquipo device={device} />
          <SpaceDivider className="mb-4" />
        </Fragment>
      ))}
    </>
  );
}

export default ConfigEquipos;
