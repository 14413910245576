import { customBodyRenderPercentage } from 'utils/helpers/body-renders';

export const columnsTablaAlimentos = [
  {
    name: 'name',
    label: 'Alimento',
    options: { filter: false, sort: false },
  },
  {
    name: 'dryMatterPercentage',
    label: '%MS',
    options: {
      filter: false,
      sort: false,
      customBodyRender: customBodyRenderPercentage,
    },
  },
];
