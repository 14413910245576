import { HAASTEN_LIGHT, SECONDARY_ACCENT } from 'components/colors';
import FormCard, { FormCardIcon } from 'design-system/form-card';
import { IconBarChart } from 'design-system/icon';
import {
  Bar,
  Brush,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { COLORS } from 'utils/constants/colors';
import { DeviceEventGAC } from 'utils/device/event/gac/gac';

export const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="flex flex-col p-4 bg-white/90 rounded-md">
        <p className="text-black">{label}</p>
        {payload[0]?.payload?.Usuario && <p className="text-black font-bold">{payload[0]?.payload?.Usuario}</p>}
        {payload.map((item: any) => (
          <p
            key={item.name}
            className="font-bold"
            style={{
              color: item.stroke || item.fill || item.color,
            }}
          >
            {item.name}: {item.value}
          </p>
        ))}
      </div>
    );
  }

  return null;
};

interface GacChartProps {
  dataGacHistorico: (DeviceEventGAC & { fecha: string })[];
}

export const GacChart = ({ dataGacHistorico }: GacChartProps) => {
  // Create a map of unique users in data to a random vibrant color for the chart (don't use theme or twin colors, use contrasting colors with 80% saturation, in the shades of blue, green, purple, red, orange)
  const userColors = dataGacHistorico.reduce((acc, item) => {
    if (!acc[item.user]) {
      acc[item.user] = COLORS[Object.keys(acc).length % COLORS.length];
    }
    return acc;
  }, {} as Record<string, string>);

  const data = dataGacHistorico.map((item) => ({
    name: item.fecha,
    'Litros despachados': item.ltDownload,
    Usuario: item.user,
    'Litros disponibles': item.ltAvaiableTank,
    fill: userColors[item.user],
  }));

  return (
    <div className="content-view px-8 py-8">
      <FormCard
        title="Datos último mes"
        icon={
          <FormCardIcon className="px-2 py-2">
            <IconBarChart />
          </FormCardIcon>
        }
        items={[
          {
            value: (
              // Temperatura y humedad
              <div className="w-full h-[70vh] relative">
                <ResponsiveContainer width="100%" height="100%">
                  <ComposedChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                      top: 20,
                      right: 0,
                      left: 0,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" opacity={0.5} />
                    <XAxis dataKey="name" />
                    <YAxis yAxisId="left" orientation="left" stroke={SECONDARY_ACCENT} />
                    <YAxis yAxisId="right" orientation="right" stroke="#ff7300" />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend verticalAlign="top" />
                    <Brush
                      dataKey="name"
                      height={30}
                      stroke={HAASTEN_LIGHT}
                      fill={'rgba(0,0,0,0)'}
                      startIndex={data.length < 15 ? 0 : data.length - 15}
                    />
                    <Bar yAxisId="left" dataKey="Litros despachados" fill={SECONDARY_ACCENT} />
                    <Line yAxisId="right" type="monotone" dataKey="Litros disponibles" stroke="#ff7300" />
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};
