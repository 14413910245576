import moment from 'moment';
import { sortIth, DeviceEventITH } from 'utils/device/event/ith';

export default function parseDataITH(
  data: DeviceEventITH[] = []
): DeviceEventITH[] {
  return sortIth(data).map((element) => ({
    ...element,
    date: moment.utc(element.date).format('HH:mm'),
  }));
}
