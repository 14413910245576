import { useCommonQueryParameters } from 'hooks/useCommonQueryParameters';
import moment from 'moment';
import { Moment } from 'moment';
import { DeviceEventITH } from 'utils/device/event/ith';

const getRangeOfDates = (startDate: Date, endDate: Date | string) => {
  const dates: Moment[] = [];
  const theDate = moment.utc(startDate, 'DD-MM-YYYY HH:mm:ss');
  while (theDate < moment.utc(endDate, 'DD-MM-YYYY HH:mm:ss')) {
    let h = 0;
    while (h < 24) {
      theDate.hours(h);
      dates.push(moment.utc(theDate, 'DD-MM-YYYY HH:mm:ss'));
      h++;
    }

    theDate.add(1, 'day');
  }
  dates.push(moment.utc(endDate, 'DD-MM-YYYY HH:mm:ss'));
  return dates;
};

export const useHistoricoPromedioStats = (dataITHHistorico: (DeviceEventITH & { fecha: string })[]) => {
  const { minDate } = useCommonQueryParameters();

  const lastPoint = dataITHHistorico?.[dataITHHistorico?.length - 1];

  const stats = getRangeOfDates(new Date(minDate), lastPoint?.fecha).map((d) => {
    const year = d.year();
    const month = d.month() + 1;
    const day = d.date();
    const hour = d.hours();

    const points = dataITHHistorico?.filter((item) => {
      if (!item.temperature || !item.humidity || !item.ITH) return false;

      const pointFecha = moment.utc(item.fecha, 'DD-MM-YYYY HH:mm:ss');
      return (
        pointFecha.year() === d.year() &&
        pointFecha.month() + 1 === d.month() + 1 &&
        pointFecha.date() === d.date() &&
        pointFecha.hours() === d.hours()
      );
    });

    const averageTemperature = points?.reduce((acc, point) => acc + point.temperature, 0) / points?.length;
    const averageHumidity = points?.reduce((acc, point) => acc + point.humidity, 0) / points?.length;
    const averageIth = points?.reduce((acc, point) => acc + point.ITH, 0) / points?.length;

    return {
      year,
      month,
      day,
      hour,
      points,
      averageTemperature,
      averageHumidity,
      averageIth,
      fecha: `${day}/${month} ${hour}hs`,
    };
  });

  return stats;
};
