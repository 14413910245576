export const columnsAdminUsers = [
  {
    name: 'username',
    label: 'Usuario',
    options: { filter: false, sort: true },
  },
  {
    name: 'roles',
    label: 'Rol',
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) =>
        Array.isArray(value) ? value.join(', ') : value,
    },
  },
  {
    name: 'serialNumbers',
    label: 'Equipos',
    options: { filter: false, sort: true },
  },
  {
    name: 'accion',
    label: '',
    options: {
      filter: false,
      sort: false,
      viewColumns: false,
    },
  },
];
