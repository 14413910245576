import { FormCardIcon } from 'design-system/form-card';
import { IconDevices } from 'design-system/icon';
import Table from 'design-system/table';
import TableRow from 'design-system/table/table-row';
import { FiChevronRight } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { devicesActions } from 'store/devices/devicesSlice';
import { DeviceItem } from 'utils/device';
import { useEquipos } from './useEquipos';

export default function Equipos({ onDeviceClick = () => {} }: { onDeviceClick?: (device: DeviceItem) => void }) {
  const dispatch = useDispatch();
  const devicesList = useEquipos();

  const onLinkClick = (device: DeviceItem) => {
    dispatch(devicesActions.setDeviceSelected(device));
    onDeviceClick(device);
  };

  return (
    <Table
      title="Equipos"
      Icon={
        <FormCardIcon className="px-2 py-2">
          <IconDevices />
        </FormCardIcon>
      }
      rows={devicesList.map((device: DeviceItem, index) => (
        <Link to={device.link || ''} onClick={() => onLinkClick(device)} key={index}>
          <TableRow
            title={device.serialNumber}
            subtitles={[
              device.type,
              ...(device.alias ? [device.alias] : []),
              ...(device.associatedCmas ? [`CMAS asociado: ${device.associatedCmas}`] : []),
            ]}
            clickable
            action={
              <FiChevronRight
                style={{
                  color: '#fff',
                  fontSize: '1.5rem',
                  cursor: 'pointer',
                }}
              />
            }
          />
        </Link>
      ))}
    />
  );
}
