import Equipos from 'screens/equipos/Equipos';

function Home() {
  return (
    <>
      <div className="px-8 py-8">
        <Equipos />
      </div>
    </>
  );
}

export default Home;
