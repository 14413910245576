import { DeviceEventBase } from 'utils/device/event';

export const ACTION_CMAS = 'dryingFinished';

export interface DeviceEventCMAS extends DeviceEventBase {
  action: typeof ACTION_CMAS;
  dryMatter: number;
  dryingMode: string;
  dryingTime: number;
  startingWeight: number;
  finalWeight: number;
  ingredient: string;
  numberOfCycles: number;
  validationPercentage: number;
}

export function sortCMAS(data: DeviceEventCMAS[]) {
  return data
    .slice()
    .sort((element1, element2) => (element1.date > element2.date && -1) || 1);
}
