import Button from 'components/button';
import Icon from '../icons';
import {
  FormItem,
  Input as InputComponent,
  InputWithStartAdornment,
} from 'design-system/input';

export default function Input({
  endAdornment,
  startAdornment,
  label,
  required,
  onChange = () => {},
  onBlur = () => {},
  ...props
}: {
  endAdornment?: any;
  startAdornment?: any;
  label?: string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  required?: boolean;
  [prop: string]: any;
}) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    onChange(event.target.value);
  };

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    onBlur(event.target.value);
  };

  return (
    <FormItem label={`${label || ''}${required ? '*' : ''}`}>
      {startAdornment ? (
        <>
          <div className="pointer-events-none h-7 w-7 px-1 py-1 flex items-center absolute bottom-[6px] left-[4px]">
            <span>{startAdornment}</span>
          </div>
          <InputWithStartAdornment
            {...props}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </>
      ) : (
        <InputComponent
          {...props}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      )}
      {endAdornment ? (
        <div className="h-7 w-7 px-1 py-1 flex items-center absolute bottom-[6px] right-[4px]">
          {endAdornment}
        </div>
      ) : null}
    </FormItem>
  );
}

export function InputWithEditToggle({
  onToggle,
  toggleDisabled,
  ...props
}: {
  onToggle: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  toggleDisabled?: boolean;
  [prop: string]: any;
}) {
  return (
    <Input
      endAdornment={
        <Button variant="icon" onClick={onToggle} disabled={toggleDisabled}>
          <Icon name="pencil" />
        </Button>
      }
      {...props}
    />
  );
}
