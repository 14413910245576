import { useState, useCallback, useMemo } from 'react';
import tableColumns from 'utils/helpers/tables';
import cloneDeep from 'lodash/cloneDeep';
import { useSetUserColumnsMutation } from 'store/config/configApi';
import { useAuth } from './useAuth';

export const useColumns = (name = '') => {
  const {
    loggedUser: {
      user,
      user: { columns = [] },
    },
  } = useAuth();

  const columnsToUse = useMemo(() => {
    let cols = cloneDeep(tableColumns[name]);
    if (columns != null && columns.hasOwnProperty(name)) {
      cols.forEach((col, index) => {
        if (col.name === columns[name][index].name) {
          col.options.display = !(
            columns[name][index].options.display === false
          );
          col.options.viewColumns = columns[name][index].options.viewColumns;
        }
      });
    }
    return cols;
  }, [columns, name]);

  const [
    setUserColumns,
    { isLoading: isSetUserColumnsLoading },
  ] = useSetUserColumnsMutation();
  const onColumnsViewChange = (columnsUpdated) => {
    let cleanColumnsUpdated = cloneDeep(columnsUpdated);
    cleanColumnsUpdated.forEach(
      (col) => (col.options.customBodyRender = undefined)
    );
    let newColumns = {
      ...columns,
      [name]: cleanColumnsUpdated,
    };
    setUserColumns({
      username: user?.username,
      columns: newColumns,
    });
  };

  const [filtersApplied, setFiltersApplied] = useState([]);
  const onFilterChange = useCallback(
    (changedColumn, filterList) => {
      setFiltersApplied(
        columnsToUse.reduce(
          (obj, item, index) => ({ ...obj, [item.name]: filterList[index] }),
          {}
        )
      );
    },
    [columnsToUse]
  );

  return {
    columns: columnsToUse,
    onColumnsViewChange,
    onFilterChange,
    filtersApplied,
    isFetching: isSetUserColumnsLoading,
  };
};
