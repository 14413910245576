import Input from 'components/input';
import { FullScreenLoader } from 'components/loader';
import FormCard from 'design-system/form-card';
import SidebarSlide from 'design-system/sidebar-slide';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useUpdateGacVehicleMutation } from 'store/devices/devicesApi';
import { selectDeviceSelected } from 'store/devices/devicesSlice';
import { PumpVehicle } from 'utils/device';
import { VERSION, isDeviceVersionAtLeast } from 'utils/helpers/validation';

interface ModalEditarVehiculoProps {
  isOpen: boolean;
  onClose: () => void;
  vehiculo?: PumpVehicle | null;
}

export function ModalEditarVehiculo({ isOpen, onClose, vehiculo }: ModalEditarVehiculoProps) {
  const deviceSelected = useSelector(selectDeviceSelected);
  const pumpVehicles = deviceSelected?.pumpVehicles;

  const isDeviceV2 = isDeviceVersionAtLeast(VERSION.TWO, deviceSelected);

  const [updateGacVehicle] = useUpdateGacVehicleMutation();

  const [isSaving, setIsSaving] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isValid, isDirty },
  } = useForm<PumpVehicle>({
    defaultValues: {
      id: vehiculo?.id,
      name: vehiculo?.name,
      keyId: vehiculo?.keyId,
    },
  });

  const onSave = () => {
    handleSubmit(async (data) => {
      setIsSaving(true);
      const newVehicle = {
        ...vehiculo,
        ...data,
      };

      const response = await updateGacVehicle({ vehicle: newVehicle, serialNumber: deviceSelected!.serialNumber });

      if ('data' in response) onClose();
      setIsSaving(false);
    })();
  };

  const setFormToVehicle = useCallback(
    (vehicle: PumpVehicle) => {
      setValue('id', vehicle.id);
      setValue('name', vehicle.name);
    },
    [setValue]
  );

  useEffect(() => {
    if (vehiculo && isOpen) {
      setFormToVehicle(vehiculo);
    }
  }, [vehiculo, isOpen, setFormToVehicle]);

  return (
    <>
      <SidebarSlide
        isOpen={isOpen}
        onClose={onClose}
        title={`Editar vehículo ${vehiculo?.name}`}
        actions={[
          {
            autoFocus: false,
            onClick: onSave,
            text: 'Guardar',
            disabled: !isDirty || !isValid,
            variant: 'fill',
          },
        ]}
      >
        {isSaving && <FullScreenLoader />}
        <FormCard
          items={
            !pumpVehicles?.length
              ? []
              : [
                  {
                    value: (
                      <Controller
                        name="name"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <Input label="Nombre*" value={value} onChange={onChange} maxLength={14} />
                        )}
                      />
                    ),
                  },
                  ...(isDeviceV2
                    ? [
                        {
                          value: (
                            <Controller
                              name="keyId"
                              control={control}
                              rules={{ required: true }}
                              render={({ field: { onChange, value } }) => (
                                <Input label="ID Tarjeta Llavero" value={value} onChange={onChange} maxLength={14} />
                              )}
                            />
                          ),
                        },
                      ]
                    : []),
                ]
          }
        />
      </SidebarSlide>
    </>
  );
}
