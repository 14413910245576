import { LoggedUser } from 'store/auth/authApi';
import { api as devicesApi, TAG_USER } from '../devices/devicesApi';

export interface NewUser {
  username: string;
  password: string;
  secondaryEmail?: string;
  roles: string[];
}

export const api = devicesApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserAll: builder.query<LoggedUser[], void>({
      query: () => ({ url: `user/all` }),
      providesTags: [TAG_USER],
    }),
    createUser: builder.mutation<any, NewUser>({
      query: (newUser) => ({
        url: 'user/create',
        method: 'POST',
        body: newUser,
      }),
      invalidatesTags: [TAG_USER],
    }),
    deleteUser: builder.mutation<any, string>({
      query: (username) => ({
        url: `user/delete/${username}`,
        method: 'POST',
        body: {},
      }),
      invalidatesTags: [TAG_USER],
    }),
    addDeviceToUser: builder.mutation<
      any,
      { username: string; serialNumber: string }
    >({
      query: ({ username, serialNumber }) => ({
        url: `user/addDevice/${username}?serialNumber=${serialNumber}`,
        method: 'POST',
        body: {},
      }),
      invalidatesTags: [TAG_USER],
    }),
    removeDeviceFromUser: builder.mutation<
      any,
      { username: string; serialNumber: string }
    >({
      query: ({ username, serialNumber }) => ({
        url: `user/removeDevice/${username}?serialNumber=${serialNumber}`,
        method: 'POST',
        body: {},
      }),
      invalidatesTags: [TAG_USER],
    }),
  }),
});

export const {
  useGetUserAllQuery,
  useCreateUserMutation,
  useDeleteUserMutation,
  useAddDeviceToUserMutation,
  useRemoveDeviceFromUserMutation,
} = api;
