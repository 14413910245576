import { createSelector } from '@reduxjs/toolkit';
import { selectDeviceSelected } from 'store/devices/devicesSlice';

export const selectRecetas = createSelector(
  selectDeviceSelected,
  (device) =>
    [...(device?.recipes || [])].sort((a, b) => {
      return a.id || b.id ? (!a.id ? -1 : !b.id ? 1 : a.id > b.id ? 1 : a.id < b.id ? -1 : 0) : 0;
    }) || []
);

export const selectRecetasIds = createSelector(selectRecetas, (recetas) => recetas?.map((receta) => receta.id));

export const selectRecetaById = (id: number) =>
  createSelector(selectRecetas, (receta) => receta.find((receta) => receta.id === id));
