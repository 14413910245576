import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select as MaterialSelect,
  SelectProps
} from '@material-ui/core';
import {
  DEFAULT_BACKGROUND_CONTENT_LIGHTER,
  DEFAULT_FONT_COLOR
} from 'components/colors';
import { ChangeEvent } from 'react';

type SelectOption = {
  value: string;
  name: string;
  disabled?: boolean;
};

const MenuProps = {
  PaperProps: {
    style: {
      backgroundColor: DEFAULT_BACKGROUND_CONTENT_LIGHTER,
      color: DEFAULT_FONT_COLOR,
    },
  },
};

export function MUISelect({
  name,
  label,
  placeholder,
  options,
  value = [],
  onChangeValue = () => {},
  ...props
}: SelectProps & {
  value: string | string[];
  onChangeValue: (value: string | string[]) => void;
  options: SelectOption[];
  label?: string;
  placeholder?: string;
}) {
  const handleChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>,
    child: React.ReactNode
  ) => {
    event.preventDefault();
    props.onChange && props.onChange(event, child);
    onChangeValue(event.target.value as string | string[]);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="material-select-label">{label}</InputLabel>
      <MaterialSelect
        labelId="material-select-label"
        id="material-select-label"
        value={value}
        onChange={handleChange}
        MenuProps={MenuProps}
        input={<OutlinedInput label="Name" />}
        renderValue={(selected) => (selected as unknown as string[]).join(', ')}
        {...props}
      >
        {options.map(({ value: optionValue, name, disabled }, idx) => (
          <MenuItem key={`${name}-${idx}`} value={optionValue}>
            {props.multiple && (
              <Checkbox checked={value.indexOf(optionValue) > -1} />
            )}
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </MaterialSelect>
    </FormControl>
  );
}
