import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { api as devicesApi } from 'store/devices/devicesApi';
import devicesReducer from 'store/devices/devicesSlice';
import recetasReducer from 'store/recetas/recetasSlice';
import descargasReducer from 'store/descargas/descargasSlice';
import configReducer from 'store/config/configSlice';
import { rtkQueryErrorLogger } from './error-logger';

const store = configureStore({
  reducer: {
    [devicesApi.reducerPath]: devicesApi.reducer,
    devices: devicesReducer,
    recetas: recetasReducer,
    descargas: descargasReducer,
    config: configReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(rtkQueryErrorLogger)
      .concat(devicesApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
