import Input from 'components/input';
import { FullScreenLoader } from 'components/loader';
import Select from 'design-system/select';
import SidebarSlide from 'design-system/sidebar-slide';
import { useEffect, useState } from 'react';
import { NewUser, useCreateUserMutation } from 'store/admin/adminUsersApi';

const USER_ROLES = [
  { name: 'adminUser', value: 'adminUser' },
  { name: 'guestUser', value: 'guestUser' },
];

const EMPTY_USER = {
  username: '',
  password: '',
  secondaryEmail: '',
  roles: ['guestUser'],
};

function ModalCreateUser({ isOpen, onClose = () => {} }: { isOpen: boolean; onClose: () => void }) {
  const [onCreateUser, { isLoading: isCreatingUser }] = useCreateUserMutation();
  const [newUser, seNewUser] = useState<NewUser>(EMPTY_USER);
  const canGuardar = newUser.username && newUser.password && newUser.secondaryEmail;

  useEffect(() => seNewUser(EMPTY_USER), [isOpen]);

  const onSave = () => {
    onCreateUser(newUser);
    onClose();
  };

  const onUserDataChange = (key: keyof NewUser, value: any) => {
    seNewUser((user) => ({ ...user, [key]: value }));
  };

  return (
    <SidebarSlide
      isOpen={isOpen}
      onClose={onClose}
      title="Nuevo usuario"
      actions={[
        {
          autoFocus: false,
          onClick: onClose,
          text: 'Cancelar',
          variant: 'text-danger',
        },
        {
          autoFocus: false,
          onClick: onSave,
          text: 'Guardar',
          variant: 'fill',
          disabled: !canGuardar,
        },
      ]}
    >
      {isCreatingUser && <FullScreenLoader />}
      <div className="px-8 py-8">
        <div className="form">
          <Input
            placeholder="Usuario"
            label="Usuario"
            value={newUser.username || ''}
            onChange={(value) => onUserDataChange('username', value)}
            required
          />
          <Input
            placeholder="Contraseña"
            label="Contraseña"
            value={newUser.password || ''}
            onChange={(value) => onUserDataChange('password', value)}
            required
          />
          <Input
            placeholder="Email secundario"
            label="Email secundario"
            value={newUser.secondaryEmail || ''}
            onChange={(value) => onUserDataChange('secondaryEmail', value)}
            required
          />
          <Select
            name="Rol"
            label="Rol"
            options={USER_ROLES}
            onChangeValue={(value: string) => {
              onUserDataChange('roles', [value]);
            }}
            value={newUser.roles[0]}
          />
        </div>
      </div>
    </SidebarSlide>
  );
}
export default ModalCreateUser;
